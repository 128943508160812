<div *ngIf="branding" class="d-flex flex-row gap-3">
	<div class="d-flex flex-column gap-3 form-group">
		<div>
			<label for="brandingPrimaryColor" localize="component.branding.primary"></label>
			<p class="mb-1" localize="component.branding.primary.description"></p>
			<input
				(change)="brandingChanged.emit(branding)"
				[(ngModel)]="branding!.primary"
				[disabled]="disabled"
				class="form-control"
				id="brandingPrimaryColor"
				type="color"
			/>
		</div>

		<div>
			<label for="brandingSecondaryColor" localize="component.branding.secondary"></label>
			<p class="mb-1" localize="component.branding.secondary.description"></p>
			<input
				(change)="brandingChanged.emit(branding)"
				[(ngModel)]="branding!.secondary"
				[disabled]="disabled"
				class="form-control"
				id="brandingSecondaryColor"
				type="color"
			/>
		</div>

		<div>
			<label for="brandingBgPrimaryColor" localize="component.branding.primary.background"></label>
			<p class="mb-1" localize="component.branding.primary.background.description"></p>
			<input
				(change)="brandingChanged.emit(branding)"
				[(ngModel)]="branding!.bg_primary"
				[disabled]="disabled"
				class="form-control"
				id="brandingBgPrimaryColor"
				type="color"
			/>
		</div>

		<div>
			<label for="brandingBgSecondaryColor" localize="component.branding.secondary.background"></label>
			<p class="mb-1" localize="component.branding.secondary.background.description"></p>
			<input
				(change)="brandingChanged.emit(branding)"
				[(ngModel)]="branding!.bg_secondary"
				[disabled]="disabled"
				class="form-control"
				id="brandingBgSecondaryColor"
				type="color"
			/>
		</div>

		<div class="d-flex justify-content-end mt-2 gap-2">
			<ng-content select="[footer]" />

			<button
				(click)="brandingCleared.emit()"
				[disabled]="disabled"
				class="btn btn-danger btn-sm form-control w-fit"
				localize="component.branding.deleteBranding"
			></button>
		</div>
	</div>
</div>
