export const environment = {
	useEmulators: false,
	firebaseConfig: {
		apiKey: 'AIzaSyCjXQ3Uv1LRCwEeyPLT1SrVxG5kq2TUI8A',
		authDomain: 'gen8-dev.firebaseapp.com',
		databaseURL: 'https://gen8-dev-default-rtdb.europe-west1.firebasedatabase.app',
		projectId: 'gen8-dev',
		storageBucket: 'gen8-dev.appspot.com',
		messagingSenderId: '951667506531',
		appId: '1:951667506531:web:d3a1b900af06f975f5e756',
		measurementId: 'G-SD2LY7LZHH',
	},
	versionString: 'v2.11.0 (feature/meeting-tool#f496c79)',
};
