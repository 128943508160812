import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import EditorJS, { API, OutputBlockData } from '@editorjs/editorjs';
import { EditorJsService } from '../../services/editor-js.service';
import { sleep } from '../../utilities/sleep';

@Component({
	selector: 'app-editorjs-renderer',
	templateUrl: './editorjs-renderer.component.html',
})
export class EditorjsRendererComponent implements OnInit, OnDestroy {
	@Input() blocks: OutputBlockData[] = [];
	@Input() readOnly = false;
	@Input() minHeight = 0;

	@Output() change = new EventEmitter<OutputBlockData[]>();

	@ViewChild('holder') private holder?: ElementRef<HTMLDivElement>;
	private editor?: EditorJS;

	async ngOnInit() {
		const start = Date.now();

		while (!this.holder?.nativeElement) {
			await sleep(10);
			if (Date.now() - start > 5000) {
				throw new Error('Editorjs renderer could not initialize: holder not found');
			}
		}

		this.editor = new EditorJS({
			holder: this.holder?.nativeElement,
			data: {
				blocks: this.blocks,
			},
			tools: EditorJsService.tools,
			readOnly: this.readOnly,
			minHeight: this.minHeight,
			onChange: async (api: API) => {
				const savedBlocks = api.readOnly.isEnabled ? this.blocks : (await api.saver.save()).blocks;
				this.change.emit(savedBlocks);
			},
		});
	}

	ngOnDestroy() {
		this.editor?.destroy();
	}
}
