import { Component, ElementRef, inject, Input, OnChanges, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { LayoutElement, LayoutElementDecorator, LayoutElementRule } from 'src/app/core/shared/models/layout/layoutBase';
import { BaseHtmlBit } from '../../../models/layout/html/base-html-bit';
import { getCustomData } from '../../../utilities/decorators/customDataDecorator';
import { ClassConstructor } from '../../../utilities/typeUtilities';
import { DynamicValuesComponent } from '../../base/dynamic-values/dynamic-values.component';

@Component({
	template: ''
})
export abstract class LayoutElementComponent extends DynamicValuesComponent implements OnInit, OnChanges {

	private _thisEl: ElementRef;
	private _renderer: Renderer2;
	private _siblingIndex: number = 0;
	private _isFirstSibling: boolean = true;
	private _isLastSibling: boolean = true;
	private _nextSibling: LayoutElementComponent | null = null;
	private _previousSibling: LayoutElementComponent | null = null;
	private _parent: LayoutElementComponent | null = null;

	@Input() set setup(value: LayoutElement) {
		if (value.type === 'textInput') {
			console.warn('settung up text input element', value, 'on this', this.constructor.name, 'with visible value', (value as any).visible, 'host element ref', this.hostElement.nativeElement);
		}
		Object.assign(this, value);
	}

	get setup(): LayoutElement {
		return this as unknown as LayoutElement;
	}

	protected get hostElement(): ElementRef {
		return this._thisEl;
	}

	protected get contextElement(): ElementRef {
		return this._thisEl;
	}

	protected get renderer2(): Renderer2 {
		return this._renderer;
	}

	constructor() {
		super();
		this._thisEl = inject(ElementRef);
		this._renderer = inject(Renderer2);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this._calculateRules()
		this.setupRuleBasedStyles(this._calculatedRules);
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	// override ngOnChanges(changes: SimpleChanges): void {
	// 	if (changes['setup']) {
	// 		super.ngOnInit();
	// 	} else {
	// 		super.ngOnChanges(changes);
	// 	}
	// }

	//#region rules

	private _calculateRules() {
		this._calculatedRules.clear();

		// begin with own rules
		this.rulesWithOverrides.forEach(r => this._calculatedRules.add(r));

		// find previous sibling and get its rules
		const prev = this._previousSibling;
		const prevRules = prev ? (prev as LayoutElementComponent).rules : [];
		if (prevRules && prevRules.includes(LayoutElementRule.BottomTight)) {
			this._calculatedRules.add(LayoutElementRule.TopTight);
		}

		// find next sibling and get its rules
		const next = this._nextSibling;
		const nextRules = next ? (next as LayoutElementComponent).rules : [];
		if (nextRules && nextRules.includes(LayoutElementRule.TopTight)) {
			this._calculatedRules.add(LayoutElementRule.BottomTight);
		}

		// if element is first child, add top tight
		if (this._calculatedRules.has(LayoutElementRule.TopTightIfFirst) && this.isFirstSibling()) {
			this._calculatedRules.add(LayoutElementRule.TopTight);
			this._calculatedRules.delete(LayoutElementRule.TopTightIfFirst);
		}

		// if element is last child, add bottom tight
		if (this._calculatedRules.has(LayoutElementRule.BottomTightIfLast) && this.isLastSibling()) {
			this._calculatedRules.add(LayoutElementRule.BottomTight);
			this._calculatedRules.delete(LayoutElementRule.BottomTightIfLast);
		}

		// find parent and get its rules
		const parent = this._getParentElement();
		const parentRules = parent ? parent.rulesWithOverrides : null;
		parentRules?.forEach(r => {
			if (r === LayoutElementRule.VerticalTightBetween) {
				if (!this._isFirstSibling) {
					this._calculatedRules.add(LayoutElementRule.TopTight);
				}
				if (!this._isLastSibling) {
					this._calculatedRules.add(LayoutElementRule.BottomTight);
				}
			} else if (r === LayoutElementRule.BottomTight) {
				if (this._isLastSibling) {
					this._calculatedRules.add(LayoutElementRule.BottomTight);
				}
			} else if (r === LayoutElementRule.TopTight) {
				if (this._isFirstSibling) {
					this._calculatedRules.add(LayoutElementRule.TopTight);
				}
			}
		});

		// check overrides in the setup
		if (this.setup.overrideRules) {
			if (this.setup.overrideRules.apply) {
				this.setup.overrideRules.apply.forEach(r => this._calculatedRules.add(r));
			}
			if (this.setup.overrideRules.ignore) {
				this.setup.overrideRules.ignore.forEach(r => this._calculatedRules.delete(r));
			}
		}
	}

	protected get rulesWithOverrides(): LayoutElementRule[] {
		let rules = this.rules;
		if (this.setup.overrideRules) {
			if (this.setup.overrideRules.apply) {
				this.setup.overrideRules.apply.forEach(r => rules.push(r));
			}
			if (this.setup.overrideRules.ignore) {
				this.setup.overrideRules.ignore.forEach(r => {
					rules = rules.filter(rule => rule !== r);
				});
			}
		}
		return rules;
	}

	private _calculatedRules: Set<LayoutElementRule> = new Set();
	protected abstract get rules(): LayoutElementRule[];
	protected abstract setupRuleBasedStyles(calculatedRules: Set<LayoutElementRule>): void;

	//#endregion

	//#region style helpers

	protected switchClass<T>(values: keyof T | (keyof T)[] | Set<keyof T>, map: { [option in keyof T]: string | string[] | null | undefined }): void {
		const valuesSet = values instanceof Set ? values : new Set(values instanceof Array ? values : [values]);
		Object.entries(map).forEach(([option, classNames]) => {
			if (classNames === null || classNames === undefined)
				return;
			if (valuesSet.has(option as keyof T)) {
				this.setClass(classNames as string | string[]);
			} else {
				this.setClass(classNames as string | string[], false);
			}
		});
	}

	protected setClass(classes: string | string[], condition: boolean = true, otherwiseClasses?: string | string[]): void {
		if (Array.isArray(classes)) {
			classes.forEach(c => this._setClass(c, condition));
		} else {
			this._setClass(classes, condition);
		}

		if (otherwiseClasses) {
			if (Array.isArray(otherwiseClasses)) {
				otherwiseClasses.forEach(c => this._setClass(c, !condition));
			} else {
				this._setClass(otherwiseClasses, !condition);
			}
		}
	}

	private _setClass(className: string, value: boolean): void {
		if (value) {
			this._renderer.addClass(this.contextElement.nativeElement, className);
		} else {
			this._renderer.removeClass(this.contextElement.nativeElement, className);
		}
	}

	protected setStyle(style: string, value: string): void {
		this._renderer.setStyle(this.contextElement.nativeElement, style, value);
	}

	protected setAttribute(name: string, value: string): void {
		this._renderer.setAttribute(this.contextElement.nativeElement, name, value);
	}

	protected setInnerHTML(html: string): void {
		this.contextElement.nativeElement.innerHTML = html;
	}

	protected setInnerText(text: string): void {
		this.contextElement.nativeElement.innerText = text;
	}

	//#endregion

	//#region drawing helpers

	public static draw(vcr: ViewContainerRef, elements: LayoutElement[], parent: LayoutElementComponent | null): void {
		vcr.clear();

		const spawned: LayoutElementComponent[] = [];

		elements.forEach((element: LayoutElement, index: number) => {
			const data = getCustomData<LayoutElementDecorator>(element.constructor);

			if (!data) {
				return;
			}

			const component = vcr.createComponent(data['component']);
			(component.instance as LayoutElementComponent).setup = element;
			(component.instance as LayoutElementComponent)._siblingIndex = index;
			(component.instance as LayoutElementComponent)._parent = parent;
			(component.instance as LayoutElementComponent)._isFirstSibling = index === 0;
			(component.instance as LayoutElementComponent)._isLastSibling = index === elements.length - 1;
			spawned.push(component.instance as LayoutElementComponent);
		});

		spawned.forEach((component, index) => {
			component._previousSibling = spawned[index - 1] || null;
			component._nextSibling = spawned[index + 1] || null;
		});
	}

	public draw(vcr: ViewContainerRef, elements: LayoutElement[]): void {
		LayoutElementComponent.draw(vcr, elements, this);
	}

	//#endregion

	//#region hierarchy helpers

	// protected isInHorizontalFlex(): boolean {
	// 	// check if parent has d-flex and flex-row
	// 	const parent = this._thisEl.nativeElement.parentElement;
	// 	if (!parent) {
	// 		return false;
	// 	}
	// 	const parentClasses = parent.className.split(' ');
	// 	return parentClasses.includes('d-flex') && parentClasses.includes('flex-row');
	// }

	protected _getParentElement(): LayoutElementComponent | null {
		return this._parent;
	}

	protected isPreviousSiblingOfType<T extends LayoutElementComponent>(token: ClassConstructor<T>): boolean {
		return this._isOfType(token, this._previousSibling);
	}

	protected isFirstSibling(): boolean {
		return this._isFirstSibling;
	}

	protected isNextSiblingOfType<T extends LayoutElementComponent>(token: ClassConstructor<T>): boolean {
		return this._isOfType(token, this._nextSibling);
	}

	protected isLastSibling(): boolean {
		return this._isLastSibling;
	}

	private _isOfType<T extends LayoutElementComponent>(token: ClassConstructor<T>, element: LayoutElementComponent | null): boolean {
		if (!element) {
			return false;
		}
		return element instanceof token;
	}

	//#endregion
}
