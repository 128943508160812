import { NavigationService, RouteOption, RouteParams } from '../../services/navigation/navigation.service';
import { Pipe, PipeTransform } from '@angular/core';
import { HostConfigService } from './host-config.service';
import { HostConfigOption } from '../../models/config/host-config/hostConfigModels';
import { NavigationExtras } from '@angular/router';

@Pipe({
	name: 'routerConfig',
})
export class RouterConfigPipe implements PipeTransform {
	constructor(
		private hostConfigService: HostConfigService,
		private navigationService: NavigationService,
	) {}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transform(value: HostConfigOption, routeParams?: RouteParams): { commands: any[]; extras?: NavigationExtras } {
		const routeOption: RouteOption = this.hostConfigService.get(value, routeParams?.organizationId);
		return this.navigationService.toRoute(routeOption, routeParams);
	}
}
