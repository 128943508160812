import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-loader-guard',
	templateUrl: './loader-guard.component.html',
	styleUrls: ['./loader-guard.component.scss'],
})
export class LoaderGuardComponent {
	@Input() public loading: boolean = false;
}
