import { GeneralInfo, MetaData } from '../util/general';
import { Nullable } from '../util/common';
import { SharedDocumentReference } from '../util/internal';
import { OrganizationInfo, OrganizationPermissions, OrganizationRole } from './index';
import { UserInfo } from '../core/user';

export type OrganizationMemberInfo = GeneralInfo & {
	roles: string[];
	title: Nullable<string>;
};

export enum OrganizationMembershipStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	REMOVED = 'removed',
}

export type OrganizationMembership = {
	created: MetaData;
	invitedByMemberRef: Nullable<SharedDocumentReference>;
	inviteLinkRef: Nullable<SharedDocumentReference>;
	organizationRef: SharedDocumentReference;
	organizationInfo: OrganizationInfo;
	userRef: SharedDocumentReference;
	status: OrganizationMembershipStatus;
	memberInfo: OrganizationMemberInfo;
	userInfo: UserInfo;
	role: OrganizationRole; // setting roles (except 'custom') should automatically set permissions
	permissions: OrganizationPermissions;
};
