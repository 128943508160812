import { FormGroup } from "@angular/forms";

export type CompareOperator = '==' | '!=' | '>' | '<' | '>=' | '<=';
export type ConditionOperator = 'and' | 'or';

export type Condition = {
};

export type ConditionCombine = Condition & {
	operator: ConditionOperator;
	conditions: Condition[];
};

export type ConditionInvert = {
	condition: Condition;
};

export type ConditionCompare = Condition & {
	name: string;
	operator: CompareOperator;
	value: string;
};

export function formGroupToDictionary(formGroup: FormGroup): Record<string, any> {
	const dictionary: Record<string, any> = {};
	for (const key in formGroup.controls) {
		const control = formGroup.controls[key];
		dictionary[key] = control.value;
	}
	return dictionary;
}


export function evaluate(condition: Condition, dictionary: Record<string, any>): boolean {
	if (isConditionCombine(condition)) {
		return evaluateCombine(condition, dictionary);
	} else if (isConditionInvert(condition)) {
		return evaluateInvert(condition, dictionary);
	} else if (isConditionCompare(condition)) {
		return evaluateCompare(condition, dictionary);
	} else {
		return true;
	}
}

function evaluateCombine(condition: ConditionCombine, dictionary: Record<string, any>): boolean {
	const { operator, conditions } = condition;
	if (operator === 'and') {
		return conditions.every((c) => evaluate(c, dictionary));
	} else if (operator === 'or') {
		return conditions.some((c) => evaluate(c, dictionary));
	} else {
		return false;
	}
}

function evaluateInvert(condition: ConditionInvert, dictionary: Record<string, any>): boolean {
	const { condition: innerCondition } = condition;
	return !evaluate(innerCondition, dictionary);
}

function evaluateCompare(condition: ConditionCompare, dictionary: Record<string, any>): boolean {
	const { name, value, operator } = condition;
	const processedName = name.trim().toLowerCase();
	let actualValue: any = undefined;
	// find the value in the dictionary
	for (const key in dictionary) {
		if (key.trim().toLowerCase() === processedName) {
			actualValue = dictionary[key];
			break;
		}
	}
	switch (operator) {
		case '==':
			return String(actualValue) == String(value);
		case '!=':
			return String(actualValue) != String(value);
		case '>':
			return Number(actualValue) > Number(value);
		case '<':
			return Number(actualValue) < Number(value);
		case '>=':
			return Number(actualValue) >= Number(value);
		case '<=':
			return Number(actualValue) <= Number(value);
		default:
			return false;
	}
}

function isConditionCombine(condition: Condition): condition is ConditionCombine {
	if (!condition) return false;
	return 'operator' in condition && 'conditions' in condition;
}

function isConditionInvert(condition: Condition): condition is ConditionInvert {
	if (!condition) return false;
	return 'condition' in condition;
}

function isConditionCompare(condition: Condition): condition is ConditionCompare {
	if (!condition) return false;
	return 'name' in condition && 'value' in condition && 'operator' in condition;
}
