import { Destroyable } from '../../../utilities/data/dynamic/destroyable';
import { Listenable } from '../../../utilities/data/dynamic/listenables';
import { Listener } from '../../../utilities/data/dynamic/listener';
import { ValueWrapper } from '../../../utilities/data/dynamic/valueWrapper';
import { Dynamic } from '../../../utilities/data/dynamic/valueWrappers';
import BootstrapClass from '../bootstrap/bootstrapTypes';
import { FontAwesomeClass } from '../fontawesome/fontawesomeTypes';

export type HtmlBit = Dynamic<string> | Dynamic<BaseHtmlBit>;

type HtmlBitClass = FontAwesomeClass | BootstrapClass;

export abstract class BaseHtmlBit extends ValueWrapper<string> implements Destroyable {
	private _listener: Listener<string> = new Listener();

	protected constructor() {
		super('');
		this._classes = new Set();
		this._classes.add(this._uid);
	}

	private _classes?: Set<HtmlBitClass | string>;

	get classes(): string {
		return this._classes ? Array.from(this._classes).join(' ') : '';
	}

	private _uid = Math.random().toString(36).substring(14);

	get uid(): string {
		return this._uid;
	}

	abstract get html(): string;

	override toString(): string {
		return this.html;
	}

	addClass(...bsClass: HtmlBitClass[]): this {
		if (!this._classes) {
			this._classes = new Set();
		}
		bsClass.forEach(c => this._classes!.add(c));
		this.updateValue();
		return this;
	}

	removeClass(...bsClass: HtmlBitClass[]): this {
		if (this._classes) {
			bsClass.forEach(c => this._classes!.delete(c));
		}
		this.updateValue();
		return this;
	}

	listenTo(other: Dynamic<unknown> | Dynamic<unknown>[]): this {
		if (Array.isArray(other)) {
			other.forEach(o => this.listenTo(o));
		} else if (other instanceof Listenable) {
			this._listener.listenTo(other, this.updateValue.bind(this));
		}
		return this;
	}

	stopListeningTo(other: Dynamic<unknown> | Dynamic<unknown>[]): this {
		if (Array.isArray(other)) {
			other.forEach(o => this.stopListeningTo(o));
		} else if (other instanceof Listenable) {
			this._listener.stopListeningTo(other);
		}
		return this;
	}

	destroy(): void {
		this._listener.destroy();
	}

	protected updateValue(): void {
		this.value = this.html;
	}
}
