import { IdTag } from '../util/common';
import { Condition } from './conditions';

export type FormInputUid = IdTag<'formInputUid'>;

export enum FormComponentType {
	If = 'if',
	EndIf = 'endif',
	Text = 'text', // just text, no input
	Button = 'button', // todo
	Prompt = 'prompt', // a prompt piece of text, no input, can be used to encapsulate with conditions

	Webhook = 'webhook',

	Textfield = 'textfield',
	Textarea = 'textarea',
	Checkbox = 'checkbox',
	Toggle = 'toggle', // todo
	Url = 'url', // todo
	Image = 'image', // todo
	Radio = 'radio',
	Dropdown = 'dropdown',
	Pick = 'pick',
	RichText = 'richtext',
}

export type FormComponent = {
	index: number; // The index of the form component in the text
	type: FormComponentType; // The type of the form component
};

export type FormIfComponent = FormComponent & {
	type: FormComponentType.If | FormComponentType.EndIf;
};

export type FormConditionalComponent = FormComponent & {
	condition: Condition; // The conditions that must be met for the form component to be shown/used
};

export type FormPromptComponent = FormConditionalComponent & {
	type: FormComponentType.Prompt;
	value: string; // The value of the prompt, should be emptied when extracted as a form input component!
};

export type FormInputComponent = FormConditionalComponent & {
	uid: FormInputUid; // The uid of the form component
	name?: string; // The name of the form input, used as the key in the inputValues object
	label?: string; // The label of the form input shown to the user
	description?: string; // The description of the form input shown to the user
	required?: boolean; // Whether the form input is required
	default?: string; // The default value of the form input
	isVariable?: boolean;

	variables: { [scope in FormVariableScope]?: FormVariablePolicy }; // Settings for the variables. Default is 'allow' for all scopes
};

export enum FormVariablePolicy {
	Forbid = 'forbid',
	Allow = 'allow',
	Suggest = 'suggest',
	Require = 'require',
}

export enum FormVariableScope {
	Personal = 'personal',
	Organization = 'organization',
	Project = 'project',
	Global = 'global',
}
