import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { isWrappedValue } from './valueWrapper';
import { Dynamic } from './valueWrappers';

@Pipe({
  name: 'dynamic',
  pure: false // Setting the pipe to be impure so that Angular reevaluates it when the wrapped value changes.
})
export class DynamicPipe<T> implements PipeTransform, OnDestroy {

  private currentValue: T | null = null;
  private subscription: Subscription | null = null;

  constructor(private cdr: ChangeDetectorRef) {}

  transform(wrapper: Dynamic<T>): T | null {
		if (isWrappedValue<T>(wrapper)) {
			if (!this.subscription) {
				this.subscription = new Observable<T>((observer) => {
					wrapper.listen(() => {
						observer.next(wrapper.value);
					});
					observer.next(wrapper.value); // emit the initial value
				}).subscribe(value => {
					this.currentValue = value;
					this.cdr.markForCheck(); // Mark the component for check
				});
			}
		} else {
			this.currentValue = wrapper as T;
		}
    return this.currentValue;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
