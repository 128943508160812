import { iTimestamp, SharedDocumentReference } from '../util/internal';
import { Nullable } from '../util/common';
import { MetaData, Reference } from '../util/general';

export enum WalletCreditChangeReason {
	GIFT = 'gift',
	PURCHASE = 'purchase',
	PURCHASE_FAILED = 'purchaseFailed',
	REFUND = 'refund',
	AI_USAGE = 'aiUsage',
	SYSTEM = 'system',
}

// this type is extended by the FirestoreDocument in the apps.
export type WalletCreditChange = {
	amount: number;
	reason: WalletCreditChangeReason;
	contextRef: Nullable<SharedDocumentReference>;
	contextRtdbRef: Nullable<string>;
	created: MetaData; // includes userId and organizationId
	stripeInvoiceId?: string;
	stripeInvoicePdfUrl?: string;
	stripeInvoicePageUrl?: string;
};

export type WalletCreditData = {
	credits: number;
	minCredits: number;
};

export enum WalletStatus {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
	INACTIVE = 'inactive',
}

export enum WalletOwnerType {
	ORG = 'org',
	USER = 'user',
}

export type WalletOwner = {
	ownerPath: Reference;
	ownerType: WalletOwnerType;
};

export type WalletPaymentData = {
	planRef: SharedDocumentReference;
	planVersionRef: SharedDocumentReference;
	startTime: iTimestamp;
	lastPaymentPlanInvoiceSent: iTimestamp;
	stripeCustomerId?: string;
};

export type Wallet = {
	owner: WalletOwner;
	status: WalletStatus;
};

export type SwitchPaymentPlanFunctionInput = {
	walletOwner: {
		ownerId: string;
		ownerType: WalletOwnerType;
	};
	paymentPlanId: string;
};
