import { Component, Input } from '@angular/core';
import { CardLayoutGroup } from 'src/app/core/shared/models/layout/layoutGroups';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';
import { LayoutElementRule } from '../../../models/layout/layoutBase';

@Component({
  selector: 'app-card-layout-group',
  template: `
<div class="card-header h5" [innerHTML]="title | dynamic | sanitizeHtml">
	{{ title }}
</div>
<app-vertical-layout-group [elements]="elements" class="card-body"></app-vertical-layout-group>`,
	styles: [`
		:host {
			display: contents;
		}
	`]
})
export class CardLayoutGroupComponent extends BlockLayoutElementComponent {

	@Input() elements: CardLayoutGroup['elements'] = [];
	@Input() title: CardLayoutGroup['title'] = '';

	override ngOnInit(): void {
		super.ngOnInit();
		this.setClass('card', true);
	}

	override setupAllValues(): void {
		this.setupValueForKey('title');
		this.setupValueForKey('elements');
	}

	override setupValueForKey(key: keyof this): void {
		// no keys to setup
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to setup
	}
}
