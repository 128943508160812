import { Component, Input } from '@angular/core';
import { TabLayoutGroup } from 'src/app/core/shared/models/layout/layoutGroups';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';

@Component({
	selector: 'app-tab-layout-group',
	template: `<div class="card">
		<div class="card-header">
			<ul class="nav nav-tabs card-header-tabs gap-2">
				@for (tab of tabs; track $index) {
					<app-tab-layout-group-item-tab
						[label]="tab.label"
						[active]="currentTab == $index"
						(onClick)="currentTab = $index"
					></app-tab-layout-group-item-tab>
				}
			</ul>
		</div>
		<app-tab-layout-group-item-body
			*ngFor="let tab of tabs; let i = index"
			[elements]="tab.elements"
			[show]="currentTab != i"
		></app-tab-layout-group-item-body>
	</div> `,
	styles: [],
})
export class TabLayoutGroupComponent extends BlockLayoutElementComponent {
	@Input() tabs: TabLayoutGroup['tabs'] = [];

	protected currentTab: number = 0;

	protected override setupAllValues(): void {
		// no values to setup
	}

	override setupValueForKey(key: keyof this): void {
		// no keys to setup
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to setup
	}
}
