import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-h1',
  templateUrl: './h1.component.html',
  styleUrls: ['./h1.component.scss']
})
export class H1Component {

	@Input() public image: string | null | undefined;
	@Input() public faIcon: string | null | undefined;
	@Input({ required: true }) public title: string | null | undefined;
	@Input() public subtitle: string | null | undefined;
	@Input() public description: string | null | undefined;

}
