<ng-container *ngIf="name" [formGroup]="group!">
	@if (anySetup.type === 'prompt' || anySetup.type === 'webhook') {
		<input type="hidden" [formControlName]="name" value="false" />
	}

	<div *ngIf="show" class="form-element d-flex flex-column gap-1">
		<!-- Textfield -->
		<ng-container *ngIf="anySetup.type === 'textfield'">
			<label *ngIf="anySetup.label" class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">
				{{ anySetup.description }}
			</p>
			<input
				#inputElement
				(change)="onChanged($event)"
				(input)="onInput($event)"
				[formControlName]="name"
				type="text"
			/>

			<ng-container *ngIf="!disabled">
				<app-input-suggestion
					(suggestionSelected)="inputSuggestion(suggestion)"
					*ngFor="let suggestion of anySetup.suggestions; let first = first"
					[suggestion]="suggestion"
				></app-input-suggestion>
			</ng-container>
		</ng-container>

		<!-- Textarea -->
		<ng-container *ngIf="anySetup.type === 'textarea'">
			<label *ngIf="anySetup.label" class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">
				{{ anySetup.description }}
			</p>
			<textarea
				#inputElement
				(change)="onChanged($event)"
				(input)="onInput($event)"
				[formControlName]="name"
				style="max-height: 10rem; overflow-y: auto; resize: vertical"
			></textarea>

			<ng-container *ngIf="!disabled">
				<app-input-suggestion
					(suggestionSelected)="inputSuggestion(suggestion)"
					*ngFor="let suggestion of anySetup.suggestions; let first = first"
					[suggestion]="suggestion"
				></app-input-suggestion>
			</ng-container>
		</ng-container>

		<!-- Checkbox -->
		<ng-container *ngIf="anySetup.type === 'checkbox'">
			<p *ngIf="anySetup.description" class="formDescription">
				{{ anySetup.description }}
			</p>
			<label class="checkbox-label">
				<input
					#inputElement
					(change)="onChanged($event)"
					[formControlName]="name"
					class="form-check-input"
					type="checkbox"
				/>
				<span>{{ anySetup.label }}</span>
			</label>
		</ng-container>

		<!-- Pick -->
		<ng-container *ngIf="anySetup.type === 'pick'">
			<label *ngIf="anySetup.label" class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">
				{{ anySetup.description }}
			</p>
			<input (change)="onChanged($event)" [formControlName]="name" type="hidden" />
			<div *ngIf="!hasValue" class="d-flex flex-row flex-wrap gap-2">
				<button
					(click)="pick(option.label)"
					*ngFor="let option of anySetup.options; let i = index"
					[disabled]="disabled"
					class="btn btn-sm btn-secondary"
				>
					{{ option.label }}
				</button>
			</div>
			<ng-container *ngIf="hasValue">
				<div class="btn-group" style="width: fit-content">
					<button (submit)="noop()" class="btn btn-sm btn-secondary w-100" disabled>
						{{ controlValue }}
					</button>
					<button
						(click)="pick(null)"
						(submit)="noop()"
						[disabled]="disabled"
						class="btn btn-sm btn-secondary"
					>
						X
					</button>
				</div>
			</ng-container>
		</ng-container>

		<!-- Radio -->
		<ng-container *ngIf="anySetup.type === 'radio'">
			<label *ngIf="anySetup.label" class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">
				{{ anySetup.description }}
			</p>
			<ng-container *ngFor="let option of anySetup.options">
				<label class="radio-label">
					<input
						#inputElement
						(change)="onChanged($event)"
						[formControlName]="name"
						[value]="option.label"
						class="form-check-input"
						type="radio"
					/>
					<span>{{ option.label }}</span>
				</label>
			</ng-container>
		</ng-container>

		<!-- Dropdown -->
		<ng-container *ngIf="anySetup.type === 'dropdown'">
			<label *ngIf="anySetup.label" class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">
				{{ anySetup.description }}
			</p>
			<select (change)="onChanged($event)" [disabled]="disabled" [formControlName]="name" class="form-select">
				<option *ngFor="let option of anySetup.options" [value]="option.label">
					{{ option.label }}
				</option>
			</select>
		</ng-container>

		<!-- Rich text editor -->
		<ng-container *ngIf="anySetup.type === 'richtext'">
			<label *ngIf="anySetup.label" class="formLabel">{{ anySetup.label }}</label>
			<p *ngIf="anySetup.description" class="formDescription">
				{{ anySetup.description }}
			</p>

			<div #editorJsFsParent>
				<div #editorJsFsContainer class="position-relative h-100 bg-white">
					<div #editorJsHolder [ngClass]="{ 'is-fullscreen': isFullscreen }" id="editor-js-holder"></div>
					<div
						(click)="toggleFullscreen()"
						[ngClass]="{ 'is-fullscreen': isFullscreen }"
						aria-label="Toggle fullscreen"
						class="fullscreen-button btn btn-sm border-med bg-white"
						role="button"
					>
						<i class="fa-solid fa-expand"></i>
					</div>
				</div>
			</div>

			<!-- This is the container for the markdown formatted value the form can bind to. -->
			<textarea [formControlName]="name" [value]="value" class="d-none"></textarea>
		</ng-container>
	</div>
</ng-container>
