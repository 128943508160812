import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { toasts } from 'src/app/core/shared/services/toasts.service';
import { userDocFromAuthId } from 'src/app/core/shared/utilities/database/userUtilities';
import { assertDocumentReference, DotPatches, DotPath } from 'src/app/core/shared/utilities/typeUtilities';
import { fsdb, ManagedDocumentListenerByRef } from '../../core/shared/utilities/firebase/firestoreUtilities';
import { userIdFromAuthId } from '../../core/shared/utilities/database/userUtilities';
import { DocumentReference } from 'firebase/firestore';
import { getUnixTimestampInSeconds } from 'src/app/core/shared/utilities/timeUtilities';
import { ContextService } from 'src/app/core/shared/services/page/context.service';
import { FsDoc } from '../../core/shared/models/Utility';
import { OrganizationId, User, UserInfo, UserTemplatesData } from '../../../build-dependencies/shared';

export type ActiveOrganizationServiceTunnelToUserService = {
	setActiveOrganization(organizationId: OrganizationId): void;
};

@Injectable({
	providedIn: 'root',
})
export class UserService {
	user = new BehaviorSubject<FsDoc<User> | undefined>(undefined);
	isLoggedIn: boolean = false;
	isLoggedInAnonymously: boolean = false;
	isLoggedInWithIdentity: boolean = false;
	isLoggedInAsGod: boolean = false;
	userSessionId?: string;

	private tunnelForActiveOrganizationServiceGiven: boolean = false;
	//userListener: Unsubscribe | undefined;
	private userListener = new ManagedDocumentListenerByRef<FsDoc<User>>(
		user => {
			// check if the user still exists
			if (!user) {
				this.setUser(undefined);
				return;
			}

			// add user id if it is missing
			if (!user.userId && user.userRef?.id) {
				user.userId = user.userRef?.id;
			}

			this.setUser(user);
		},
		(error: Error) => {
			toasts.error(error, `Error loading user: ${error.message}`);
		},
	).enableRetry(true);

	constructor(
		private authService: AuthService,
		private contextService: ContextService,
		private ngZone: NgZone,
	) {
		this.userSessionId = localStorage.getItem('userSessionId') ?? undefined;
		if (!this.userSessionId) {
			this.userSessionId =
				Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			localStorage.setItem('userSessionId', this.userSessionId);
		}

		this.authService.currentUser.subscribe(async user => {
			if (!user || !user.uid) {
				this.userListener.pause();
				this.isLoggedIn = false;
				this.isLoggedInAnonymously = false;
				this.isLoggedInWithIdentity = false;
				this.setUser(undefined);
				return;
			}

			this.isLoggedIn = true;
			this.isLoggedInAnonymously = user.isAnonymous;
			this.isLoggedInWithIdentity = !this.isLoggedInAnonymously;

			if (this.isLoggedInAnonymously) {
				this.setUser({
					//eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore Typescript doesn't convert this correctly, the actual type is `Nullable<DocumentReference>`
					userRef: null,
					userId: userIdFromAuthId(user.uid),
					created: null,
					info: {
						name: 'Anonymous User',
						description: '',
						image: '',
					},
					isAnonymous: true,
				} as unknown as FsDoc<User>);
				return;
			}

			const userDoc = userDocFromAuthId(user.uid);
			this.userListener.listenTo(userDoc);
		});

		this.checkGodMode().then((isGod: boolean) => {
			this.isLoggedInAsGod = isGod;
		});
	}

	public get isLoading() {
		return this.userListener.isLoading.value || !this.authService.isInitialized;
	}

	get id(): string | undefined {
		return this.user.value?.userId;
	}

	get ref(): DocumentReference | undefined {
		const ref = this.user.value?.docRef;
		if (!ref) return undefined;
		assertDocumentReference(ref);
		return ref;
	}

	get isAnonymous(): boolean | undefined {
		return this.user.value?.isAnonymous;
	}

	get info() {
		return this.user.value?.info;
	}

	get variablesData() {
		return this.user.value?.data?.variables;
	}

	get membershipsData() {
		return this.user.value?.data?.memberships;
	}

	get templatesData(): UserTemplatesData | undefined {
		return this.activeOrganizationId ? this.user.value?.data?.templates?.[this.activeOrganizationId] : undefined;
	}

	private get activeOrganizationId(): OrganizationId | undefined {
		return this.user.value?.data?.memberships?.active?.organizationId;
	}

	getTunnelForActiveOrganizationService(): ActiveOrganizationServiceTunnelToUserService {
		if (this.tunnelForActiveOrganizationServiceGiven) {
			throw new Error('Tunnel for ActiveOrganizationService already given');
		}

		this.tunnelForActiveOrganizationServiceGiven = true;

		return {
			setActiveOrganization: (organizationId: OrganizationId) => {
				const orgIdDataPath: DotPath<User> = 'data.memberships.active.organizationId';
				const orgTimestampDataPath: DotPath<User> = 'data.memberships.active.timestamp';

				this.patchUser([
					{
						path: orgIdDataPath,
						value: organizationId,
					},
					{
						path: orgTimestampDataPath,
						value: getUnixTimestampInSeconds(),
					},
				]);
			},
		};
	}

	async checkGodMode(): Promise<boolean> {
		try {
			const document = await fsdb.get(`system/gods`);
			return document !== undefined;
		} catch {
			return false;
		}
	}

	updateUser(patch: Partial<User>) {
		const user = this.user.value;
		if (!user) return;

		return fsdb.update(user.docRef!.path, patch).catch(error => console.error('error updating user', patch, error));
	}

	updateInfo(info: UserInfo) {
		const user = this.user.value;
		if (!user) return;

		const update = fsdb
			.update(user.docRef!.path, { info })
			.catch(error => console.error('error updating user info', info, error));

		if (info?.name) {
			this.authService
				.updateDisplayName(info.name)
				.catch(error => console.error('error updating display name', error));
		}

		if (info?.image) {
			this.authService
				.updatePhotoURL(info.image)
				.catch(error => console.error('error updating photo URL', error));
		}

		return update;
	}

	patchUser(patch: DotPatches<User>) {
		const user = this.user.value;
		if (!user || !user.docRef) return;

		//eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return fsdb.patch(user.docRef!.path, patch);
	}

	private setUser(user?: FsDoc<User>) {
		this.ngZone.run(() => {
			if (user) this.contextService.setUser(user.info.name ?? 'Nameless user', user.userId);
			else this.contextService.unsetUser();
			this.user.next(user);
		});
	}
}
