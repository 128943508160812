import { ElementRef, Injectable, Renderer2 } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FullscreenService {
	private overlays: Record<
		string,
		{
			element: ElementRef<HTMLElement>;
			originalParent: ElementRef<HTMLElement>;
			fullScreenOverlay: HTMLElement;
			renderer: Renderer2;
		}
	> = {};

	/**
	 * Removes an overlay element from fullscreen and restores it to its original parent.
	 *
	 * @param id - The unique ID of the overlay to hide.
	 * @remarks
	 *
	 * If the overlay with the specified ID does not exist, the function will return without any action.
	 * The overlay's element is appended back to its original parent, and the fullscreen overlay is removed from the DOM.
	 */
	public hideOverlay(id: string) {
		const overlay = this.overlays[id];

		if (!overlay) {
			return;
		}

		overlay.renderer.appendChild(overlay.originalParent.nativeElement, overlay.element.nativeElement);
		overlay.renderer.removeChild(document.body, overlay.fullScreenOverlay);
		delete this.overlays[id];
	}

	/**
	 * Adds an overlay element to the DOM, making it fullscreen.
	 *
	 * @param element - The ElementRef of the HTML element to be overlaid.
	 * @param parent - The ElementRef of the original parent of the element.
	 * @param renderer - The Renderer2 instance for DOM manipulations.
	 * @returns A unique ID string representing the overlay.
	 */
	public addOverlayElement(
		element: ElementRef<HTMLElement>,
		parent: ElementRef<HTMLElement>,
		renderer: Renderer2,
	): string {
		const id = this.randomId();

		this.overlays[id] = {
			element: element,
			originalParent: parent,
			fullScreenOverlay: renderer.createElement('div'),
			renderer,
		};

		renderer.addClass(this.overlays[id].fullScreenOverlay, 'fullscreen-overlay');
		renderer.appendChild(document.body, this.overlays[id].fullScreenOverlay);
		renderer.appendChild(this.overlays[id].fullScreenOverlay, element.nativeElement);

		return id;
	}

	// TODO: A function that accepts a TemplateRef as an overlay element.

	/**
	 * Generates a random string ID that is not already in use.
	 *
	 * @returns A unique string ID.
	 */
	private randomId() {
		let id;
		do {
			id = Math.random().toString(36).substring(2, 10);
		} while (id in this.overlays);
		return id;
	}
}
