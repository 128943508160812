import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { Gen8Params, NavigationService, ResolvedRoute, RouteOption } from './navigation.service';
import { DestroyableDirective } from '../../components/base/destroyable/destroyable.directive';

@Directive({
	selector: '[navigationLink]',
})
export class NavigationLinkDirective extends DestroyableDirective implements OnChanges, OnInit {
	@Input() navigationLink: RouteOption | null = null;
	@Input() navigationLinkActive: string | null = 'active';
	@Input() params: Gen8Params | null = null;

	private _resolvedRoute: ResolvedRoute | null = null;

	constructor(
		private navigationService: NavigationService,
		private el: ElementRef,
		private renderer: Renderer2,
	) {
		super();
		this.addOnStopListener(navigationService.listen(this._onUpdate.bind(this)));
	}

	ngOnChanges(): void {
		this._onUpdate();
	}

	ngOnInit() {
		this.renderer.listen(this.el.nativeElement, 'click', event => {
			if (this._resolvedRoute) {
				this.navigationService.navigate(this._resolvedRoute).then();
				event.preventDefault();
			} else {
				event.preventDefault();
			}
		});
		this._onUpdate();
	}

	private _onUpdate() {
		if (!this.navigationLink) {
			return;
		}

		this._resolvedRoute = this.navigationService.toRoute(this.navigationLink, this.params ?? undefined);

		// set the href property if this element is a <a>
		if (this.el.nativeElement.tagName.toLowerCase() === 'a') {
			const url = this.navigationService.toUrl(this._resolvedRoute);
			this.renderer.setProperty(this.el.nativeElement, 'href', url);
		}

		if (!this.navigationLinkActive) {
			return;
		}
		if (this.navigationService.isCurrentRoute(this._resolvedRoute)) {
			this.renderer.addClass(this.el.nativeElement, this.navigationLinkActive);
		} else {
			this.renderer.removeClass(this.el.nativeElement, this.navigationLinkActive);
		}
	}
}
