<div
	class="d-flex align-items-center"
	style="font-weight: normal"
	[ngClass]="{
		'text-bg-light': !isLoggedInUser && !hideBackground,
		'text-bg-primary': isLoggedInUser && !hideBackground,
		'p-0': hideBackground,
		badge: !hideBackground,
		'rounded-pill': !hideBackground,
	}"
>
	<img
		[src]="userInfo?.image || './assets/img/blank-profile-photo.png'"
		class="img-fluid rounded-circle me-2"
		alt="Profile image"
		style="width: 20px; height: 20px; object-fit: cover"
	/>
	<div *ngIf="showName">{{ userInfo?.name || 'Nameless User' }}</div>
</div>
