import { Injectable } from '@angular/core';
import { query } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { DestroyableComponent } from 'src/app/core/shared/components/base/destroyable/destroyable.component';
import { OrganizationMembership5 } from 'src/app/core/shared/models/database/organizationModels';
import { organizationMembersCollectionGroup } from 'src/app/core/shared/utilities/database/organizationUtilities';
import { userDoc, whereUserRef } from 'src/app/core/shared/utilities/database/userUtilities';
import { ManagedDocumentsListenerByQuery } from 'src/app/core/shared/utilities/firebase/firestoreUtilities';
import { UserService } from 'src/app/user/services/user.service';

@Injectable({
	providedIn: 'root',
})
export class MembershipsService extends DestroyableComponent {
	public memberships: BehaviorSubject<OrganizationMembership5[]> = new BehaviorSubject<OrganizationMembership5[]>([]);
	private membershipsListener: ManagedDocumentsListenerByQuery<OrganizationMembership5> =
		new ManagedDocumentsListenerByQuery(
			(memberships: OrganizationMembership5[] | undefined) => {
				console.log(`[MembershipsService] Loaded ${memberships?.length ?? 0} membership(s)!`);
				this.memberships.next(memberships ?? []);
			},
			(error: Error) => {
				console.error('Error listening to organization memberships', error);
			},
		)
			.hookStop(this)
			.enableRetry();

	constructor(private userService: UserService) {
		super();

		let lastUserId: string | undefined;

		userService.user.subscribe(user => {
			// if same user, return
			if (lastUserId === user?.docId) {
				return;
			}

			lastUserId = user?.docId;

			if (!user) {
				this.membershipsListener.pause();
				return;
			}

			this._listenForOrganizationMemberships();
		});
	}

	public isUserMemberOfOrganization(userId: string, organizationId: string): boolean {
		const memberships = this.memberships.value;
		if (!memberships) return false;
		return memberships.some(m => m.organizationRef.id === organizationId && m.userRef.id === userId);
	}

	private _listenForOrganizationMemberships() {
		const userId = this.userService.user.value?.docId;
		if (!userId) return;

		console.log(`[MembershipsService] Listening to user (${userId}) organization memberships.`);

		const membershipsQuery = query(organizationMembersCollectionGroup(), whereUserRef(userDoc(userId)));
		this.membershipsListener.listenTo(membershipsQuery);
	}
}
