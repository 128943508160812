import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SubtitleElement } from 'src/app/core/shared/models/layout/layoutElements';
import { InlineBlockLayoutElementComponent } from '../base/inline-block-layout-element.component';
import { LayoutElementRule } from '../../../models/layout/layoutBase';

@Component({
	selector: 'app-caption-element',
	template: `<p #pEl [innerHtml]="content | dynamic | sanitizeHtml" class="text-muted"></p>`,
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
})
export class CaptionElementComponent extends InlineBlockLayoutElementComponent {
	@ViewChild('pEl', { static: true }) pEl!: ElementRef<HTMLParagraphElement>;

	protected override get contextElement(): ElementRef<HTMLParagraphElement> {
		return this.pEl;
	}

	protected override get rules() {
		return super.rules.concat(LayoutElementRule.TopTight);
	}

	@Input() content: SubtitleElement['content'] = '';

	override setupAllValues(): void {
		// no values to setup
	}
	override setupValueForKey(key: keyof this): void {
		// no keys to setup
	}
	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to setup
	}
}
