<a
	(click)="onItemClick($event)"
	[class.active]="isActive"
	[class.disabled]="isDisabled"
	[class.highlighted]="isHighlighted"
	[id]="id"
	[navigationLink]="route"
	[params]="params"
	[style.order]="order"
	[tooltip]="tooltip"
	class="nav-item list-group-item list-group-item-action"
	type="button"
>
	<i [ngClass]="['fa-' + faType, faIcon !== undefined ? faIcon : '']" class="icon me-2"></i>
	<span class="flex-shrink-1 flex-grow-1 text-truncate"> {{ label }} </span>

	@if (actions.length > 0) {
		<div class="btn-group">
			@for (action of actions; track action.tooltip) {
				<button
					class="btn btn-sm border-med bg-white ps-1 pe-1"
					[ngClass]="[action.onlyShowOnHover ? 'show-only-on-hover' : '']"
					(click)="onActionClick(action, $event)"
					[tooltip]="action.tooltip"
				>
					<i class="fa-solid" [ngClass]="action.faIcon"></i>
				</button>
			}
		</div>
	}

	@for (toggle of toggles; let i = $index; track i) {
		<i
			class="extra text-body ms-1 togglable fa-solid"
			[ngClass]="[toggle.faIcon, toggle.onlyShowOnHover ? 'show-only-on-hover' : '']"
			(click)="$event.preventDefault(); $event.stopImmediatePropagation(); onToggleClick(toggle, $event)"
			[class.active]="toggle.isActive"
			[tooltip]="toggle.tooltip"
		></i>
	}
</a>
