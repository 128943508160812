import { Directive, EventEmitter, OnInit, Output } from '@angular/core';

@Directive({
	selector: '[ngInit]',
})
export class InitDirective implements OnInit {
	@Output('ngInit') initEvent = new EventEmitter();

	ngOnInit() {
		return this.initEvent.emit();
	}
}
