import { ManagedDocumentListenerByRef } from '../../../utilities/firebase/firestoreUtilities';
import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { DestroyableComponent } from '../../base/destroyable/destroyable.component';
import { Nullable, OrganizationMembership, SharedDocumentReference, UserInfo } from '../../../../../../build-dependencies/shared';
import { FsDoc } from '../../../models/Utility';
import { DocumentReference } from 'firebase/firestore';

@Component({
	selector: 'app-member-badge',
	templateUrl: './member-badge.component.html',
	styleUrls: ['./member-badge.component.scss'],
})
export class MemberBadgeComponent extends DestroyableComponent implements OnChanges, OnInit {
	@Input() memberRef?: Nullable<SharedDocumentReference> | FsDoc<unknown> | DocumentReference;

	protected isLoading: boolean = false;
	protected collectiveUserInfo?: UserInfo;
	protected userRef?: SharedDocumentReference;

	private memberListener = new ManagedDocumentListenerByRef<FsDoc<OrganizationMembership>>(
		(member?: OrganizationMembership) => {
			if (!member) {
				this.isLoading = false;
				this.collectiveUserInfo = undefined;
				this.userRef = undefined;
				return;
			}

			this.isLoading = false;
			this.collectiveUserInfo = {
				name: member.memberInfo.name || member.userInfo.name,
				image: member.memberInfo.image || member.userInfo.image,
				description: member.memberInfo.description || member.userInfo.description,
				email: member.userInfo.email,
			};
			this.userRef = member.userRef;
		},
		(error: Error) => {
			this.isLoading = false;
			this.collectiveUserInfo = undefined;
			console.error('Error listening to member', error);
		},
	)
		.enableRetry(true, 3, 250)
		.hookStop(this);

	ngOnInit(): void {
		this.ngOnChanges({
			// hack
			memberRef: new SimpleChange(undefined, this.memberRef, true),
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.memberRef === undefined || this.memberRef === null) return;

		if ('memberRef' in changes) {
			this.isLoading = true;
			this.memberListener.listenTo(
				'id' in this.memberRef ? (this.memberRef as DocumentReference) : this.memberRef.docRef,
			);
		}
	}
}
