import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: 'textarea[appAutoSize]',
})
export class AutoSizeDirective implements OnInit {
	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
	) {
		// Set initial styles to hide the scrollbar and prevent manual resizing
		this.renderer.setStyle(this.el.nativeElement, 'overflow-y', 'hidden');
		this.renderer.setStyle(this.el.nativeElement, 'resize', 'none');
	}

	ngOnInit() {
		this.resize();
	}

	@HostListener('input')
	onInput() {
		this.resize();
	}

	private resize() {
		const textarea = this.el.nativeElement;
		textarea.style.height = 'auto'; // Reset height
		if (!textarea.disabled) textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
	}
}
