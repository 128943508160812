import { Injectable } from '@angular/core';
import { HtmlBit } from '../../models/layout/html/base-html-bit';
import { TooltipElement } from './tooltip-element';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

	private static _instance: TooltipService;
	public static get instance(): TooltipService {
		if (!TooltipService._instance) {
			throw new Error('TooltipService is not created yet but was requested');
		}
		return TooltipService._instance;
	}

	constructor() {
		TooltipService._instance = this;
	}

	createTooltipOnElement(element: HTMLElement, content: HtmlBit): TooltipElement {
    const tooltipElement = document.createElement('div');
		tooltipElement.classList.add('tooltip');
		document.body.appendChild(tooltipElement);
		return new TooltipElement(element, tooltipElement, content, () => this._removeTooltip(tooltipElement));
	}

	private _removeTooltip(tooltipElement: HTMLElement) {
		if (tooltipElement.parentElement) {
			tooltipElement.remove();
		}
	}

}
