import { Injectable } from '@angular/core';
import { OutputBlockData } from '@editorjs/editorjs/types/data-formats/output-data';

type EditorJSListItem = {
	content: string;
	meta: object;
	items: EditorJSListItem[];
};

type ListStyle = 'checklist' | 'unordered' | 'ordered';

type EditorJSTableBlock = OutputBlockData<
	'table',
	{
		content: string[][];
	}
>;

@Injectable({
	providedIn: 'root',
})
export class EditorJsService {
	public toMarkdown(blocks: OutputBlockData[]): string {
		return blocks
			.map(block => {
				if (block.type == 'table') {
					return this.tableToMarkdown(block as EditorJSTableBlock);
				}

				const text = this.convertHtmlToMarkdown(block.data.text);

				switch (block.type) {
					case 'header':
						return '#'.repeat(block.data.level) + ' ' + text;
					case 'list': {
						const data = block.data as {
							style: ListStyle;
							meta: object;
							items: EditorJSListItem[];
						};

						return this.listToMarkdown(data.items, 0, data.style) + '\n&nbsp;';
					}
					case 'paragraph':
						return text;
				}

				throw new Error(`Unknown block type: ${block.type}`);
			})
			.join('\n\n');
	}

	public toEditorJS(markdownString: string): OutputBlockData[] {
		const lines = markdownString.split('\n');
		const blocks: OutputBlockData[] = [];

		for (let i = 0; i < lines.length; i++) {
			const line = lines[i].replace('&nbsp;', ' ');
			if (!line) continue;

			// Handle headers
			const headerMatch = line.match(/^(#{1,6})\s(.+)/);
			if (headerMatch) {
				blocks.push({
					type: 'header',
					data: {
						text: this.convertMarkdownToHtml(headerMatch[2]),
						level: headerMatch[1].length,
					},
				});
				continue;
			}

			// Table matching
			if (line.match(/\|.*\|/)) {
				let entireTable = line;

				for (let j = i + 1; j < lines.length; j++) {
					const line = lines[j];
					if (line.match(/\|.*\|/)) {
						entireTable += '\n' + line;
						i++;
					} else {
						break;
					}
				}

				const table = this.markdownToTable(entireTable);
				if (table.data.content.length > 0) {
					blocks.push(table);
				}

				continue;
			}

			// Handle lists (including indented)
			const listStart = line.match(this.listStartRegExp());

			if (listStart) {
				const totalListLines = [];

				while (lines[i].match(this.listStartRegExp())) {
					totalListLines.push(lines[i]);
					i++;
				}

				const listStyle: ListStyle =
					listStart[2].includes('[ ]') || listStart[2].includes('[x]')
						? 'checklist'
						: listStart[2].includes('.')
							? 'ordered'
							: 'unordered';

				const listItems = this.listToEditorJS(totalListLines, listStyle);

				blocks.push({
					type: 'list',
					data: {
						style: listStyle,
						meta: {},
						items: listItems,
					},
				});

				continue;
			}

			// Handle paragraphs (default case)
			blocks.push({
				type: 'paragraph',
				data: {
					text: this.convertMarkdownToHtml(line),
				},
			});
		}

		return blocks;
	}

	private getPrefixForStyleIndex(style: ListStyle, index: number, checked = false) {
		switch (style) {
			case 'ordered':
				// Sadly other list styles provided by EditorJS are not supported in markdown.
				return `- ${index + 1}. `;
			case 'checklist':
				return `- [${checked ? 'x' : ' '}]`;
			case 'unordered':
			default:
				return '-';
		}
	}

	private convertHtmlToMarkdown(text: string): string {
		// Convert HTML to Markdown decorations
		return text
			.replace(/<b>(.*?)<\/b>/g, '**$1**')
			.replace(/<i>(.*?)<\/i>/g, '*$1*')
			.replace(/<u[^>]*>(.*?)<\/u>/g, '__$1__')
			.replace(/<s>(.*?)<\/s>/g, '~~$1~~')
			.replace(/<a[^>]*href="(.*?)"[^>]*>(.*?)<\/a>/g, '[$2]($1)');
	}

	private convertMarkdownToHtml(text: string): string {
		return text
			.replace(/\*\*\*(.*?)\*\*\*/g, '<b><i>$1</i></b>')
			.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
			.replace(/\*(.*?)\*/g, '<i>$1</i>')
			.replace(/__(.*?)__/g, '<u class="cdx-underline">$1</u>')
			.replace(/~~(.*?)~~/g, '<s>$1</s>')
			.replace(/\[(.*?)]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');
	}

	private listToMarkdown(list: EditorJSListItem[], indent = 0, style: ListStyle = 'unordered'): string {
		let index = 0;

		return list
			.map(item => {
				const { content, items, meta } = item;
				if (!content) return;

				const prefix = this.getPrefixForStyleIndex(
					style,
					index++,
					'checked' in meta && (meta.checked as boolean),
				);

				return `${'\t'.repeat(indent)}${prefix} ${content}${items.length ? `\n${this.listToMarkdown(items, indent + 1, style)}` : ''}`;
			})
			.filter(Boolean)
			.join('\n');
	}

	private tableToMarkdown(block: EditorJSTableBlock): string {
		// If the table is empty, return an empty string
		if (!block.data.content || block.data.content.length === 0) {
			return '';
		}

		// Determine the number of columns
		const columnCount = block.data.content[0].length;

		// Create header row
		const headerRow = block.data.content[0].map(cell => cell.trim()).join(' | ');

		// Create separator row (required in Markdown tables)
		const separatorRow = Array(columnCount).fill('---').join(' | ');

		// Create data rows
		const dataRows = block.data.content
			.slice(1) // Skip header row
			.map(row => row.map(cell => cell.trim()).join(' | '));

		// Combine all parts of the Markdown table
		return ['| ' + headerRow + ' |', '| ' + separatorRow + ' |', ...dataRows.map(row => '| ' + row + ' |')].join(
			'\n',
		);
	}

	private markdownToTable(markdownTable: string): OutputBlockData<
		'table',
		{
			content: string[][];
			stretched: false;
			withHeadings: true;
		}
	> {
		// Remove leading and trailing whitespace and split into lines
		const lines = markdownTable.trim().split('\n');

		// If there are fewer than 3 lines, it's not a valid Markdown table
		if (lines.length < 3) {
			return {
				type: 'table',
				data: { content: [], stretched: false, withHeadings: true },
			};
		}

		try {
			// Remove surrounding pipes and split the header row
			const headerRow = lines[0]
				.replace(/^\||\|$/g, '')
				.split('|')
				.map(cell => this.convertMarkdownToHtml(cell.trim()));

			// Validate the separator row (should consist of ---/:-:/:-/etc.)
			const separatorRow = lines[1]
				.replace(/^\||\|$/g, '')
				.split('|')
				.map(cell => cell.trim());

			// Validate that separator row contains only separator-like content
			if (!separatorRow.every(cell => /^:?-+:?$/.test(cell))) {
				return {
					type: 'table',
					data: { content: [], stretched: false, withHeadings: true },
				};
			}

			// Parse data rows
			const dataRows = lines
				.slice(2)
				.map(line =>
					line
						.replace(/^\||\|$/g, '')
						.split('|')
						.map(cell => this.convertMarkdownToHtml(cell.trim())),
				)
				// Filter out any empty rows
				.filter(row => row.some(cell => cell !== ''));

			// Combine header and data rows
			const content = [headerRow, ...dataRows];

			return {
				type: 'table',
				data: { content, stretched: false, withHeadings: true },
			};
		} catch (error) {
			console.error(error);

			// If any parsing fails, return an empty table
			return {
				type: 'table',
				data: { content: [], stretched: false, withHeadings: true },
			};
		}
	}

	private listStartRegExp() {
		return new RegExp('^([\\t|\\s]*)(-\\s\\[(x|\\s)]|-?\\s?\\d+\\.|-)\\s(.+)', 'i');
	}

	private listToEditorJS(lines: string[], listType: ListStyle): EditorJSListItem[] {
		const items: EditorJSListItem[] = [];
		const indentStack: { indent: number; items: EditorJSListItem[] }[] = [{ indent: -1, items }];

		for (const line of lines) {
			const match = line.match(this.listStartRegExp());
			if (!match) continue;

			const indent = match[1].length;
			const prefix = match[2];
			const content = match[4];

			// Determine checked status for checklists
			const meta: { checked?: boolean } = {};
			if (listType === 'checklist') {
				meta.checked = prefix.includes('[x]');
			}

			// Find the appropriate parent list to add to
			while (indentStack.length > 0 && indentStack[indentStack.length - 1].indent >= indent) {
				indentStack.pop();
			}

			const newItem: EditorJSListItem = {
				content: this.convertMarkdownToHtml(content),
				meta,
				items: [],
			};

			// Add the new item to the most recent list at a lower indent level
			const parentList = indentStack[indentStack.length - 1].items;
			parentList.push(newItem);

			// If this item is more indented, it becomes a child of the previous item
			indentStack.push({ indent, items: newItem.items });
		}

		return items;
	}
}
