
<div class="d-flex flex-column mt-5 gap-2" [class.mb-3]="mode === 'normal'" [class.mb-0]="mode !== 'normal'">
	<div class="d-flex flex-row align-items-center gap-2 border-bottom justify-content-between" [class.border-subtle]="mode === 'normal'" [class.border-dark]="mode !== 'normal'">
		<h2 class="mb-0 d-flex flex-row flex-shrink-0 flex-grow-0 align-items-baseline gap-3 pb-0 pe-2">
			<img *ngIf="image" [src]="image" class="rounded-circle" style="width: 40px; height: 40px; transform: translateY(5px)">
			<i *ngIf="faIcon" class="fa-solid" [ngClass]="faIcon"></i>
			<span>{{ title }}</span>
			<span *ngIf="subtitle" class="fs-6 text-muted">{{ subtitle }}</span>
		</h2>
		<div class="d-flex flew-row justify-content-end pt-2 flex-grow-1 flex-nowrap">
			<ng-content></ng-content>
		</div>
	</div>
	<div *ngIf="description">{{ description }}</div>
</div>
