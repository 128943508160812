import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-input-suggestion',
	templateUrl: './input-suggestion.component.html',
	styleUrls: ['./input-suggestion.component.scss'],
})
export class InputSuggestionComponent implements OnInit {
	@Output() suggestionSelected: EventEmitter<string> = new EventEmitter<string>();
	@Input() suggestion: string = '';

	constructor() {}

	ngOnInit(): void {}

	emitSelectedSuggestion() {
		this.suggestionSelected.emit(this.suggestion);
	}
}
