import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Listener } from '../../../utilities/data/dynamic/listener';
import { ValueWrapper } from '../../../utilities/data/dynamic/valueWrapper';
import { DestroyableComponent } from '../destroyable/destroyable.component';

@Component({
	template: '',
})
export abstract class DynamicValuesComponent extends DestroyableComponent implements OnInit, OnChanges {
	protected readonly updatedKeyTimes: Map<string, number> = new Map();
	private readonly dynamicValuesListener = new Listener();

	ngOnInit(): void {
		this._listenToAllDynamicValues();
		this._setupAllValues();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const keys = Object.keys(changes);

		for (const key of keys) {
			const typedKey = key as keyof this;
			const oldValue = changes[key].previousValue;
			const newValue = changes[key].currentValue;

			this._stopListeningToDynamicValue(oldValue);
			this.setupValueForKey(typedKey);
			this._rememberJustUpdated(key);
			this._listenToDynamicValueOfKey(key, newValue);
		}
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this._stopListeningToAllDynamicValues();
	}

	protected abstract setupAllValues(): void;

	protected abstract setupValueForKey(key: keyof this): void;

	protected abstract setupValueInArrayForKey(key: keyof this, index: number): void;

	private _setupAllValues() {
		this.setupAllValues();
	}

	private _listenToAllDynamicValues() {
		this._stopListeningToAllDynamicValues();

		for (const [key, value] of Object.entries(this)) {
			this._listenToDynamicValueOfKey(key, value);
		}
	}

	private _stopListeningToAllDynamicValues() {
		this.dynamicValuesListener.stopListeningToAll();
	}

	private _listenToDynamicValueOfKey(key: string, value: unknown | unknown[]) {
		const typed = key as keyof this;

		if (value instanceof ValueWrapper) {
			this.dynamicValuesListener.listenTo(value, this._onDynamicValueUpdated.bind(this, typed));
		} else if (Array.isArray(value)) {
			for (const v of value) {
				if (v instanceof ValueWrapper) {
					this.dynamicValuesListener.listenTo(v, this._onDynamicValueUpdated.bind(this, typed));
				}
			}
		}
	}

	private _stopListeningToDynamicValue(value: unknown | unknown[]) {
		if (value instanceof ValueWrapper) {
			this.dynamicValuesListener.stopListeningTo(value);
		} else if (Array.isArray(value)) {
			for (const v of value) {
				if (v instanceof ValueWrapper) {
					this.dynamicValuesListener.stopListeningTo(v);
				}
			}
		}
	}

	private _onDynamicValueUpdated(key: keyof this) {
		this.setupValueForKey(key);
		this._rememberJustUpdated(key as string);
	}

	private _rememberJustUpdated(key: string) {
		this.updatedKeyTimes.set(key, Date.now());
	}
}
