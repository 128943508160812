import { ConvertPrimitiveString, JsonSchemaProperty, PrimitiveTypeStrings } from './general';

export type ToolConfiguration = Record<string, string | boolean | number>;

/**
 * Configuration for an AI function
 */
export type FunctionConfiguration = Record<
	string,
	JsonSchemaProperty extends infer T
		? T extends { type: infer Type }
			? Type extends PrimitiveTypeStrings
				?
						| (T & { required: false; default: ConvertPrimitiveString<Type> })
						| (T & {
								required: true;
								// angular doesn't understand if this is not here
								default: ConvertPrimitiveString<Type>;
						  })
				: never
			: never
		: never
>;

// this changed but to lazy to remove this entire type everywhere
export type InferFunctionsConfiguration<T extends FunctionConfiguration> = {
	[K in keyof T]: ConvertPrimitiveString<T[K]['type']>;
	// [K in keyof T]: T[K] extends { required: true }
	// 	? ConvertPrimitiveString<T[K]['type']>
	// 	: ConvertPrimitiveString<T[K]['type']> | undefined;
};

export const AIFunctionDefinitions = {
	RandomNumberTool: {
		title: 'ai-functions.random.title',
		description: 'ai-functions.random.description',
		configuration: {
			min: {
				type: 'number',
				required: true,
				default: 0,
				description: 'ai-functions.random.config.min',
			},
			max: {
				type: 'number',
				required: true,
				default: 10,
				description: 'ai-functions.random.config.max',
			},
		} satisfies FunctionConfiguration,
	},
	SearchTool: {
		title: 'ai-functions.searchOnline.title',
		description: 'ai-functions.searchOnline.description',
		configuration: {} satisfies FunctionConfiguration,
	},
	CalculateTool: {
		title: 'ai-functions.calculate.title',
		description: 'ai-functions.calculate.description',
		configuration: {} satisfies FunctionConfiguration,
	},
	AddMessageTool: {
		title: 'ai-functions.addMessage.title',
		description: 'ai-functions.addMessage.description',
		configuration: {} satisfies FunctionConfiguration,
	},
	TranscribeTool: {
		title: 'ai-functions.transcribe.title',
		description: 'ai-functions.transcribe.description',
		configuration: {
			context_prompt: {
				type: 'string',
				required: false,
				description: 'ai-functions.transcribe.config.context_prompt',
				default: '',
			},
		} satisfies FunctionConfiguration,
	},
	DescribeImageTool: {
		title: 'ai-functions.describeImage.title',
		description: 'ai-functions.describeImage.description',
		configuration: {
			context_prompt: {
				type: 'string',
				required: false,
				description: 'ai-functions.describeImage.config.context_prompt',
				default: '',
			},
		} satisfies FunctionConfiguration,
	},
	PdfGenerationTool: {
		title: 'ai-functions.createPdf.title',
		description: 'ai-functions.createPdf.description',
		configuration: {} satisfies FunctionConfiguration,
	},
	MistralTool: {
		title: 'ai-functions.transcribePdf.title',
		description: 'ai-functions.transcribePdf.description',
		configuration: {} satisfies FunctionConfiguration,
	},
	MeetingTool: {
		title: 'ai-functions.meeting.title',
		description: 'ai-functions.meeting.description',
		configuration: {
			bot_name: {
				type: 'string',
				required: false,
				default: 'Gen8 Note-taker',
				description: 'ai-functions.meeting.config.bot_name',
			},
			bot_image_url: {
				type: 'string',
				required: false,
				description: 'ai-functions.meeting.config.bot_image_url',
				default: '',
			},
			bot_entry_message: {
				type: 'string',
				required: false,
				description: 'ai-functions.meeting.config.bot_entry_message',
				default: '',
			},
		} satisfies FunctionConfiguration,
	},
	CancelMeetingTool: {
		title: 'ai-functions.cancel-meeting.title',
		description: 'ai-functions.cancel-meeting.description',
		configuration: {} satisfies FunctionConfiguration,
	},
};
