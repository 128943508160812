<!-- Sidebar -->
<nav
	*ngIf="routeDataService.RouteData.value.navbarShow"
	[class.d-none]="expanded"
	class="offcanvas offcanvas-start d-xl-block vh-100 border bg-light shadow"
	id="navbar-side-offcanvas"
	style="visibility: visible; max-width: 80vw"
	tabindex="-1"
>
	<div class="d-flex flex-column h-100">
		<!-- Header -->
		<div class="offcanvas-header d-flex flex-column gap-3 align-items-start">
			<!-- Close button -->
			<button
				aria-label="Close"
				class="btn-close btn-close-white text-reset d-xl-none"
				data-bs-dismiss="offcanvas"
				style="z-index: 999; position: absolute; right: -40px; top: 20px"
				type="button"
			></button>

			<!-- User/Org dropdown -->
			<div class="d-flex flex-row gap-2 flex-grow-1 w-100" id="switcher" role="group">
				<button
					[class.active]="routeDataService.RouteData.value.navbarContentAccountManagement"
					class="btn border-med bg-white flex-grow-1 flex-shrink-1"
					id="profileButton"
					navigationLink="@organization/details"
					type="button"
				>
					<div class="d-flex flex-row align-items-center gap-2 w-100">
						<div class="position-absolute" style="width: 50px; height: 40px">
							<img
								[src]="
									componentState.activeMembership?.organizationInfo?.image ||
									'./assets/img/blank-organization-profile-photo.png'
								"
								class="img-fuild rounded-circle border position-absolute"
								style="height: 40px; width: 40px; left: 10px"
							/>
							<img
								[src]="componentState.user?.info?.image || './assets/img/blank-profile-photo.png'"
								class="img-fuild rounded-circle border position-absolute"
								style="height: 25px; width: 25px; top: 0px; left: 0px"
							/>
						</div>

						<div class="names d-flex flex-column align-items-start w-100 mw-100" style="padding-left: 60px">
							<small class="user-name text-truncate d-inline-block" style="font-size: 0.75em">
								{{ componentState.user?.info?.name || ('navbar.user.noUser' | localize) }}
							</small>
							<span class="organization-name fw-bold z-3 d-inline-block text-truncate">
								{{
									componentState.activeMembership?.organizationInfo?.name ||
										('navbar.user.noOrg' | localize)
								}}
							</span>
						</div>
					</div>
				</button>

				<a
					aria-expanded="false"
					class="btn border-med bg-white d-flex align-items-center dropdown-toggle"
					data-bs-toggle="dropdown"
					href="#"
					id="profileDropdownButton"
					role="button"
				></a>

				<ul
					aria-labelledby="navbarDropdownProfile"
					class="dropdown-menu dropdown-menu-end shadow overflow-auto"
					id="switcherDropdown"
					style="max-height: 75vh"
				>
					<li class="px-2 pb-1">
						<small class="text-muted">{{
							componentState.activeMembership?.organizationInfo?.name || ('navbar.user.noOrg' | localize)
						}}</small>
					</li>

					<li>
						<a
							class="dropdown-item"
							id="accountDropdownItem"
							navigationLink="@organization/details"
							navigationLinkActive="highlight"
						>
							<i class="fa-solid fa-gear me-2"></i>
							{{ 'page.organization.settings.pageName' | localize }}
						</a>
					</li>

					<li>
						<a
							class="dropdown-item"
							id="accountDropdownItem"
							navigationLink="@organization/billing"
							navigationLinkActive="highlight"
						>
							<i class="fa-solid fa-bolt me-2"></i>
							{{ 'page.billing.pageName' | localize }}
						</a>
					</li>

					<li>
						<a
							class="dropdown-item"
							id="accountDropdownItem"
							navigationLink="@organization/members"
							navigationLinkActive="highlight"
						>
							<i class="fa-solid fa-house-user me-2"></i>
							{{ 'page.organization.members.pageName' | localize }}
						</a>
					</li>

					<ng-container *ifHostConfig="'ui.navbar.organization.dropdown.isSwitcherEnabled'">
						<ng-container *ngIf="(membershipsService.memberships.value.length ?? 0) > 1">
							<li>
								<hr class="dropdown-divider" />
							</li>

							<li class="px-2 pb-1">
								<small class="text-muted" localize="navbar.organization.switchTitle"></small>
							</li>

							<li
								(click)="selectMembership(membership); $event.preventDefault()"
								*ngFor="let membership of membershipsService.memberships.value"
							>
								<a
									[class.active]="
										membership.organizationRef.id === componentState.activeOrganizationId
									"
									class="dropdown-item"
									href="#"
									role="button"
								>
									<img
										[src]="
											membership.organizationInfo.image ||
											'./assets/img/blank-organization-profile-photo.png'
										"
										class="img-fuild rounded-circle border me-2 flex-shrink-0"
										style="height: 20px; width: 20px; margin-left: -3px"
									/>
									<span
										[id]="membership.organizationInfo.name + '_dropdownItem'"
										[ngStyle]="{
											'font-weight':
												membership.organizationRef.id === componentState.activeOrganizationId
													? 'bold'
													: 'normal',
										}"
										href="#"
									>
										{{ membership.organizationInfo.name }}
									</span>
								</a>
							</li>
						</ng-container>

						<li>
							<hr class="dropdown-divider" />
						</li>
					</ng-container>

					<li class="px-2 pb-1 d-flex flex-row flex-nowrap">
						<small class="text-muted text-truncate flex-grow-1 align-self-center">{{
							componentState.user?.info?.name || ('navbar.user.noUser' | localize)
						}}</small>
						<div class="d-flex flex-row align-self-center">
							<button
								(click)="localizationService.language = 'nl'; $event.preventDefault()"
								[class.btn-outline-light]="localizationService.language != 'nl'"
								[class.btn-outline-secondary]="localizationService.language == 'nl'"
								class="btn btn-sm px-2 py-1 ms-2"
							>
								<img
									[src]="'./assets/img/Flag_of_the_Netherlands.png'"
									style="height: 1em; width: 1.6em"
								/>
							</button>

							<button
								(click)="localizationService.language = 'en'; $event.preventDefault()"
								[class.btn-outline-light]="localizationService.language != 'en'"
								[class.btn-outline-secondary]="localizationService.language == 'en'"
								class="btn btn-sm px-2 py-1 ms-2"
							>
								<img
									[src]="'./assets/img/Flag_of_the_United_Kingdom.png'"
									style="height: 1em; width: 1.6em"
								/>
							</button>
						</div>
					</li>

					<li>
						<a
							class="dropdown-item"
							id="accountDropdownItem"
							navigationLink="@user/details"
							navigationLinkActive="highlight"
						>
							<i class="fa-solid fa-user me-2"></i>
							{{ 'page.user.account.pageName' | localize }}
						</a>
					</li>

					<li>
						<a
							class="dropdown-item"
							id="accountDropdownItem"
							navigationLink="@organization/details"
							navigationLinkActive="highlight"
						>
							<i class="fa-solid fa-buildings me-2"></i>
							{{ 'page.organizations.pageName' | localize }}
						</a>
					</li>

					<li>
						<a
							(click)="authService.logout(); (false)"
							class="dropdown-item"
							href="#"
							id="logOutDropdownItem"
							><i class="fa-solid fa-right-from-bracket me-1"></i>
							{{ 'navbar.user.logout' | localize }}</a
						>
					</li>

					<ng-container *ngIf="devService.devMode.value">
						<li>
							<hr class="dropdown-divider" />
						</li>

						<li class="px-2 pb-1">
							<small class="text-muted">Migrations</small>
						</li>

						<li>
							<a
								(click)="runMigration(); (false)"
								class="dropdown-item"
								href="#"
								id="runMigrationDropdownItem"
							>
								<i class="fa-solid fa-arrow-circle-right me-1"></i>
								Run migration
							</a>
						</li>

						<li>
							<a
								(click)="loadTemplates(); (false)"
								class="dropdown-item"
								href="#"
								id="loadTemplatesDropdownItem"
								><i class="fa-solid fa-arrow-circle-right me-1"></i> Load templates</a
							>
						</li>
						<li>
							<a
								(click)="loadSystemPrompts(); (false)"
								class="dropdown-item"
								href="#"
								id="loadSystemPromptsDropdownItem"
								><i class="fa-solid fa-arrow-circle-right me-1"></i> Load system prompts</a
							>
						</li>
						<li>
							<a
								(click)="loadPaymentPlans(); (false)"
								class="dropdown-item"
								href="#"
								id="loadPaymentPlansDropdownItem"
								><i class="fa-solid fa-arrow-circle-right me-1"></i> Load payment plans</a
							>
						</li>
						<li>
							<a
								(click)="loadWallets(); (false)"
								class="dropdown-item"
								href="#"
								id="loadWalletsDropdownItem"
								><i class="fa-solid fa-arrow-circle-right me-1"></i> Hand out wallets</a
							>
						</li>

						<li>
							<hr class="dropdown-divider" />
						</li>

						<li class="px-2 pb-1">
							<small class="text-muted">Spoof a host</small>
						</li>

						<li>
							<a
								(click)="devService.setHostSpoof(host); (false)"
								*ngFor="let host of devService.hostSpoofs"
								class="dropdown-item"
								href="#"
							>
								<i *ngIf="host === devService.currentHostSpoof" class="fa-solid fa-check me-1"></i>
								<i *ngIf="host !== devService.currentHostSpoof" class="fa-solid fa-globe me-1"></i>
								{{ host }}
							</a>
						</li>

						@if (environment?.versionString) {
							<li>
								<hr class="dropdown-divider" />
							</li>

							<li class="px-2 pb-1">
								<small class="text-muted">Version: {{ environment.versionString }}</small>
							</li>
						}
					</ng-container>
				</ul>
			</div>
		</div>

		<div class="flex-grow-1 bg-white overflow-visible">
			<!-- Route content -->
			@if (routeDataService.RouteData.value.navbarContentBackToProd) {
				<div class="offcanvas-body pb-0">
					<a
						id="backButton"
						class="btn btn-sm border-med text-start text-primary text-sm"
						style="width: fit-content"
						navigationLink="@mvp1"
					>
						< {{ 'navbar.backToProd' | localize }}
					</a>
				</div>
			}
			@if (routeDataService.RouteData.value.navbarContentAccountManagement) {
				<div class="offcanvas-body d-flex flex-column gap-2">
					<div class="list-group list-group-flush">
						<ng-container *ifHostConfig="'ui.page.organization.isDetailsEnabled'">
							<ng-container *ngIf="componentState.activeMembership">
								<app-nav-list-divider
									[label]="
										componentState.activeMembership.organizationInfo.name ||
										('navbar.user.noOrg' | localize)
									"
								></app-nav-list-divider>

								<app-nav-item
									*ifHasPermission="{
										for: ['organization.info.read'],
										forOperator: 'or',
									}"
									route="@organization/details"
									[label]="'page.organization.settings.pageName' | localize"
									faIcon="fa-gear"
									faType="solid"
								></app-nav-item>

								<ng-container *ifHostConfig="'ui.page.organization.billing.isEnabled'">
									<app-nav-item
										*ifHasPermission="{
											for: ['administration.creditHistory.read', 'administration.credits.read'],
											forOperator: 'or',
										}"
										route="@organization/billing"
										[label]="'page.billing.pageName' | localize"
										faIcon="fa-bolt"
										faType="solid"
									></app-nav-item>
								</ng-container>

								<ng-container *ifHostConfig="'ui.page.organization.members.isEnabled'">
									<app-nav-item
										*ifHasPermission="{
											for: [
												'memberships.inviteLinks.read',
												'memberships.invites.read',
												'memberships.members.read',
											],
											forOperator: 'or',
										}"
										route="@organization/members"
										[label]="'page.organization.members.pageName' | localize"
										faIcon="fa-house-user"
										faType="solid"
									></app-nav-item>
								</ng-container>
							</ng-container>
						</ng-container>

						<app-nav-list-divider
							[label]="componentState.user?.info?.name || ('navbar.user.noUser' | localize)"
						></app-nav-list-divider>

						<app-nav-item
							route="@user/details"
							[routingExact]="true"
							[label]="'page.user.account.pageName' | localize"
							faIcon="fa-user"
							faType="solid"
						></app-nav-item>

						<ng-container *ifHostConfig="'ui.page.user.billing.isEnabled'">
							<app-nav-item
								route="@user/billing"
								[routingExact]="true"
								[label]="'page.billing.pageName' | localize"
								faIcon="fa-bolt"
								faType="solid"
							></app-nav-item>
						</ng-container>

						<ng-container *ifHostConfig="'ui.page.organizations.isEnabled'">
							<app-nav-item
								route="@organizations"
								[routingExact]="true"
								[label]="'page.organizations.pageName' | localize"
								faIcon="fa-buildings"
								faType="solid"
							></app-nav-item>
						</ng-container>
					</div>
				</div>
			}

			<!-- Injected content -->
			<ng-container *ngFor="let content of layoutService.content['side']">
				<ng-container *ngTemplateOutlet="content.template"></ng-container>
			</ng-container>
		</div>

		<!-- Footer -->
		<!-- nothing -->
	</div>
</nav>
