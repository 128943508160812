import { NgModule, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subject, takeUntil } from 'rxjs';
import { AuthGuard } from "./auth/guards/auth.guard";

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./produce/produce.module').then(m => m.ProduceModule),
	},
	{
		path: 'templates',
		loadChildren: () => import('./template/template.module').then(m => m.TemplateModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
	},
	{
		path: 'organization',
		loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
		canActivate: [AuthGuard],
		data: { navbarContentBackToProd: true, navbarContentAccountManagement: true },
	},
	{
		path: 'organizations',
		loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule),
		canActivate: [AuthGuard],
		data: { navbarContentBackToProd: true, navbarContentAccountManagement: true },
	},
	{
		path: 'user',
		loadChildren: () => import('./user/user.module').then(m => m.UserModule),
		canActivate: [AuthGuard],
		data: { navbarContentBackToProd: true, navbarContentAccountManagement: true },
	},
	{
		path: 'payment',
		loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
		data: { navbarContentBackToProd: true },
	},
	{
		path: 'invite',
		loadChildren: () => import('./invite/invite.module').then(m => m.InviteModule),
		canActivate: [AuthGuard],
		data: { signup: true, navbarShow: false },
	},
	// {
	// 	path: '**',
	// 	redirectTo: '/',
	// 	pathMatch: 'full'
	// },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule implements OnDestroy {
	private onDestroy$ = new Subject<void>();

	constructor(private router: Router, private gaService: GoogleAnalyticsService) {
		// Subscribe to router events and send page view to Google Analytics
		this.router
			.events
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(event => {
				if (event instanceof NavigationEnd) this.gaService.pageView(event.urlAfterRedirects, undefined);
			});
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
