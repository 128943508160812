import { DotPatchAtom } from '../typeUtilities';
import { toasts } from '../../services/toasts.service';

export type ResultCallback<T> = (data: T) => void;

export abstract class DatabaseWrapper {
	protected requestTimestamps: number[];
	protected readonly THRESHOLD: number = 50; // Set your threshold here
	protected readonly TIME_PERIOD: number = 5000; // Time period in milliseconds
	protected readonly COOLDOWN_PERIOD: number = 5000; // Cooldown period in milliseconds
	protected isInCooldown: boolean = false;

	protected constructor() {
		this.requestTimestamps = [];
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	abstract get(path: string): Promise<any>;

	abstract update(path: string, data: object): Promise<void>;

	abstract patch<T>(path: string, patch: DotPatchAtom<T>[]): Promise<void>;

	abstract remove(path: string): Promise<void>;

	abstract set(path: string, data: unknown): Promise<void>;

	protected checkThreshold(): void {
		if (this.isInCooldown) {
			throw new Error('Database request threshold exceeded');
		}

		const now = Date.now();

		// Remove timestamps older than the time period from the start of the queue
		while (this.requestTimestamps.length > 0 && now - this.requestTimestamps[0] > this.TIME_PERIOD) {
			this.requestTimestamps.shift();
		}

		// Add the current request timestamp to the end of the queue
		this.requestTimestamps.push(now);

		// If the number of requests in the time period exceeds the threshold, print a warning
		if (this.requestTimestamps.length > this.THRESHOLD) {
			toasts.error(
				`Database request threshold exceeded! Please wait before proceeding. (${this.requestTimestamps.length} requests in the last ${this.TIME_PERIOD}ms)`,
				undefined,
				undefined,
				this.COOLDOWN_PERIOD,
			);
			if (!this.isInCooldown) {
				this.isInCooldown = true;
				setTimeout(() => (this.isInCooldown = false), this.COOLDOWN_PERIOD); // Set cooldown period to 5 seconds
			}
		}
	}
}

export abstract class FirebaseWrapper extends DatabaseWrapper {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	abstract listen(path: string, onResult: any): () => void;
}
