import { OrganizationPermissions } from './index';

export enum OrganizationPermissibleCategories {
	ORGANIZATION = 'organization',
	MEMBERSHIPS = 'memberships',
	ADMINISTRATION = 'administration',
	MVP1 = 'mvp1',
}

export enum OrganizationPermissibleFeatures {
	INFO = 'info',
	VARIABLES = 'variables',
	MEMBERS = 'members',
	PERMISSIONS = 'permissions',
	INVITES = 'invites',
	INVITELINKS = 'inviteLinks',
	PUBLICTOKENS = 'publicTokens',
	CREDITHISTORY = 'creditHistory',
	CREDITS = 'credits',
	ACCESSTOKENS = 'accessTokens',
	TEMPLATES = 'templates',
	PACKS = 'packs',
	CHATS = 'chats',
}

export enum OrganizationPermissibleActions {
	CREATE = 'create',
	READ = 'read',
	UPDATE = 'update',
	DELETE = 'delete',
}

export type OrganizationActionPermissionPath =
	| 'organization.info.read'
	| 'organization.info.update'
	| 'organization.info.delete'
	| 'organization.variables.read'
	| 'organization.variables.update'
	| 'organization.variables.delete'
	| 'memberships.members.read'
	| 'memberships.members.update'
	| 'memberships.members.delete'
	| 'memberships.permissions.read'
	| 'memberships.permissions.update'
	| 'memberships.invites.create'
	| 'memberships.invites.read'
	| 'memberships.invites.update'
	| 'memberships.invites.delete'
	| 'memberships.inviteLinks.create'
	| 'memberships.inviteLinks.read'
	| 'memberships.inviteLinks.update'
	| 'memberships.inviteLinks.delete'
	| 'memberships.publicTokens.create'
	| 'memberships.publicTokens.read'
	| 'memberships.publicTokens.update'
	| 'memberships.publicTokens.delete'
	| 'administration.creditHistory.read'
	| 'administration.credits.read'
	| 'mvp1.accessTokens.create'
	| 'mvp1.accessTokens.read'
	| 'mvp1.accessTokens.update'
	| 'mvp1.accessTokens.delete'
	| 'mvp1.templates.create'
	| 'mvp1.templates.read'
	| 'mvp1.templates.update'
	| 'mvp1.templates.delete'
	| 'mvp1.chats.create'
	| 'mvp1.chats.read'
	| 'mvp1.chats.update'
	| 'mvp1.chats.delete';

export const ORGANIZATION_OWNER_PERMISSIONS: OrganizationPermissions = {
	organization: {
		info: { read: true, update: true, delete: true },
		variables: { read: true, update: true, delete: true },
		packs: { create: true, read: true, update: true, delete: true },
	},
	memberships: {
		members: { read: true, update: true, delete: true },
		permissions: { read: true, update: true },
		invites: { create: true, read: true, update: true, delete: true },
		inviteLinks: { create: true, read: true, update: true, delete: true },
		publicTokens: { create: true, read: true, update: true, delete: true },
	},
	administration: {
		creditHistory: { read: true },
		credits: { read: true },
	},
	mvp1: {
		accessTokens: { create: true, read: true, update: true, delete: true },
		templates: { create: true, read: true, update: true, delete: true },
		chats: { create: true, read: true, update: true, delete: true },
	},
};

export const ORGANIZATION_MANAGER_PERMISSIONS: OrganizationPermissions = {
	...ORGANIZATION_OWNER_PERMISSIONS,
	organization: {
		...ORGANIZATION_OWNER_PERMISSIONS.organization,
		info: { read: true, update: true, delete: false },
	},
};

export const ORGANIZATION_CREATOR_PERMISSIONS: OrganizationPermissions = {
	organization: {
		...ORGANIZATION_OWNER_PERMISSIONS.organization,
		info: { read: true, update: false, delete: false },
	},
	memberships: {
		members: { read: true, update: false, delete: false },
		permissions: { read: false, update: false },
		invites: { create: false, read: false, update: false, delete: false },
		inviteLinks: {
			create: false,
			read: false,
			update: false,
			delete: false,
		},
		publicTokens: { create: true, read: true, update: true, delete: true },
	},
	administration: {
		creditHistory: { read: false },
		credits: { read: true },
	},
	mvp1: {
		accessTokens: { create: true, read: true, update: true, delete: true },
		templates: { create: true, read: true, update: true, delete: true },
		chats: { create: true, read: true, update: true, delete: false },
	},
};

export const ORGANIZATION_USER_PERMISSIONS: OrganizationPermissions = {
	organization: {
		info: { read: true, update: false, delete: false },
		variables: { read: true, update: true, delete: false },
		packs: { create: false, read: false, update: false, delete: false },
	},
	memberships: {
		members: { read: true, update: false, delete: false },
		permissions: { read: false, update: false },
		invites: { create: false, read: false, update: false, delete: false },
		inviteLinks: {
			create: false,
			read: false,
			update: false,
			delete: false,
		},
		publicTokens: {
			create: false,
			read: true,
			update: false,
			delete: false,
		},
	},
	administration: {
		creditHistory: { read: false },
		credits: { read: true },
	},
	mvp1: {
		accessTokens: {
			create: false,
			read: true,
			update: false,
			delete: false,
		},
		templates: { create: false, read: false, update: false, delete: false },
		chats: { create: true, read: true, update: true, delete: false },
	},
};

export const ORGANIZATION_GUEST_PERMISSIONS: OrganizationPermissions = {
	organization: {
		info: { read: false, update: false, delete: false },
		variables: { read: false, update: false, delete: false },
		packs: { create: false, read: false, update: false, delete: false },
	},
	memberships: {
		members: { read: false, update: false, delete: false },
		permissions: { read: false, update: false },
		invites: { create: false, read: false, update: false, delete: false },
		inviteLinks: {
			create: false,
			read: false,
			update: false,
			delete: false,
		},
		publicTokens: {
			create: false,
			read: false,
			update: false,
			delete: false,
		},
	},
	administration: {
		creditHistory: { read: false },
		credits: { read: false },
	},
	mvp1: {
		accessTokens: {
			create: false,
			read: false,
			update: false,
			delete: false,
		},
		templates: { create: false, read: false, update: false, delete: false },
		chats: { create: true, read: false, update: false, delete: false }, // a guest can create chats, but they will be private and only they can see, edit and delete them
	},
};

export const ORGANIZATION_PUBLIC_PERMISSIONS: OrganizationPermissions = {
	...ORGANIZATION_GUEST_PERMISSIONS,
	mvp1: {
		...ORGANIZATION_GUEST_PERMISSIONS.mvp1,
		chats: { create: false, read: false, update: false, delete: false },
	},
};
