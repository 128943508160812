import { LayoutElementComponent } from './layout-element.component';
import { LayoutElementRule } from '../../../models/layout/layoutBase';

export abstract class FlexRowLayoutElementComponent extends LayoutElementComponent {
	protected override get rules(): LayoutElementRule[] {
		return [LayoutElementRule.BottomTight, LayoutElementRule.VerticalTightBetween, LayoutElementRule.TopTight];
	}

	protected override setupRuleBasedStyles(calculatedRules: Set<LayoutElementRule>): void {
		this.setClass(['d-flex', 'flex-row', 'gap-2', 'w-100', 'align-items-start', 'justify-content-stretch']);
		this.setClass('mt-0', calculatedRules.has(LayoutElementRule.TopTight));
		this.setClass('mb-3', !calculatedRules.has(LayoutElementRule.BottomTight), 'mb-0');
	}
}
