import { Timestamp } from 'firebase/firestore';

export function unknownToDate(value: unknown): Date {
	if (!value) throw new Error('Value cannot be null or undefined');

	const ret =
		value instanceof Timestamp
			? value.toDate()
			: value instanceof Date
				? value
				: typeof value == 'number'
					? new Date(value)
					: Error('Value is not a timestamp, date, or number');

	if (ret instanceof Error) throw ret;
	return ret;
}
