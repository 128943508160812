import { HostConfig, HostConfigOption, hostConfigTargets } from '../../models/config/host-config/hostConfigModels';
import { deepMerge } from '../../utilities/objectUtilities';
import { Injectable } from '@angular/core';
import { hostConfigs } from 'src/config/host-config';
import { ActiveOrganizationService } from 'src/app/organization/services/active-organization.service';
import { defaultHostConfig } from '../../models/config/host-config/hostConfigDefaults';
import { Subject } from 'rxjs';
import { AppLocalizationModifications } from '../../models/config/localization/localizationModels';
import { devModeHostSpoof } from '../../services/dev.service';

@Injectable({
	providedIn: 'root',
})
export class HostConfigService {
	// throw empty event to notify using Subject
	public onHostConfigChange: Subject<void> = new Subject<void>();
	private hostConfig: HostConfig = {};
	private hostname: string = devModeHostSpoof ?? window.location.hostname;
	private activeOrganizationId: string = '';

	constructor(private orgService: ActiveOrganizationService) {
		this.hostConfig = deepMerge(this.hostConfig, defaultHostConfig);
		if (this.hostname in hostConfigs) {
			this.hostConfig = deepMerge(this.hostConfig, hostConfigs[this.hostname]);
		}

		//console.log("default host config", defaultHostConfig);
		//console.log("Host config for host", this.hostname, this.hostConfig);

		this.orgService.activeMembership.subscribe(membership => {
			this.activeOrganizationId = membership?.organizationRef.id || '';
		});
	}

	private static getForConfig(config: HostConfig, option: HostConfigOption, organizationId: string) {
		switch (option) {
			default: {
				let o: string = option;
				if (o.startsWith('organization.')) {
					o = o.substring('organization.'.length);
				}
				return (
					HostConfigService.getOrganizationConfigValue(config, o, organizationId) ??
					this.logErrorAndReturn(config, option)
				);
			}
		}
	}

	private static getOrganizationConfigValue(config: HostConfig, option: string, organizationId: string) {
		const thisOrgConfig = HostConfigService.getOrganizationConfig(config, organizationId);
		const lookIn = [thisOrgConfig, config];

		const path = option.split('.');

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let value: any = undefined;
		for (const orgConfig of lookIn) {
			if (orgConfig) {
				value = orgConfig;
				for (const key of path) {
					value = value[key];
					if (value === undefined) {
						break;
					}
				}
				if (value !== undefined) {
					break;
				}
			}
		}
		return value;
	}

	private static logErrorAndReturn<T>(config: HostConfig, option: HostConfigOption, returnValue?: T) {
		console.error(
			'Error getting value from config',
			config,
			'with option',
			option,
			'for host',
			'. returning value:',
			returnValue,
		);
		return returnValue;
	}

	private static getOrganizationConfig(config: HostConfig, organizationId: string) {
		return config.organizations?.linked?.[organizationId] ?? config.organizations?.nonLinked ?? config ?? {};
	}

	get(option: HostConfigOption, overrideOrganizationId?: string) {
		return HostConfigService.getForConfig(
			this.hostConfig,
			option,
			overrideOrganizationId ?? this.activeOrganizationId,
		);
	}

	getHostLocalizationModifications(): AppLocalizationModifications {
		return this.hostConfig.ui?.localization ?? {};
	}

	getActiveOrganizationLocalizationModifications(): AppLocalizationModifications {
		return this.getOrganizationLocalizationModifications(this.activeOrganizationId);
	}

	getOrganizationLocalizationModifications(organizationId: string): AppLocalizationModifications {
		return HostConfigService.getOrganizationConfig(this.hostConfig, organizationId).ui?.localization ?? {};
	}

	getLinkedOrganizations() {
		return this.hostConfig.organizations?.linked ?? {};
	}

	getTarget(option: HostConfigOption) {
		return hostConfigTargets[option];
	}
}
