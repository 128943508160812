import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationKey } from '../../models/config/localization/localizationModels';

@Pipe({
	name: 'asLocalizationKey',
})
export class AsLocalizationKeyPipe implements PipeTransform {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transform(value: string, args?: any): LocalizationKey {
		return value as LocalizationKey;
	}
}
