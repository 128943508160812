export enum CheckoutSessionStatus {
	COMPLETED = 'completed',
	PAID = 'paid',
	PENDING = 'pending',
	FAILED = 'failed',
	EXPIRED = 'expired',
}

export type CreateCreditsCheckoutSessionBody = {
	quantity: number;
	walletId: string;
	giftcard: boolean;
};

export type CreateCheckoutSessionResult = {
	success: boolean;
	checkoutUrl: string;
};

export type CreatePortalSessionResult = {
	portalUrl: string;
};
