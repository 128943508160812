import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

export type Loader = {
	key: string;
	message: string;
};

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	loadingMessages: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	loadingMessage = this.loadingMessages.pipe(
		map(messages =>
			messages.length > 0 ? { isLoading: true, message: messages[0] } : { isLoading: false, message: undefined },
		),
	); // only the first message is used, if any
	private loaders: { [key: string]: Loader } = {};

	constructor() {}

	public addLoading(key: string, message: string): void {
		this.loaders[key] = { key, message };
		this.isLoading.next(true);
		this.loadingMessages.next(Object.values(this.loaders).map(loader => loader.message));
	}

	public removeLoading(key: string): void {
		delete this.loaders[key];
		if (Object.keys(this.loaders).length === 0) {
			this.isLoading.next(false);
		}
		this.loadingMessages.next(Object.values(this.loaders).map(loader => loader.message));
	}
}
