import { iTimestamp, SharedDocumentReference } from '../util/internal';
import { Nullable } from '../util/common';

export type UserNotification = {
	created: iTimestamp;
	organizationRef: SharedDocumentReference;
	organizationName: string;
	type: UserNotificationType;
	contextRef: Nullable<SharedDocumentReference>; // chatId or templateId or userId, etc. Depends on the notification type
	title: string;
	description: string;
	read: boolean;
};

export enum UserNotificationType {
	ORGANIZATION_INVITE_RECEIVED = 'organization_invite_received',
	ORGANIZATION_INVITE_ACCEPTED = 'organization_invite_accepted',
	ORGANIZATION_INVITE_DECLINED = 'organization_invite_declined',
	ORGANIZATION_INVITE_REMINDER = 'organization_invite_reminder',
	ORGANIZATION_INVITE_EXPIRED = 'organization_invite_expired',
}
