import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
	selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
	constructor(private elementRef: ElementRef) {}

	ngAfterViewInit() {
		// Set a timeout to ensure the element is available in the DOM
		setTimeout(() => {
			this.elementRef.nativeElement.focus();
		});
	}
}
