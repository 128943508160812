import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { LayoutElement } from '../../models/layout/layoutBase';
import { LayoutElementComponent } from './base/layout-element.component';

@Component({
  selector: 'app-layout',
  template: `<ng-template #container></ng-template>`,
	styles: [`
		:host {
			display: contents;
		}
	`]
})
export class LayoutComponent<T extends LayoutElement> implements OnInit {
	@ViewChild('container', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;

	protected elements: T[] = [];

	@Input({ alias: 'elements', required: true }) set _elements(elements:T[] | T) {
		this.elements = Array.isArray(elements) ? elements : [elements];
	}

	constructor(el: ElementRef, renderer: Renderer2) {
		renderer.addClass(el.nativeElement, 'w-100');
	}

	ngOnInit(): void {
		LayoutElementComponent.draw(this.container, this.elements, null);
	}

	ngOnChanges(): void {
		this.ngOnInit();
	}
}
