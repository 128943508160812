export const instant = <T>(cb: () => T, delay = 0) =>
	new Promise<T>((resolve, reject) =>
		setTimeout(
			() => {
				try {
					resolve(cb());
				} catch (error) {
					reject(error);
				}
			},
			Math.max(delay, 0),
		),
	);
