import { Component, Input } from '@angular/core';
import { HeaderElement } from 'src/app/core/shared/models/layout/layoutElements';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';
import { InlineBlockLayoutElementComponent } from '../base/inline-block-layout-element.component';
import { LayoutElementRule } from '../../../models/layout/layoutBase';

@Component({
	selector: `app-header-element`,
	template: ``,
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
})
export class HeaderElementComponent extends InlineBlockLayoutElementComponent {
	@Input() level: HeaderElement['level'] = 1;
	@Input() content: HeaderElement['content'] = '';

	override ngOnInit(): void {
		super.ngOnInit();
		this.setClass('py-1');
	}

	// override ngOnInit(): void {
	// 	super.ngOnInit();

	// 	const isNotHorizontalFlex = !this.isInHorizontalFlex();

	// 	// only add margin top if previous element is not a subtitle
	// 	const prevIsSubtitle = this.isPreviousSiblingOfType(SubtitleElementComponent);
	// 	const prevIsText = this.isPreviousSiblingOfType(TextElementComponent);
	// 	this.setClass('mt-3', !isNotHorizontalFlex && !prevIsSubtitle && !prevIsText);
	// 	this.setClass('mt-0', isNotHorizontalFlex || prevIsSubtitle || prevIsText);

	// 	// only add margin bottom if next element is not a subtitle or divider
	// 	const nextIsSubtitle = this.isNextSiblingOfType(SubtitleElementComponent);
	// 	const nextIsDivider = this.isNextSiblingOfType(DividerElementComponent);
	// 	this.setClass('mb-1', !isNotHorizontalFlex && !nextIsSubtitle && !nextIsDivider);
	// 	this.setClass('mb-0', isNotHorizontalFlex || nextIsSubtitle || nextIsDivider);
	// }

	protected override get rules(): LayoutElementRule[] {
		return super.rules.concat([LayoutElementRule.BottomTight]);
	}

	override setupAllValues(): void {
		this.setupValueForKey('level');
	}

	override setupValueForKey(key: keyof this): void {
		if (key === 'level' || key === 'content') {
			const unwrappedLevel = unwrap(this.level);
			const unwrappedContent = unwrap(this.content);
			this.contextElement.nativeElement.innerHTML = `<h${unwrappedLevel} class="m-0">${unwrappedContent.toString()}</h${unwrappedLevel}>`;
		}
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays
	}
}
