import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalizationKey } from '../models/config/localization/localizationModels';
import { LocalizationService } from '../directives/localization/localization.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export type Toast = {
	id: string;
	message: string;
	header?: string;
	type: ToastType;
	duration: number;
	createdAt: number;
	onClick?: () => void;
};

export enum ToastType {
	Success = 'success',
	Error = 'error',
	Warning = 'warning',
	Info = 'info',
}

export let toasts: ToastsService;

@Injectable({
	providedIn: 'root',
})
export class ToastsService {
	public toasts: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);

	constructor(
		private localizationService: LocalizationService,
		private gaService: GoogleAnalyticsService,
	) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		toasts = this;
	}

	error(
		error: string | LocalizationKey | Error = 'toasts.error.internal',
		debug: string = 'Unknown error',
		onClick?: () => void,
		duration: number = 5000,
	) {
		const message =
			typeof error === 'string'
				? error
				: // firebase adds the localizationId as extra details to the error object
					// retrieval can probably be cleaner though
					//eslint-disable-next-line @typescript-eslint/no-explicit-any
					(((error as any).details?.localizationId as string) ?? 'toasts.error.unknown');

		console.error(debug);
		this.gaService.exception(debug);

		this.add(null, message, ToastType.Error, onClick, duration);
	}

	info(message: string | LocalizationKey, onClick?: () => void, duration: number = 5000) {
		this.add(null, message, ToastType.Info, onClick, duration);
	}

	success(message: string | LocalizationKey, onClick?: () => void, duration: number = 5000) {
		this.add(null, message, ToastType.Success, onClick, duration);
	}

	removeToast(id: string) {
		this.toasts.next(this.toasts.getValue().filter(toast => toast.id !== id));
	}

	getToasts(): Toast[] {
		return this.toasts.getValue();
	}

	clearToasts() {
		this.toasts.next([]);
	}

	private add(
		header: string | LocalizationKey | null,
		message: string | LocalizationKey,
		type: ToastType,
		onClick?: () => void,
		duration: number = 5000,
	) {
		if (this.localizationService.keyExists(message as LocalizationKey)) {
			message = this.localizationService.get(message as LocalizationKey);
		} else {
			console.warn('toasts message could not be localized');
			message = message as string;
		}

		if (header && this.localizationService.keyExists(header as LocalizationKey)) {
			header = this.localizationService.get(header as LocalizationKey);
		} else if (header) {
			console.warn('toasts header could not be localized');
			header = header as string;
		}

		const toast: Toast = {
			id: this.generateId(),
			message,
			header: header ? header : undefined,
			type,
			duration,
			createdAt: Date.now(),
			onClick,
		};

		this.toasts.next([...this.toasts.getValue(), toast]);
		setTimeout(() => {
			this.removeToast(toast.id);
		}, duration);
	}

	private generateId(): string {
		return Math.random().toString(36).slice(2, 9);
	}
}
