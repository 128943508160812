import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SubtitleElement } from 'src/app/core/shared/models/layout/layoutElements';
import { LayoutElementRule } from '../../../models/layout/layoutBase';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';

@Component({
	selector: 'app-subtitle-element',
	template: `<p #pEl [innerHtml]="content | dynamic | sanitizeHtml" class="text-muted"></p>`,
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
})
export class SubtitleElementComponent extends BlockLayoutElementComponent {
	@ViewChild('pEl', { static: true }) pEl!: ElementRef<HTMLParagraphElement>;
	@Input() content: SubtitleElement['content'] = '';

	protected override get contextElement(): ElementRef<HTMLParagraphElement> {
		return this.pEl;
	}

	protected override get rules() {
		return super.rules.concat(LayoutElementRule.TopTight);
	}

	// override ngOnInit(): void {
	// 	super.ngOnInit();

	// 	// only add margin top if previous element is not a header
	// 	const useTopMargins = !this.isInHorizontalFlex() && !this.isPreviousSiblingOfType(HeaderElementComponent);
	// 	this.setClass('mt-3', useTopMargins);
	// 	this.setClass('mt-0', !useTopMargins);

	// 	// only add margin bottom if next element is not a header or divider
	// 	const useBottomMargins = !this.isInHorizontalFlex() && !this.isNextSiblingOfType(HeaderElementComponent) && !this.isNextSiblingOfType(DividerElementComponent);
	// 	this.setClass('mb-3', useBottomMargins);
	// 	this.setClass('mb-0', !useBottomMargins);
	// }

	override setupAllValues(): void {
		// no values to set up
	}

	override setupValueForKey(key: keyof this): void {
		// no keys to set up
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to set up
	}
}
