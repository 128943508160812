import { Component, Input } from '@angular/core';
import { TabLayoutGroupItem } from 'src/app/core/shared/models/layout/layoutGroups';
import { DynamicValuesComponent } from '../../base/dynamic-values/dynamic-values.component';

@Component({
	selector: 'app-tab-layout-group-item-body',
	template: `
		<div class="card-body" [class.d-none]="show">
			<app-layout [elements]="elements"></app-layout>
		</div>
	`,
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
})
export class TabLayoutGroupItemBodyComponent extends DynamicValuesComponent {
	@Input() show = false;
	@Input() elements: TabLayoutGroupItem['elements'] = [];

	override setupAllValues(): void {
		// no values to set up
	}

	override setupValueForKey(key: keyof this): void {
		// no keys to set up
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to set up
	}
}
