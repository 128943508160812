import { Component, ElementRef, ViewChild } from '@angular/core';
import { httpsCallable } from '@firebase/functions';
import { AuthService } from 'src/app/auth/services/auth.service';
import { fbFunctions } from 'src/app/firebase-init';
import { ActiveOrganizationService } from 'src/app/organization/services/active-organization.service';
import { MembershipsService } from 'src/app/organization/services/memberships.service';
import { OrganizationMembership5 } from '../../models/database/organizationModels';
import { DevService } from '../../services/dev.service';
import { RouteDataService } from '../../services/page/route-data.service';
import {
	ComponentState,
	PageStateBasedComponent,
	RequiredPageState,
} from '../base/page-state-based/page-state-based.component';
import { LayoutService } from '../../services/page/layout.service';
import { LocalizationService } from '../../directives/localization/localization.service';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'app-navbar-side',
	templateUrl: './navbar-side.component.html',
	styleUrl: './navbar-side.component.scss',
})
export class NavbarSideComponent extends PageStateBasedComponent {
	@ViewChild('#navbarSideToggler') protected toggler?: ElementRef;

	protected readonly environment = environment;

	constructor(
		protected activeOrgService: ActiveOrganizationService,
		protected authService: AuthService,
		protected devService: DevService,
		protected routeDataService: RouteDataService,
		protected layoutService: LayoutService,
		protected membershipsService: MembershipsService,
		protected localizationService: LocalizationService,
	) {
		super();
	}

	public get expanded(): boolean {
		if (!this.toggler) return false;
		return this.toggler.nativeElement.getAttribute('aria-expanded') === 'true';
	}

	override getRequiredPageState(): RequiredPageState | undefined {
		return undefined;
	}

	override async onComponentStateUpdate(componentState: ComponentState): Promise<void> {
		// do nothing
	}

	selectMembership(membership: OrganizationMembership5) {
		if (!membership) return;
		this.activeOrgService.activateOrganizationByMembership(membership);
	}

	runMigration() {
		//if (this.isLocalhost) {
		// show confirm dialog
		if (confirm('Are you sure you want to run the migration?')) {
			const functions = [
				'runWalletOwnerMigrations5',

				//"runOrganizationsMigrations5",
				//"runOrganizationMembersMigrations5",
				//"runFormTemplateMigrations5",
				// "runTemplateMigrations5",
				// "runChatsMigrations5",
				// "runCustomMigrations5"
			];

			functions.forEach(functionName => {
				httpsCallable(fbFunctions, functionName)
					.call(null)
					.then(result => {
						//this.toastsService.info('Migration ' + functionName + ' started');
					})
					.catch(error => {
						//this.toastsService.error('Error starting migration');
					});
			});
		}
		//}
	}

	loadTemplates() {
		//if (this.isLocalhost) {
		httpsCallable(fbFunctions, 'loadTemplates5')
			.call(null)
			.then(result => {
				//this.toastsService.info('System prompts loaded');
			})
			.catch(error => {
				//this.toastsService.error('Error loading System prompts');
			});
		//}
	}

	loadSystemPrompts() {
		//if (this.isLocalhost) {
		httpsCallable(fbFunctions, 'loadSystemPrompts5')
			.call(null)
			.then(result => {
				//this.toastsService.info('System prompts loaded');
			})
			.catch(error => {
				//this.toastsService.error('Error loading System prompts');
			});
		//}
	}

	loadPaymentPlans() {
		//if (this.isLocalhost) {
		httpsCallable(fbFunctions, 'loadPaymentPlans5')
			.call(null)
			.then(result => {
				//this.toastsService.info('Payment plans loaded');
			})
			.catch(error => {
				//this.toastsService.error('Error loading payment plans');
			});
		//}
	}

	loadWallets() {
		//if (this.isLocalhost) {
		httpsCallable(fbFunctions, 'createWalletsForAll5')
			.call(null)
			.then(result => {
				//this.toastsService.info('Wallets loaded');
			})
			.catch(error => {
				//this.toastsService.error('Error loading wallets');
			});
		//}
	}
}
