import { MembershipsService } from '../../../../organization/services/memberships.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ActiveOrganizationService } from 'src/app/organization/services/active-organization.service';
import { memberHasPermissionFor } from '../../utilities/database/permissionUtilities';
import { OrganizationActionPermissionPath } from '../../../../../build-dependencies/shared';

@Pipe({
	name: 'ifHasPermission',
})
export class IfHasPermissionPipe implements PipeTransform {
	constructor(
		private activeOrganizationService: ActiveOrganizationService,
		private membershipsService: MembershipsService,
	) {}

	transform(value: OrganizationActionPermissionPath, organizationId?: string) {
		let membership = this.activeOrganizationService.activeMembership.value;
		if (organizationId) {
			membership = this.membershipsService.memberships.value.find(m => m.organizationRef.id === organizationId);
		}
		return memberHasPermissionFor(membership, value);
	}
}
