import { isWrappedValue, BaseValueWrapper } from './valueWrapper';

export type Dynamic<T> = T | BaseValueWrapper<T>;
export type DynamicString = Dynamic<string>;
export type DynamicNumber = Dynamic<number>;
export type DynamicBoolean = Dynamic<boolean>;
export type DynamicBigInt = Dynamic<bigint>;
export type DynamicSymbol = Dynamic<symbol>;

export type DynamicDate = Dynamic<Date>;
export type DynamicArray<T> = Dynamic<Dynamic<T>[]>;

export type Unwrapped<T> =
	T extends BaseValueWrapper<infer U>
		? U
		: T extends Dynamic<infer U>
			? U
			: T extends DynamicArray<infer U>
				? U[]
				: T;

export type UnwrappedElement<T, I extends number> = T extends unknown[] ? Unwrapped<T[I]> : never;

export const unwrap = <T>(value?: T): Unwrapped<T> => {
	if (isWrappedValue<T>(value)) {
		return value.value as Unwrapped<T>;
		// } else if (Array.isArray(value)) {
		// 	return (value as IValueWrapper<T>[]).map(unwrap) as T;
	} else {
		return value as Unwrapped<T>;
	}
};
