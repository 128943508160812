import { IListenable, Listenable } from './listenables';

// SUGGESTION:
// Rename "Dynamic" to "Port" (as something that may contain a static value or has values coming in and perhaps out)
// Rename "Wrapper" to "Signal"/"Cue" (as something that wraps a value and signals when it changes)
// Rename "Link" to "Beacon" (as something that sends a signal when it changes and allows setting a new value)

export interface IValueWrapper<T> extends IListenable<T> {
	get value(): T;

	toString(): string;

	getConvertedWrapper<TOut>(converter: (value: T) => TOut): IValueWrapper<TOut>;
}

export interface IValueLink<T> extends IValueWrapper<T> {
	set value(value: T);

	getConvertedLink<TOut>(convertOut: (value: T) => TOut, convertIn: (value: TOut) => T): IValueLink<TOut>;
}

export abstract class BaseValueWrapper<T> extends Listenable<T> implements IValueWrapper<T> {
	abstract get value(): T;
	abstract set value(value: T);

	abstract override toString(): string;

	abstract getConvertedWrapper<TOut>(converter: (value: T) => TOut): IValueWrapper<TOut>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isWrappedValue = <T>(arg: any): arg is BaseValueWrapper<T> => {
	return arg && arg instanceof BaseValueWrapper;
};

export class ValueWrapper<T> extends BaseValueWrapper<T> {
	constructor(
		protected _value: T,
		passSetter?: (setter: (value: T) => void) => void,
	) {
		super();
		if (passSetter) {
			passSetter(this._set.bind(this));
		}
	}

	get value(): T {
		return this._value;
	}

	protected set value(value: T) {
		this._set(value);
	}

	saveWrapper(saver: (wrapper: ValueWrapper<T>) => void): this {
		saver(this);
		return this;
	}

	override toString(): string {
		return this._value + '';
	}

	getConvertedWrapper<TOut>(converter: (value: T) => TOut): IValueWrapper<TOut> {
		return new ValueWrapConverter(this, converter);
	}

	private _set(value: T): void {
		this._value = value;
		this.notifyUpdate(value);
	}
}

export class ValueWrapConverter<TOut, TInner> extends ValueWrapper<TOut> {
	constructor(
		private innerWrapper: IValueWrapper<TInner>,
		private converter: (value: TInner) => TOut,
	) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		super(null as any);
	}

	override get value(): TOut {
		return this.converter(this.innerWrapper.value);
	}

	override listen(callback: (value: TOut) => void): () => void {
		return this.innerWrapper.listen(value => callback(this.converter(value)));
	}

	override toString(): string {
		return this.converter(this.innerWrapper.value) + '';
	}
}

export class ValueLink<T> extends ValueWrapper<T> implements IValueLink<T> {
	constructor(value: T, onUpdate?: (value: T) => void) {
		super(value);
		this.listen(onUpdate as (value: T) => void);
	}

	override get value(): T {
		return super.value;
	}

	override set value(value: T) {
		super.value = value;
	}

	getConvertedLink<TOut>(convertOut: (value: T) => TOut, convertIn: (value: TOut) => T): IValueLink<TOut> {
		return new ValueLinkConverter(this, convertOut, convertIn);
	}
}

export class ValueLinkConverter<TOut, TInner> extends ValueLink<TOut> {
	constructor(
		private innerWrapper: ValueLink<TInner>,
		private convertOut: (value: TInner) => TOut,
		private convertIn: (value: TOut) => TInner,
	) {
		super(convertOut(innerWrapper.value));
	}

	override get value(): TOut {
		return this.convertOut(this.innerWrapper.value);
	}

	override set value(value: TOut) {
		this.innerWrapper.value = this.convertIn(value);
	}

	override listen(callback: (value: TOut) => void): () => void {
		return this.innerWrapper.listen(value => callback(this.convertOut(value)));
	}

	override toString(): string {
		return this.convertOut(this.innerWrapper.value) + '';
	}
}

export class ThrottledValueWrapper<T> extends ValueWrapper<T> {
	private timerSet = false;

	override notifyUpdate(value: T): void {
		if (this.timerSet) return;
		this.timerSet = true;
		setTimeout(() => {
			this.timerSet = false;
			super.notifyUpdate(value);
		}, 0);
	}
}
