import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { fsdb, ManagedDocumentListenerByRef } from '../../core/shared/utilities/firebase/firestoreUtilities';
import { createStripeCheckoutSession as createStripeCreditsCheckoutSession } from 'src/app/core/shared/utilities/database/paymentUtilities';
import { walletCreditsDataDoc } from 'src/app/core/shared/utilities/database/walletUtilities';
import { organizationPaymentDoc } from 'src/app/core/shared/utilities/database/organizationUtilities';
import { userPaymentDoc } from 'src/app/core/shared/utilities/database/userUtilities';
import { UserService } from 'src/app/user/services/user.service';
import { DestroyableComponent } from 'src/app/core/shared/components/base/destroyable/destroyable.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {
	CreateCheckoutSessionResult,
	CreateCreditsCheckoutSessionBody,
	OrganizationPaymentData,
	WalletCreditData,
	WalletOwner,
	WalletOwnerType,
} from '../../../build-dependencies/shared';
import { FsDoc } from '../../core/shared/models/Utility';

@Injectable({
	providedIn: 'root',
})
export class PaymentService extends DestroyableComponent {
	public userWalletBalance: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
	private userWalletBalanceListener = new ManagedDocumentListenerByRef<FsDoc<WalletCreditData>>(
		(balance: FsDoc<WalletCreditData> | undefined) => {
			this.userWalletBalance.next(balance?.credits);
		},
		(error: Error) => {
			console.error('Error listening to wallet balance', error);
		},
	)
		.hookStop(this)
		.enableRetry();

	constructor(
		private userService: UserService,
		private gaService: GoogleAnalyticsService,
	) {
		super();

		this.userService.user.subscribe(user => {
			if (!user) {
				this.userWalletBalanceListener.pause();
				return;
			}

			this.listenForUserWalletBalance().then();
		});
	}

	public get userWalletBalanceLow(): boolean {
		const balance = this.userWalletBalance.value;
		if (balance === undefined) return false;
		return balance < 0.5;
	}

	async getWalletId(owner: WalletOwner) {
		switch (owner.ownerType) {
			case WalletOwnerType.ORG: {
				const organizationPaymentDataRef = organizationPaymentDoc(owner.ownerPath.id);
				const organizationPaymentData = await fsdb.get<FsDoc<OrganizationPaymentData>>(
					organizationPaymentDataRef.path,
				);
				if (!organizationPaymentData) throw new Error('No payment data found for org');
				return organizationPaymentData.walletPath.id;
			}

			case WalletOwnerType.USER: {
				const userPaymentDataRef = userPaymentDoc(owner.ownerPath.id);
				const userPaymentData = await fsdb.get<FsDoc<OrganizationPaymentData>>(userPaymentDataRef.path);
				if (!userPaymentData) throw new Error('No payment data found for user');
				return userPaymentData.walletPath.id;
			}

			default: {
				throw new Error('Invalid owner type');
			}
		}
	}

	createAndSendToCreditsCheckout(quantity: number, walletId: string, giftcard: boolean): Promise<void> {
		return new Promise<void>((_, reject) => {
			try {
				const body: CreateCreditsCheckoutSessionBody = {
					quantity: quantity,
					walletId: walletId,
					giftcard: giftcard,
				};
				createStripeCreditsCheckoutSession(body).then(async data => {
					const result = data.data as CreateCheckoutSessionResult;
					if (!result.success) throw new Error('Failed to create checkout session');
					this.gaService.event('wallet_credit_purchase', 'wallet', walletId, undefined, undefined, {
						quantity: quantity,
						current_balance: this.userWalletBalance.value,
					});
					window.location.href = result.checkoutUrl;
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	toCurrencyString(amount: number): string {
		return amount
			.toLocaleString('nl-NL', {
				style: 'currency',
				currency: 'EUR',
			})
			.replace(/\s/g, '');
	}

	private async listenForUserWalletBalance() {
		const userId = this.userService.user.value?.docRef?.id;
		if (!userId) return;

		// TODO user helper functions for this
		// (copy the backed onces when we merge the repos)
		const paymentDocRef = userPaymentDoc(userId);
		const paymentData = await fsdb.get<FsDoc<OrganizationPaymentData>>(paymentDocRef.path);
		if (!paymentData) throw new Error('No payment data found for user');

		const walletId = paymentData.walletPath.id;
		const walletCreditsDataRef = walletCreditsDataDoc(walletId);
		this.userWalletBalanceListener.listenTo(walletCreditsDataRef);
	}
}
