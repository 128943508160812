import { MetaData } from '../util/general';
import { ReplyOption, Template } from './templates';
import { Permission } from '../util/permission';
import { FormComponent } from './form-inputs';
import { HttpMethod } from '../util/http';
import { SharedRtdbObject } from '../util/internal';
import { JsonString, Nullable } from '../util/common';
import { AIFunctionDefinitions } from '../util/ai-functions';

export enum ChatType {
	TEMPLATE = 'prompt',
	TRANSCRIBE = 'transcribe',
	WEBHOOK_ONLY = 'webhook_only',
	INLINE = 'inline',
}

export enum ChatMessageRole {
	USER = 'user',
	ASSISTANT = 'assistant',
	SYSTEM = 'system',
}

export type WebhookDefinition = {
	url: string;
	method: HttpMethod;
	headers: Record<string, string>;
};

export type WebhookResponse = {
	body: string;
	status: number;
	headers: JsonString; // json
	timestamp: number;
};

export type WebhookExecution = {
	definition: WebhookDefinition;
	response?: WebhookResponse;
};

export type RequestedInput = {
	template: FormComponent[];
	templateString: string;
	webhook: WebhookDefinition;
	response?: string[];
	inputValues?: string[];
};

export type MessageAttachment = {
	fileId: string;
	fileName: string;
	fileType?: string;
};

export type ContentBlockType = 'text' | 'reasoning' | 'tool_call' | 'attachments' | 'tool_call_web_search';

export interface ContentBlockBase {
	id: number;
	type: ContentBlockType;
	finishedGenerating?: boolean;
	timestamp: number;
}

export interface ContentBlockText extends ContentBlockBase {
	type: 'text';
	content: string;
}

export interface ContentBlockReasoning extends ContentBlockBase {
	type: 'reasoning';
	thinkingTitle: string;
	resultTitle: string;
	hidden: boolean;
	content: string;
}

export interface ContentBlockToolCall extends ContentBlockBase {
	type: 'tool_call';
	toolId: keyof typeof AIFunctionDefinitions;
	callId: string;
	arguments: Record<string, string>;
	callingTitle?: string;
	calledTitle?: string;
	result?: string;
}

export interface ContentBlockWebSearch extends Omit<ContentBlockToolCall, 'type'> {
	type: 'tool_call_web_search';
	citations?: string[];
}

export interface ContentBlockAttachments extends ContentBlockBase {
	type: 'attachments';
	attachments: MessageAttachment[];
}

export type ContentBlock =
	| ContentBlockText
	| ContentBlockReasoning
	| ContentBlockToolCall
	| ContentBlockAttachments
	| ContentBlockWebSearch;

export type ChatMessage = {
	id: string;
	created: MetaData;
	role: ChatMessageRole;
	content: string;
	contentBlocks?: Record<string, ContentBlock>;
	promptId?: string;
	fileBucketRefs?: string[];
	fileDownloadUrls?: string[];
	status: 'waiting-to-be-generated' | 'done' | 'sending' | 'generating' | 'error';
	requestedInput?: RequestedInput;
	attachments?: MessageAttachment[];
};

export type ChatMessages = Record<string, ChatMessage>;

export type MessageLog = {
	systemMessage: string;
	resolvedTemplateMessage: string;
	fileBucketRefs?: string[];
	fileDownloadUrls?: string[];
	followingMessages: SharedRtdbObject<ChatMessages>;
	followingPrompts: Record<string, string>;
};

export type Chat = {
	id: string;
	name: string;
	type: ChatType;
	created: MetaData;
	templateId: string;
	organizationId: string;
	publicTokenId: string;
	userSessionId: string;
	searchId: string; // if not private: orgId_templateId, if private: orgId_templateId_userId
	templateCopy: Template; // a copy of the full template
	inputValues: string; // json of the input values from the form
	messages: MessageLog;
	rating?: number;
	permissions: Permission;
	replyOptions?: ReplyOption[];
	webhooks?: Record<string, WebhookExecution>;
	responseWebhookToken?: string;
	threadId?: string;
};

export type ChatInfo = {
	created: Chat['created'];
	name: Chat['name'];
	permissions: Chat['permissions'];
	userSessionId: Chat['userSessionId'];
	organizationId: Chat['organizationId'];
	publicTokenId: Chat['publicTokenId'];
	templateId: Chat['templateId'];
	rating: Chat['rating'];
};

export type ChatInfos = Record<string, ChatInfo>;

export type StartChatFromTemplateBody = {
	templateId: string;
	newChatId: string;
	inputValues: string[];
	fileBucketRefs?: string[];
	fileDownloadUrls?: string[];

	organizationId?: string;
	publicTokenId?: string;
	userSessionId?: string;

	newChatPermissions?: Permission;
};

export type SendMessageToChatBody = {
	chatId: string;
	message: string;
	replyOptionIndex: Nullable<number>;
	fileBucketRefs?: string[];
	fileDownloadUrls?: string[];
	organizationId?: string;
	publicTokenId?: string;
	userSessionId?: string;
};

export type StartChatWithApiFromTemplateBody = {
	templateId: string;
	inputValues: string[];
	organizationId: string;
	token: string;
	publicTokenId?: string;
	onCompleteWebhook?: WebhookDefinition;
};
