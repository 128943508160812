import { NavigationService } from 'src/app/core/shared/services/navigation/navigation.service';
import { RouteDataService } from '../../services/page/route-data.service';
import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/payment/services/payment.service';
import { OrganizationInvite5, OrganizationInviteId } from '../../models/database/organizationModels';
import { ManagedDocumentsListenerByQuery } from '../../utilities/firebase/firestoreUtilities';
import { CanStop } from '../../utilities/subscriptionUtilities';
import { acceptOrganizationInvite, declineOrganizationInvite, organizationInvitesCollectionGroup, whereAuthUserEmail, wherePending } from '../../utilities/database/organizationUtilities';
import { query } from 'firebase/firestore';
import { LayoutService } from '../../services/page/layout.service';

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['./navbar-top.component.scss']
})
export class NavbarTopComponent extends CanStop implements OnInit {
  protected invites: OrganizationInvite5[] | undefined;
  protected invitesListener: ManagedDocumentsListenerByQuery<OrganizationInvite5> = new ManagedDocumentsListenerByQuery(
		(invites: OrganizationInvite5[] | undefined) => { this.invites = invites; },
		(error: Error) => { throw(error); })
		.hookStop(this);
	protected inviteResponseLoading: boolean = false;

	protected console = console;

  constructor(
		protected rdService: RouteDataService,
		protected pService: PaymentService,
		protected layoutService: LayoutService,
		protected navigationService: NavigationService
	) {
			super();
	 }

	 ngOnInit(): void {
		setTimeout(() => {
			const invitesQuery = query(organizationInvitesCollectionGroup(), whereAuthUserEmail(), wherePending());
			this.invitesListener.listenTo(invitesQuery);
		}, 1000);
	 }

	 async acceptOrgInvitation(invite: OrganizationInvite5) {
			this.inviteResponseLoading = true;
		 	await acceptOrganizationInvite({ inviteId: invite.docRef!.id as OrganizationInviteId });
			this.inviteResponseLoading = false;
	 }

	 async declineOrgInvitation(invite: OrganizationInvite5) {
			this.inviteResponseLoading = true;
		 	await declineOrganizationInvite({ inviteId: invite.docRef!.id as OrganizationInviteId });
			 this.inviteResponseLoading = false;
	 }
}
