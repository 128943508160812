import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { evaluate, formGroupToDictionary } from '../../../models/Condition';
import EditorJS, { API } from '@editorjs/editorjs';
import { EditorJsService } from '../../../services/editor-js.service';
import { instant } from '../../../utilities/instant';
import { PageStateService } from '../../../services/page/page-state.service';
import { noop, Subscription } from 'rxjs';
import { FullscreenService } from '../../../services/fullscreen.service';
import { FormComponentType, FormInputComponent as FormInputComp } from '../../../../../../build-dependencies/shared';
import { sleep } from '../../../utilities/sleep';

@Component({
	selector: 'app-form-input',
	templateUrl: './form-input.component.html',
	styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnChanges, OnInit, OnDestroy {
	// this is a wierd way of communicating a change to all instances of this component
	static onFormInputValueChanged: EventEmitter<{ name: string; value: string | null }> = new EventEmitter<{
		name: string;
		value: string | null;
	}>();

	@Input() setup: FormInputComp | undefined;
	@Input() group: FormGroup | undefined;
	@Input() name: string | undefined;
	@Input() value?: string | null;
	@Input() disabled: boolean = false;

	@ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
	@ViewChild('editorJsHolder') editorJsHolder?: ElementRef<HTMLDivElement>;
	@ViewChild('editorJsFsContainer') editorJsFsContainer?: ElementRef<HTMLDivElement>;
	@ViewChild('editorJsFsParent') editorJsFsParent?: ElementRef<HTMLDivElement>;

	public isFullscreen = false;

	@Output() protected show: boolean = false;

	protected showSuggestions: boolean = false;
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected anySetup: any | undefined;
	protected hasValue: boolean = false;
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected controlValue: any | undefined;
	protected readonly noop = noop;

	private editorJS?: EditorJS;
	private subscription: Subscription;
	private pageStateSubscription: Subscription;
	private isSettingUpEditor = false;
	private editorJsOverlayId?: string;

	constructor(
		private formBuilder: FormBuilder,
		private editorJsService: EditorJsService,
		private pageStateService: PageStateService,
		private fullscreenService: FullscreenService,
		private renderer: Renderer2,
	) {
		this.subscription = FormInputComponent.onFormInputValueChanged.subscribe(() => {
			this.evaluateShow();
		});

		this.pageStateSubscription = this.pageStateService.pageState.subscribe(async state => {
			if (state.changed.templateIdParam || state.changed.chatIdParam) {
				this.clearInputValues();
				this.editorJS?.blocks.clear();
				await this.setupEditorJS();
			}
		});
	}

	clearInputValues() {
		this.group?.get(this.name!)?.setValue(null);
		this.controlValue = null;
		this.hasValue = false;
		this.showSuggestions = false;
		FormInputComponent.onFormInputValueChanged.emit({ name: this.name!, value: null });
	}

	ngOnDestroy(): void {
		if (this.editorJS && this.editorJS.destroy) this.editorJS.destroy();

		this.subscription.unsubscribe();
		this.pageStateSubscription.unsubscribe();
		this.group?.removeControl(this.name!);
	}

	ngOnInit(): void {
		this.group?.addControl(this.name!, this.formBuilder.control(null));
		this.group?.get(this.name!)?.setValue(this.value);
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		this.disabled ? this.group?.disable() : this.group?.enable();
		this.updateValues();
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes['setup']) {
			this.anySetup = this.setup;
		}
		if (changes['value']) {
			this.group?.get(this.name!)?.setValue(this.value);
			await this.setupEditorJS();
		}
		if (changes['disabled'] !== undefined) {
			if (changes['disabled'].currentValue) this.group?.disable();
			else this.group?.enable();
		}
		// if (this.setup?.type === 'radio' || this.setup?.type === 'dropdown') {
		// 	this.anySetup.type = 'pick';
		// 	this.anySetup.options = [{value: 'test', label: 'test'}, {value: '('navbar.user.noOrg'| localize)', label: '('navbar.user.noOrg'| localize)'}, {value: 'test3', label: 'test3'}];
		// }

		this.updateValues();
	}

	updateValues() {
		this.showSuggestions = !!this.value;
		this.controlValue = this.group!.get(this.name!)?.value ?? this.value;
		this.hasValue = !!this.controlValue;
		this.evaluateShow();

		// hack; cant be bothered to find what makes this not update the first time
		sleep(100).then(this.evaluateShow.bind(this));
	}

	evaluateShow() {
		this.show = true;
		if (this.group && this.setup?.condition) {
			this.show = evaluate(this.setup?.condition, formGroupToDictionary(this.group));
		}

		if (this.setup?.type === FormComponentType.Prompt || this.setup?.type === FormComponentType.Webhook) {
			this.group!.get(this.name!)?.setValue(this.show.toString());
		}
	}

	inputSuggestion(suggestion: string) {
		this.group!.get(this.name!)?.setValue(suggestion);
		this.hasValue = true;

		FormInputComponent.onFormInputValueChanged.emit({ name: this.name!, value: suggestion });
	}

	pick(value: string | null) {
		if (value === null) {
			this.group!.get(this.name!)?.setValue(null);
			this.hasValue = false;
			this.controlValue = null;
			FormInputComponent.onFormInputValueChanged.emit({ name: this.name!, value: null });
			return;
		}

		this.group!.get(this.name!)?.setValue(value);
		this.hasValue = true;
		this.controlValue = value;

		FormInputComponent.onFormInputValueChanged.emit({ name: this.name!, value: value });
	}

	onChanged(event: Event) {
		const target = event.target as HTMLInputElement;

		if (target.type === 'checkbox') {
			this.group!.get(this.name!)?.setValue(target.checked);

			FormInputComponent.onFormInputValueChanged.emit({
				name: this.name!,
				value: target.checked ? '1' : '0',
			});

			return;
		}

		FormInputComponent.onFormInputValueChanged.emit({
			name: this.name!,
			value: target.value,
		});
	}

	onInput(event: Event) {
		FormInputComponent.onFormInputValueChanged.emit({
			name: this.name!,
			value: (event.target as HTMLInputElement | HTMLTextAreaElement).value,
		});
	}

	focus() {
		if (this.inputElement) {
			this.inputElement.nativeElement.focus();
		}
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key === 'Escape' && this.isFullscreen) {
			this.toggleFullscreen();
		}
	}

	setupEditorJS() {
		// This function gets called when the holder component is initialized.
		// The element is not yet available so we need to wait for the next tick.
		return instant(async () => {
			// If we are already setting up the editor, we need to stop immediately
			if (this.isSettingUpEditor) return;

			if (!this.setup?.type || this.setup?.type != FormComponentType.RichText) {
				return;
			}

			if (!this.editorJsHolder) {
				console.warn('EditorJSHolder is not defined, skipping setup');
				return;
			}

			this.isSettingUpEditor = true;

			const blocks = this.editorJsService.toEditorJS(String(this.value ?? ''));

			if (!this.editorJS) {
				this.editorJS = new EditorJS({
					holder: this.editorJsHolder.nativeElement,
					tools: EditorJsService.tools,
					data: {
						time: Date.now(),
						blocks: blocks,
					},
					readOnly: this.disabled,
					minHeight: 16,
					onChange: async (api: API) => {
						const savedBlocks = api.readOnly.isEnabled ? blocks : (await api.saver.save()).blocks;
						this.value = this.editorJsService.toMarkdown(savedBlocks);
						this.group!.get(this.name!)?.setValue(this.value);
					},
				});
			}

			if (this.editorJS.blocks) {
				await this.editorJS.blocks.clear();
				// not sure if this is needed or not
				await this.editorJS.readOnly.toggle(false);
				this.editorJS.blocks.insertMany(blocks);
				await this.editorJS.readOnly.toggle(this.disabled);
			}

			this.isSettingUpEditor = false;
		});
	}

	toggleFullscreen() {
		if (!this.editorJsFsContainer || !this.editorJsFsParent) {
			throw new Error('editorJsFsContainer or editorJsFsParent is not defined');
		}

		this.isFullscreen = !this.isFullscreen;

		if (this.isFullscreen) {
			this.editorJsOverlayId = this.fullscreenService.addOverlayElement(
				this.editorJsFsContainer,
				this.editorJsFsParent,
				this.renderer,
			);

			this.editorJS?.focus(true);
		} else {
			this.fullscreenService.hideOverlay(this.editorJsOverlayId!);
		}
	}
}
