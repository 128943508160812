import { Component, Input } from '@angular/core';
import { AccordionLayoutGroup } from 'src/app/core/shared/models/layout/layoutGroups';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';

@Component({
  selector: 'app-accordion-layout-group',
  template: `<div class="accordion" [id]="uniqueId">
	@for(item of items; track $index) {
		<div class="accordion-item">

			<app-accordion-layout-group-item-content
				[elements]="item.elements"
				[label]="item.label"
				[currentAccordion]="currentAccordion"
				[uniqueId]="uniqueId"
				[index]="$index"
			></app-accordion-layout-group-item-content>

		</div>
	}
</div>`,
	styles: [`
		:host {
			display: contents;
		}
	`]
})
export class AccordionLayoutGroupComponent extends BlockLayoutElementComponent {

	@Input() items: AccordionLayoutGroup['items'] = [];

	protected currentAccordion: number = -1;
	protected uniqueId: string = '';

	constructor() {
		super();
		this.uniqueId = Math.random().toString(36).substring(2, 10);
	}

	override setupAllValues(): void {
		// no values to setup
	}

	override setupValueForKey(key: keyof this): void {
		// no values to setup
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to setup
	}
}
