import { HostConfigService } from '../../../directives/host-config/host-config.service';
import { PageStateBasedComponent } from 'src/app/core/shared/components/base/page-state-based/page-state-based.component';
import { Component, Input } from '@angular/core';
import { ConfigUIComponentTypes } from '../../../models/config/host-config/hostConfigModels';

@Component({
	selector: 'app-page-state-guard',
	templateUrl: './page-state-guard.component.html',
	styleUrls: ['./page-state-guard.component.scss'],
})
export class PageStateGuardComponent {
	@Input({ required: true }) public forComponent!: PageStateBasedComponent;

	protected readonly ConfigUIComponentTypes = ConfigUIComponentTypes;

	constructor(protected hostConfigService: HostConfigService) {}
}
