import { Component, Input } from '@angular/core';
import { SpacerLayoutElement } from '../../../models/layout/utilityElements';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';

@Component({
  selector: 'app-spacer-layout-element',
  template: ``,
  styles: []
})
export class SpacerLayoutElementComponent extends BlockLayoutElementComponent {

	@Input() height: SpacerLayoutElement['height'] = '1rem';

	override ngOnInit(): void {
		super.ngOnInit();
		this.setClass('m-0', true, 'mb-3');
		this.setClass('p-0', true);
	}

	protected override setupAllValues(): void {
		this.setupValueForKey('height');
	}

	override setupValueForKey(key: keyof this): void {
		if (key === 'height') {
			this.setStyle('height', unwrap(this.height));
		}
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// Do nothing
	}
}
