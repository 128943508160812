import { FormComponent, FormComponentType, FormInputComponent } from '../../../../build-dependencies/shared';

export type FormTextInputComponent = FormInputComponent & {
	type: FormComponentType.Text | FormComponentType.Textfield | FormComponentType.Textarea;
	suggestions?: string[]; // if the input is a textfield or textarea the suggestions are defined here
	placeholder?: string; // The placeholder of the form input shown to the user
};

export type FormCheckboxComponent = FormInputComponent & {
	type: FormComponentType.Checkbox | FormComponentType.Toggle;
	true?: string; // this is the value when it is checked
	false?: string; // this is the value when it is unchecked
};

export type FormOptionsComponent = FormInputComponent & {
	type: FormComponentType.Dropdown | FormComponentType.Radio | FormComponentType.Pick;
	options: { value: string; label: string }[]; // if the input is a dropdown or radio, the options are defined here
};

export function canFormComponentTypeHaveValue(type: FormComponentType): boolean {
	return !(
		type === FormComponentType.If ||
		type === FormComponentType.EndIf ||
		type === FormComponentType.Button ||
		type === FormComponentType.Text
	);
}

export function isFormInputComponent(component: FormComponent): component is FormInputComponent {
	return canFormComponentTypeHaveValue(component.type);
}
