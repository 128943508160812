import { Component, Input } from '@angular/core';
import { SelectInputElement } from '../../../models/layout/inputElements';
import { BaseInputElementComponent } from './base-input-element.component';

@Component({
	selector: 'app-select-input-element',
	template: `
		<app-normal-input-element-container [setup]="setup" [for]="uniqueId">
			<select
				class="form-control form-select w-100"
				(change)="onValueChange($event)"
				[disabled]="disabled | dynamic"
			>
				<option
					*ngFor="let option of options | dynamic"
					[disabled]="option.disabled | dynamic"
					[selected]="(value | dynamic) == (option.value | dynamic)"
					[value]="option.value | dynamic"
				>
					{{ option.label }}
				</option>
			</select>
		</app-normal-input-element-container>
	`,
	styleUrls: ['./input-elements.styles.scss'],
})
export class SelectInputElementComponent extends BaseInputElementComponent<string> {
	@Input() options!: SelectInputElement['options'];

	protected override onValueChangeEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
	protected override onInputEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
}
