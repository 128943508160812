<ng-container>
	<ng-container *ngIf="!forComponent?.componentState?.loading?.isLoading; else loading">
		<ng-container *ngIf="forComponent?.isComponentValid; else error">
			<ng-content></ng-content>
		</ng-container>
	</ng-container>
	<ng-template #loading>
		<div class="loading">
			<span class="text-muted" localize="general.loading"></span>
			<span aria-hidden="true" class="spinner-border spinner-border-sm ms-1" role="status"></span>
		</div>
	</ng-template>
	<ng-template #error>
		<div class="d-flex flex-column align-items-center h-100">
			<div class="text-center my-auto">
				<div *ngIf="forComponent?.componentState?.invalids?.authUser == true" class="error">
					<p localize="guard.error.notLoggedIn.description"></p>
					<button
						class="btn btn-primary"
						localize="guard.error.notLoggedIn.fixButtonText"
						navigationLink="@login"
					></button>
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.user == true" class="error">
					<p localize="guard.error.noValidUserDoc.description"></p>
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.activeMembership == true" class="error">
					<p localize="guard.error.noMembership.description"></p>
					@if (hostConfigService.get('ui.pageStateGuard.useComponent') == ConfigUIComponentTypes.AISHA) {
						<button
							localize="hostComponent.aisha.vmbo"
							class="btn btn-primary mx-1"
							onclick="window.location.href='https://www.slagenmetaisha.nl/vmbo';"
						></button>
						<button
							localize="hostComponent.aisha.havo"
							class="btn btn-primary mx-1"
							onclick="window.location.href='https://www.slagenmetaisha.nl/havo';"
						></button>
						<button
							localize="hostComponent.aisha.vwo"
							class="btn btn-primary mx-1"
							onclick="window.location.href='https://www.slagenmetaisha.nl/vwo';"
						></button>
					} @else {
						<button
							id="createOrganizationButton"
							localize="guard.error.noMembership.fixButtonText"
							class="btn btn-primary"
							*ifHostConfig="'ui.page.organizations.isCreateEnabled'"
							navigationLink="@organization/create"
							navigateTemporary
						></button>
					}
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.chatIdParam == true" class="error">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.userIdParam == true" class="error">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.organizationIdParam == true" class="error">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.templateIdParam == true" class="error">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.tokenIdParam == true" class="error">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.variableIdParam == true" class="error">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div *ngIf="forComponent?.componentState?.invalids?.enoughCredits == true" class="error">
					<p localize="guard.error.creditsTooLow.description"></p>
					<button
						class="btn btn-primary"
						localize="guard.error.creditsTooLow.fixButtonText"
						navigationLink="@organization/billing"
					></button>
				</div>
			</div>
		</div>
	</ng-template>
</ng-container>
