<ng-container>
  <ng-container *ngIf="!forComponent?.componentState?.loading?.isLoading; else loading">
    <ng-container *ngIf="forComponent?.isComponentValid; else error">
      <ng-content></ng-content>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div class="loading">
      <span class="text-muted" localize="general.loading"></span>
      <span class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
    </div>
  </ng-template>
  <ng-template #error>
		<div class="d-flex flex-column align-items-center h-100">
			<div class="text-center my-auto">
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.authUser == false">
    		  <p localize="guard.error.notLoggedIn.description"></p>
					<button  localize="guard.error.notLoggedIn.fixButtonText" class="btn btn-primary" navigationLink="@login"></button>
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.user == false">
					<p localize="guard.error.noValidUserDoc.description"></p>
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.activeMembership == false">
					<p localize="guard.error.noMembership.description"></p>
					@if (hostConfigService.get('ui.pageStateGuard.useComponent') == ConfigUIComponentTypes.AISHA) {
						<button localize="hostComponent.aisha.vmbo" class="btn btn-primary mx-1" onclick="window.location.href='https://www.slagenmetaisha.nl/vmbo';"></button>
						<button localize="hostComponent.aisha.havo" class="btn btn-primary mx-1" onclick="window.location.href='https://www.slagenmetaisha.nl/havo';"></button>
						<button localize="hostComponent.aisha.vwo"  class="btn btn-primary mx-1" onclick="window.location.href='https://www.slagenmetaisha.nl/vwo';"></button>
					} @else {
						<button id="createOrganizationButton" localize="guard.error.noMembership.fixButtonText" class="btn btn-primary" *ifHostConfig="'ui.page.organizations.isCreateEnabled'" navigationLink="@organization/create"></button>
					}
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.chatIdParam == false">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.userIdParam == false">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.organizationIdParam == false">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.templateIdParam == false">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.tokenIdParam == false">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.variableIdParam == false">
					<p localize="guard.error.missingParams.description"></p>
				</div>
				<div class="error" *ngIf="forComponent?.componentState?.invalids?.enoughCredits == false">
					<p localize="guard.error.creditsTooLow.description"></p>
					<button  localize="guard.error.creditsTooLow.fixButtonText" class="btn btn-primary" navigationLink="@organization/billing"></button>
				</div>
			</div>
		</div>
  </ng-template>
</ng-container>
