import { DocumentReference, collection, collectionGroup, doc, where } from 'firebase/firestore';
import { fbAuth, fbFirestore as fs } from 'src/app/firebase-init';
import { idPrefixes } from '../../models/database/generalModels';

export const usersCollection = () => collection(fs, `users`);
export const userDoc = (userId: string) => doc(fs, `users`, userId);
export const userDocFromCurrentAuthUser = () => doc(fs, `users`, idPrefixes['users'] + fbAuth.currentUser?.uid);
export const userDocFromAuthId = (userAuthId: string) => doc(fs, `users`, idPrefixes['users'] + userAuthId);
export const userIdFromAuthId = (userAuthId: string) => idPrefixes['users'] + userAuthId;

export const userEmailDoc = (userId: string, email: string) => doc(fs, `users/${userId}/userEmails`, email);
export const userEmailsCollectionGroup = () => collectionGroup(fs, 'userEmails');

export const userOrganizationCollection = (userId: string) => collection(fs, `users/${userId}/userOrganizations`);
export const userOrganizationDoc = (userId: string, organization: string) =>
	doc(fs, `users/${userId}/userOrganizations`, organization);
export const userOrganizationsCollectionGroup = () => collectionGroup(fs, 'userOrganizations');

export const userPaymentDoc = (userId: string) => doc(fs, `users/${userId}/userData/userPayment`);

export const whereUserRef = (userRef: DocumentReference) => where('userRef', '==', userRef);
export const whereUserRefFromId = (userId: string) => whereUserRef(userDoc(userId));
