import { collection, collectionGroup, doc, DocumentReference, where } from 'firebase/firestore';
import { fbAuth, fbFirestore as fs, fbFunctions as cf } from 'src/app/firebase-init';
import { httpsCallable } from '@firebase/functions';
import {
	AcceptOrganizationInviteBody5,
	AddPackToOrganizationRequest,
	CreateOrganizationBody,
	CreateOrganizationInviteBody,
	CreatePackRequest,
	DeclineOrganizationInviteBody5,
	OrganizationFunctionResult,
	OrganizationInviteFunctionResult5,
	UpdatePackRequest,
} from '../../../../../build-dependencies/shared';

// functions
export const createOrganization = (data: CreateOrganizationBody) => {
	const callable = httpsCallable<CreateOrganizationBody, OrganizationFunctionResult>(cf, 'createOrganization');
	return callable(data);
};
export const createOrganizationInvite = (data: CreateOrganizationInviteBody) => {
	const callable = httpsCallable<CreateOrganizationInviteBody, OrganizationInviteFunctionResult5>(
		cf,
		'createOrganizationInvite',
	);
	return callable(data);
};
export const acceptOrganizationInvite = (data: AcceptOrganizationInviteBody5) => {
	const callable = httpsCallable<AcceptOrganizationInviteBody5, OrganizationInviteFunctionResult5>(
		cf,
		'acceptOrganizationInvite',
	);
	return callable(data);
};
export const declineOrganizationInvite = (data: DeclineOrganizationInviteBody5) => {
	const callable = httpsCallable<DeclineOrganizationInviteBody5, OrganizationInviteFunctionResult5>(
		cf,
		'declineOrganizationInvite',
	);
	return callable(data);
};

export const addPackToOrganization = (data: AddPackToOrganizationRequest) => {
	const callable = httpsCallable(cf, 'addPackToOrganization');
	return callable(data);
};

export const removePackFromOrganization = (data: AddPackToOrganizationRequest) => {
	const callable = httpsCallable(cf, 'removePackFromOrganization');
	return callable(data);
};

export const updatePack = (data: UpdatePackRequest) => {
	const callable = httpsCallable(cf, 'updatePack');
	return callable(data);
};

export const createPack = (data: CreatePackRequest) => {
	const callable = httpsCallable<CreatePackRequest, { packId: string }>(cf, 'createPack');
	return callable(data);
};

export const deletePack = (data: { packId: string }) => {
	const callable = httpsCallable(cf, 'deletePack');
	return callable(data);
};

// docs and collections
export const organizationsCollection = () => collection(fs, `organizations`);
export const organizationDoc = (organizationId: string) => doc(fs, `organizations`, organizationId);

export const organizationMembersCollection = (organizationId: string) =>
	collection(fs, `organizations/${organizationId}/organizationMembers`);
export const organizationMemberDoc = (organizationId: string, userId: string) =>
	doc(fs, `organizations/${organizationId}/organizationMembers`, userId);
export const organizationMembersCollectionGroup = () => collectionGroup(fs, 'organizationMembers');

export const organizationInvitesCollection = (organizationId: string) =>
	collection(fs, `organizations/${organizationId}/organizationInvites`);
export const organizationInviteDoc = (organizationId: string, inviteId: string) =>
	doc(fs, `organizations/${organizationId}/organizationInvites`, inviteId);
export const organizationInvitesCollectionGroup = () => collectionGroup(fs, 'organizationInvites');

export const organizationDataCollection = (organizationId: string) =>
	collection(fs, `organizations/${organizationId}/organizationData`);
export const organizationPaymentDoc = (organizationId: string) =>
	doc(fs, `organizations/${organizationId}/organizationData/orgPayment`);

export const StripeProductsCollection = () => collection(fs, `stripeProducts`);

export const whereEmail = (email: string) => where('email', '==', email);
export const whereAuthUserEmail = () => where('email', '==', fbAuth.currentUser?.email ?? '');
export const wherePending = () => where('state', '==', 'pending');

export const whereOrganizationRef = (organizationRef: DocumentReference) =>
	where('organizationRef', '==', organizationRef);
export const whereOrganizationRefFromId = (organizationId: string) =>
	whereOrganizationRef(organizationDoc(organizationId));

export const packsCollection = () => collection(fs, `packs`);
export const getPack = (packId: string) => doc(fs, `packs`, packId);
