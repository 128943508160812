export const userCollectionsToIdPrefixMapping: { [key: string]: string } = {
	users: 'user_',
	checkoutSessions: 'checkSes_',
	// "userEmails": "", // the email is the id
};

export const tokenCollectionsToIdPrefixMapping: { [key: string]: string } = {
	organizationPublicTokens: 'orgPubTok_',
};

export const organzationCollectionsToIdPrefixMapping: { [key: string]: string } = {
	organizations: 'org_',
	organizationInvites: 'orgInvite_',
	organizationMembers: 'orgMember_',
	'organizationData/contact': 'orgContact_',
	//"organizationData/billing": "orgBilling_",
};

export const inviteLinkIdPrefix = 'inviteLink_';

export const inviteCollectionsToIdPrefixMapping: { [key: string]: string } = {
	inviteLinks: inviteLinkIdPrefix,
};

export const walletCollectionsToIdPrefixMapping: { [key: string]: string } = {
	wallets: 'wallet_',
	'walletData/walletCredits/walletCreditHistory': 'credHist_',
};

export const paymentPlanCollectionsToIdPrefixMapping: {
	[key: string]: string;
} = {
	paymentPlans: 'plan_',
	products: 'prod_',
};

export const giftcardCollectionsToIdPrefixMapping: { [key: string]: string } = {
	giftcards: 'giftcard_',
};

export const idPrefixes: { [key: string]: string } = {
	...userCollectionsToIdPrefixMapping,
	...organzationCollectionsToIdPrefixMapping,
	...tokenCollectionsToIdPrefixMapping,
	...inviteCollectionsToIdPrefixMapping,
	...walletCollectionsToIdPrefixMapping,
	...paymentPlanCollectionsToIdPrefixMapping,
	...giftcardCollectionsToIdPrefixMapping,
};
