import { Component, Input } from '@angular/core';
import { HorizontalFlexLayoutGroup } from 'src/app/core/shared/models/layout/layoutGroups';
import { FlexRowLayoutElementComponent } from '../base/flex-row-layout-element.component';

@Component({
	selector: 'app-horizontal-flex-layout-group',
	template: `@for (section of sections; track $index) {
		<div
			[style.width]="
				section.width === 'fit' ? 'fit-content' : section.width === 'expand' ? 'auto' : section.width
			"
			[class.d-flex]="section.width !== 'fit' && section.width !== 'expand'"
			[class.flex-shrink-0]="section.width === 'fit'"
			[class.flex-grow-1]="section.width === 'expand'"
		>
			<app-layout [elements]="section.elements"></app-layout>
		</div>
	} `,
	styles: [],
})
export class HorizontalFlexLayoutGroupComponent extends FlexRowLayoutElementComponent {
	@Input() sections: HorizontalFlexLayoutGroup['sections'] = [];

	override ngOnInit(): void {
		super.ngOnInit();
		this.setClass('gap-2', true);
	}

	override setupAllValues(): void {
		// no values to setup
	}

	override setupValueForKey(key: keyof this): void {
		// no keys to setup
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to setup
	}
}
