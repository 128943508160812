import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toid',
	standalone: true,
})
export class ToidPipe implements PipeTransform {
	transform(value: string): string {
		return (
			value
				.toLowerCase()
				.trim()
				// Replace spaces and special characters with hyphens
				.replace(/[^a-z0-9]/g, '-')
				// Remove multiple consecutive hyphens
				.replace(/-+/g, '-')
				// Remove leading and trailing hyphens
				.replace(/^-+|-+$/g, '')
				// Ensure it starts with a letter (HTML ID requirement)
				.replace(/^([0-9])/, 'id-$1')
		);
	}
}
