import { Observable, throttleTime } from 'rxjs';

export type Unsubscribe = () => void;

export interface IListenable<T> {
	listen(callback: (value: T) => void): () => void;

	clearListeners(): void;

	asObservable(): Observable<T>;
}

export abstract class Listenable<T> implements IListenable<T> {
	private lastId = -1;
	private listeners: Map<number, (value: T) => void> = new Map();
	private _notificationsEnabled = true;

	/**
	 * Add on change callback
	 * @param listener - callback to be called when value changes
	 * @returns - function to remove the callback
	 */
	public listen(listener: (value: T) => void): Unsubscribe {
		const id = ++this.lastId;
		this.listeners.set(id, listener);
		return () => {
			this.listeners.delete(id);
		};
	}

	public clearListeners(): void {
		this.listeners.clear();
	}

	public asObservable(): Observable<T> {
		return new Observable(observer => {
			return this.listen(v => {
				observer.next(v);
			});
		});
	}

	public asThrottledObservable(duration: number = 1000): Observable<T> {
		return this.asObservable().pipe(
			throttleTime(duration, undefined, {
				leading: true,
				trailing: true,
			}),
		);
	}

	protected notifyUpdate(value: T): void {
		if (!this._notificationsEnabled) return;

		this.listeners.forEach(listener => {
			try {
				listener?.(value);
			} catch (e) {
				console.error('Error notifying listener', e);
			}
		});
	}

	protected withoutUpdateNotifications(callback: () => void): void {
		this._notificationsEnabled = false;
		callback();
		this._notificationsEnabled = true;
	}
}
