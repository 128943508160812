<nav *ngIf="rdService.RouteData.value.navbarShow" class="navbar navbar-light bg-light justify-content-start align-items-center gap-3 border-bottom shadow-sm flex-nowrap text-truncate">
	<!-- Sidebar toggle button -->
	<button
		#navbarSideToggler
		id="navbarSideToggler"
		class="btn btn-light d-block d-xl-none"
		type="button"
		data-bs-toggle="offcanvas"
		data-bs-target="#navbar-side-offcanvas"
		aria-expanded="false"
		aria-controls="navbar-side-offcanvas"
	>
		<i class="fa-solid fa-bars"></i>
	</button>

	@if(layoutService.content.top.length > 0) {
		<!-- Injected content -->
		<ng-container *ngFor="let content of layoutService.content.top">
			<ng-container *ngTemplateOutlet="content.template"></ng-container>
		</ng-container>
	} @else {
		<!-- Base content -->
		<!-- Nothing -->
	}

</nav>

<!-- Low credit warning -->
<nav class="navbar navbar-light bg-warning" *ngIf="pService.userWalletBalanceLow">
	<div class="container justify-content-center">
		<div class="d-flex align-items-center gap-3">
			{{ 'component.navbar.lowBalance'| localize }}
			<button class="btn btn-primary" [localize]="'component.navbar.lowBalanceButtonText'" navigationLink="@user/billing">
			</button>
		</div>
	</div>
</nav>

<!-- Invite notification -->
<nav class="navbar navbar-light bg-info" *ngIf="invites && invites.length > 0">
	<div class="container justify-content-center">
		<div class="d-flex align-items-center gap-3">
			{{ 'component.navbar.orgInvite'| localize }} {{ invites[0].organizationName }}
			<button class="btn btn-primary" (click)="acceptOrgInvitation(invites[0])" [disabled]="inviteResponseLoading" localize="page.organizations.invitations.acceptButton"></button>
			<button class="btn btn-danger" (click)="declineOrgInvitation(invites[0])" [disabled]="inviteResponseLoading" localize="page.organizations.invitations.declinceButton"></button>
		</div>
	</div>
</nav>
