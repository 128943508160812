import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { HorizontalSplitLayoutGroup } from 'src/app/core/shared/models/layout/layoutGroups';
import { FlexRowLayoutElementComponent } from '../base/flex-row-layout-element.component';

@Component({
  selector: 'app-horizontal-split-layout-group',
  template: `
		<div class="d-flex flex-row gap-2 align-items-baseline justify-self-start flex-grow-1 flex-nowrap">
			<ng-template #leftContainer></ng-template>
		</div>
		<div class="d-flex flex-row gap-2 align-items-baseline justify-self-end flex-grow-0 flex-nowrap">
			<ng-template #rightContainer></ng-template>
		</div>`,
	styles: [`
		:host {
			justify-content: stretch;
		}
		:host>div {
			align-self: center;
		}`]
})
export class HorizontalSplitLayoutGroupComponent extends FlexRowLayoutElementComponent {

	@ViewChild('leftContainer', { read: ViewContainerRef, static: true }) leftContainer!: ViewContainerRef;
	@ViewChild('rightContainer', { read: ViewContainerRef, static: true }) rightContainer!: ViewContainerRef;

	@Input() left: HorizontalSplitLayoutGroup['left'] = [];
	@Input() right: HorizontalSplitLayoutGroup['right'] = [];

	override ngOnInit(): void {
		super.ngOnInit();
		this.setClass('gap-2');
		this.setClass('py-1');
	}

	protected override setupAllValues(): void {
		this.setupValueForKey('left');
		this.setupValueForKey('right');
	}

	override setupValueForKey(key: keyof this): void {
		if (key === 'left') {
			this.draw(this.leftContainer, this.left);
		}
		if (key === 'right') {
			this.draw(this.rightContainer, this.right);
		}
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// Do nothing
	}

}
