import { deepClone } from '../../utilities/objectUtilities';
import { Injectable } from '@angular/core';
import { ActivatedRoute, GuardsCheckStart, NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ContextService } from '../page/context.service';
import { Listenable } from '../../utilities/data/dynamic/listenables';
import { Location } from '@angular/common';

export type RouteOption =
	| '@login'
	| '@signup'
	| '@mvp1'
	| '@mvp1/template'
	| '@mvp1/template/chat'
	| '@templates'
	| '@templates/create'
	| '@templates/edit'
	| '@organization/details'
	| '@organization/billing'
	| '@organization/members'
	| '@organizations'
	| '@organization/create'
	| '@user/details'
	| '@user/billing';

const allRouteOptions: Record<RouteOption, true> = {
	'@login': true,
	'@signup': true,
	'@mvp1': true,
	'@mvp1/template': true,
	'@mvp1/template/chat': true,
	'@templates': true,
	'@templates/create': true,
	'@templates/edit': true,
	'@organization/details': true,
	'@organization/billing': true,
	'@organization/members': true,
	'@organizations': true,
	'@organization/create': true,
	'@user/details': true,
	'@user/billing': true,
}

export type RouteParams = {
	organizationId?: string;
	templateId?: string;
	chatId?: string;
};

export type RouteQueries = {
	returnUrl?: string;
};

export type RouteFragment = string;

export type RouteSettings = {
	preserveFragment?: boolean; // default is false
	preserveQueries?: boolean | { [key in keyof RouteQueries]: boolean }; // default is true
	extras?: NavigationExtras;
}

export type NavigateSetup = {
	toRoute: RouteOption;
	fragment?: RouteFragment;
	params?: RouteParams;
	queries?: RouteQueries;
	settings?: RouteSettings;
};

export type Gen8Param = keyof Gen8Params;
export class Gen8Params {
	organizationId?: string = undefined;
	userId?: string = undefined;
	variableId?: string = undefined;
	templateId?: string = undefined;
	tokenId?: string = undefined;
	chatId?: string = undefined;
}
export const EmptyGen8Params = new Gen8Params();

export type ResolvedRoute = {
	commands: any[],
	extras: NavigationExtras
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService extends Listenable<void> {

	returnUrl: string | undefined;
	public currentOption: BehaviorSubject<RouteOption | undefined> = new BehaviorSubject<RouteOption | undefined>(undefined);
	public currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public currentState: BehaviorSubject<Record<string, any>> = new BehaviorSubject<Record<string, any>>({});
  public currentParams: BehaviorSubject<Gen8Params> = new BehaviorSubject<Gen8Params>(new Gen8Params());
	public currentQueries: BehaviorSubject<RouteQueries> = new BehaviorSubject<RouteQueries>({});

	private static readonly UPDATING_STATE_KEY = 'updatingState';
	private static readonly UPDATING_STATE_VALUE = 'true';

	constructor(
		public readonly router: Router,
		private activatedRoute: ActivatedRoute,
		private contextService: ContextService,
		private location: Location) {
			super();

			this.activatedRoute.queryParams.subscribe((params: RouteQueries) => {
				this.returnUrl = params?.returnUrl ?? undefined;
			});

			location.subscribe((val) => {
				// if the url is exactly the same, then the angular router will not trigger a NavigationEnd event
				// so the new state will not be set
				if (val.url === this.currentUrl.value) {
					this.currentState.next(val.state as Record<string, any>);
					this.notifyUpdate();
				}
			});

			router.events.subscribe((val) => {

				// if guard event, remember url
				if (val instanceof GuardsCheckStart) {
					this.currentUrl.next((val as GuardsCheckStart).url);
					this._determineCurrentRouteOption();
					this.notifyUpdate();
				}

				// if end event, remember params
				if (val instanceof NavigationEnd) {
					this.currentState.next(this.location.getState() as Record<string, any>);
					this.currentQueries.next(this.activatedRoute.snapshot.queryParams);

					if (val.url !== this.currentUrl.value) {
						this.currentUrl.next(val.url);
						this._determineCurrentRouteOption();
					}

					// if we are currently updating state, handle the next step
					if ((this.currentQueries.value as any)[NavigationService.UPDATING_STATE_KEY] === NavigationService.UPDATING_STATE_VALUE) {
						this._handleReplaceState();
						return;
					}

					// aggregate params, because they are layered in the route snapshots
					const aggregatedParams: Gen8Params = new Gen8Params();
					let route = activatedRoute.snapshot;
					while (route) {
						Object.assign(aggregatedParams, route.params);
						route = route.children[0];
					}

					// update params
					this.currentParams.next(aggregatedParams);
					this.notifyUpdate();
				}
			});

			// if context changes, notify listeners so that they may update their routes
			contextService.context.subscribe(context => {
				this._determineCurrentRouteOption();
				this.notifyUpdate();
			});
		}

	/**
	 * Changes the current state of the page without changing the URL.
	 * @param applyValues
	 */
	replaceState(key: string, value: unknown): void
	replaceState(applyValues: Record<string, unknown>): void
	replaceState(applyValues: Record<string, unknown> | string, value?: unknown): void {
		const state = this.router.getCurrentNavigation()?.extras.state ?? {};
		if (typeof applyValues === 'string') {
			state[applyValues] = value;
		} else {
			Object.assign(state, applyValues);
		}
		this.location.replaceState(this.location.path(), '', state);
		this.currentState.next(this.location.getState() as Record<string, unknown>);
		this.notifyUpdate();
	}

	navigateToState(key: string, value: unknown): void
	navigateToState(applyValues: Record<string, unknown>): void
	navigateToState(applyValues: Record<string, unknown> | string, value?: unknown): void {
		const state = this.router.getCurrentNavigation()?.extras.state ?? {};
		if (typeof applyValues === 'string') {
			state[applyValues] = value;
		} else {
			Object.assign(state, applyValues);
		}
		this.router.navigate([], { state: state, queryParamsHandling: 'merge', queryParams: { [NavigationService.UPDATING_STATE_KEY]: NavigationService.UPDATING_STATE_VALUE }, preserveFragment: true });
	}

	private _handleReplaceState(): void {
		this.router.navigate([], { state: this.currentState.value, queryParamsHandling: 'merge', queryParams: { [NavigationService.UPDATING_STATE_KEY]: undefined }, preserveFragment: true, replaceUrl: true });
	}


	/**
	 * Navigates to the return URL. If no return URL is set, navigates to the default page
	 * @returns Promise that resolves to true if the navigation was successful
	 */
	returnFromTemporary(): Promise<boolean> {
		if (!this.returnUrl) {
			throw new Error('No return URL set for return navigation. Did you use `navigateTemporarily()`?');
		}
		// const { returnTo, returnStack } = this._popReturnStack();
		return this.router.navigate([this.returnUrl ?? '/produce'], { replaceUrl: true });
	}

	replaceTemporarily(setup: NavigateSetup): Promise<boolean>
	replaceTemporarily(route: RouteOption, params?: RouteParams, settings?: RouteSettings): Promise<boolean>
	replaceTemporarily(route: RouteOption | NavigateSetup, params?: RouteParams, settings?: RouteSettings): Promise<boolean> {
		const { commands, extras } = typeof route === 'string'
			? this.toRoute(route, params, settings)
			: this.toRoute(route);
		extras.replaceUrl = true;
		extras.queryParams = { returnUrl: this.currentUrl.value };
		extras.queryParamsHandling = 'merge';
		extras.state = {...this.currentState.value, ...extras.state};
		extras.preserveFragment = settings?.preserveFragment ?? false;
		return this.router.navigate(commands, extras);
	}

	/**
	 * Routes to a route option including the necessary parameters
	 * If the parameters are not provided, the current page state and route params will be used
	 */
	navigate(resolvedRoute: ResolvedRoute): Promise<boolean>
	navigate(setup: NavigateSetup): Promise<boolean>
	navigate(route: RouteOption, params?: RouteParams, settings?: RouteSettings): Promise<boolean>
	navigate(route: RouteOption | NavigateSetup | ResolvedRoute, params?: RouteParams, settings?: RouteSettings): Promise<boolean> {
		if (route instanceof Object && 'commands' in route && 'extras' in route) {
			return this.router.navigate(route.commands, route.extras);
		}
		const { commands, extras } = typeof route === 'string'
			? this.toRoute(route, params, settings)
			: this.toRoute(route);
		return this.router.navigate(commands, extras);
	}

	/**
	 * Routes to a route option including the necessary parameters
	 * If the parameters are not provided, the current page state and route params will be used
	 */
	replace(resolvedRoute: ResolvedRoute): Promise<boolean>
	replace(setup: NavigateSetup): Promise<boolean>
	replace(route: RouteOption, params?: RouteParams, settings?: RouteSettings): Promise<boolean>
	replace(route: RouteOption | NavigateSetup | ResolvedRoute, params?: RouteParams, settings?: RouteSettings): Promise<boolean> {
		if (route instanceof Object && 'commands' in route && 'extras' in route) {
			return this.router.navigate(route.commands, { ...route.extras, replaceUrl: true });
		}
		const { commands, extras } = typeof route === 'string'
			? this.toRoute(route, params, settings)
			: this.toRoute(route);
		extras.replaceUrl = true;
		return this.router.navigate(commands, extras);
	}

	/**
	 * Converts a route option to a route including the necessary parameters
	 * If the parameters are not provided, the current page state and route params will be used
	 */
	toRoute(setup: NavigateSetup): ResolvedRoute
	toRoute(route: RouteOption, params?: RouteParams, settings?: RouteSettings): ResolvedRoute
	toRoute(route: RouteOption | NavigateSetup, params?: RouteParams, settings?: RouteSettings): ResolvedRoute {
		const { setup, extras } = this._resolveSetupAndExtras(route, params, settings);
		const commands = this._resolveNavigationCommands(setup, extras);
		return { commands, extras };
	}

	/**
	 * Converts a route option to a URL including the necessary parameters
	 */
	toUrl(resolvedRoute: ResolvedRoute): string
	toUrl(setup: NavigateSetup): string
	toUrl(route: RouteOption, params?: RouteParams, settings?: RouteSettings): string
	toUrl(route: RouteOption | NavigateSetup | ResolvedRoute, params?: RouteParams, settings?: RouteSettings): string {
		if (route instanceof Object && 'commands' in route && 'extras' in route) {
			return this.router.createUrlTree(route.commands, route.extras).toString();
		}
		const { setup, extras } = this._resolveSetupAndExtras(route, params, settings);
		const commands = this._resolveNavigationCommands(setup, extras);
		return this.router.createUrlTree(commands, extras).toString();
	}

	/**
	 * Determines if the given route option is the current route
	 */
	isCurrentRoute(resolvedRoute: ResolvedRoute): boolean
	isCurrentRoute(setup: NavigateSetup): boolean
	isCurrentRoute(route: RouteOption, params?: RouteParams, settings?: RouteSettings): boolean
	isCurrentRoute(route: RouteOption | NavigateSetup | ResolvedRoute, params?: RouteParams, settings?: RouteSettings): boolean {
		if (route instanceof Object && 'commands' in route && 'extras' in route) {
			return this.toUrl(route) === this.currentUrl.value;
		} else {
			const { setup, extras } = this._resolveSetupAndExtras(route, params, settings);
			const commands = this._resolveNavigationCommands(setup, extras);
			const url = this.router.createUrlTree(commands, extras).toString();
			return this.currentUrl.value === url;
		}
	}

	private _resolveSetupAndExtras(route: RouteOption | NavigateSetup, params?: RouteParams, settings?: RouteSettings): { setup: NavigateSetup, extras: NavigationExtras } {
		let setup: NavigateSetup = typeof route === 'string' ? { toRoute: route } : deepClone(route);
		setup.settings = { ...(settings ?? {}), ...(setup.settings ?? {}) };
		setup.params = { ...(params ?? {}), ...(setup.params ?? {}) };

		const context = this.contextService.context.value;

		if (!setup.params.organizationId) {
			setup.params.organizationId = context.organizationId;
		}

		if (!setup.params.templateId) {
			setup.params.templateId = context.templateId;
		}

		if (!setup.params.chatId) {
			setup.params.chatId = context.chatId;
		}

		const extras: NavigationExtras = {
			preserveFragment: settings?.preserveFragment ?? false,
			queryParamsHandling: (settings?.preserveQueries ?? true) ? 'merge' : undefined,
			queryParams: setup.queries,
			fragment: setup.fragment,
			state: this.currentState.value,
			...settings?.extras
		};

		return { setup, extras };
	}

	private _resolveNavigationCommands(setup: NavigateSetup, extras: NavigationExtras): any[] {
		if (!setup.params) {
			throw new Error('No parameters provided for route while they should have been resolved');
		}

		switch (setup.toRoute) {
			case '@login':
				return ['/auth', 'login'];
			case '@signup':
				return ['/auth', 'signup'];
			case '@mvp1':
				return ['/produce'];
			case '@mvp1/template':
				return ['/produce', setup.params.templateId];
			case '@mvp1/template/chat':
				return ['/produce', setup.params.templateId, setup.params.chatId];
			case '@templates':
				return ['/templates'];
			case '@templates/create':
				return ['/templates', 'create'];
			case '@templates/edit':
				return ['/templates', setup.params.templateId, 'edit'];
			case '@organizations':
				return ['/organizations'];
			case '@organization/create':
				return ['/organization', 'create'];
			case '@organization/details':
				return ['/organization', 'details'];
			case '@organization/billing':
				return ['/organization', 'billing'];
			case '@organization/members':
				return ['/organization', 'members'];
			case '@user/details':
				return ['/user', 'account'];
			case '@user/billing':
				return ['/user', 'billing'];
			default:
				console.error('Invalid route option', setup.toRoute);
				return ['/'];
		}
	}

	/**
	 * Determines the current route option based on the current URL
	 */
	private _determineCurrentRouteOption(): void {
		const url = this.currentUrl.value;
		const allOptions = Object.keys(allRouteOptions) as RouteOption[];
		const matchedOptions: RouteOption[] = [];
		const { setup, extras } = this._resolveSetupAndExtras(allOptions[0]);
		for (const option of allOptions) {
			setup.toRoute = option;
			const commands = this._resolveNavigationCommands(setup, extras);
			let matched = true;
			for (const command of commands) {
				if (!url.includes(command)) {
					matched = false;
					break;
				}
			}
			if (matched) {
				matchedOptions.push(option);
			}
		}

		if (matchedOptions.length > 0) {
			// console.warn('Multiple route options found for URL:', this.currentUrl.value, matchedOptions);
			this._setCurrentRouteOption(matchedOptions[matchedOptions.length - 1]);
		} else {
			this._setCurrentRouteOption(undefined);
		}
	}

	private _setCurrentRouteOption(option: RouteOption | undefined): void {
		// if (option) {
		// 	console.warn('Current route option:', option);
		// } else {
		// 	console.error('No route option found for URL:', this.currentUrl.value);
		// }
		this.currentOption.next(option);
	}
}
