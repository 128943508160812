import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-h2',
	templateUrl: './h2.component.html',
	styleUrls: ['./h2.component.scss'],
})
export class H2Component {
	@Input() public image: string | null | undefined;
	@Input() public faIcon: string | null | undefined;
	@Input({ required: true }) public title: string | null | undefined;
	@Input() public subtitle: string | null | undefined;
	@Input() public description: string | null | undefined;
	@Input() public mode: 'normal' | 'table' = 'normal';
}
