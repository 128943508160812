import { Component, Input } from '@angular/core';
import { ValueLink } from 'src/app/core/shared/utilities/data/dynamic/valueWrapper';
import { BaseInputElement } from '../../../../models/layout/inputElements';
import { BlockLayoutElementComponent } from '../../base/block-layout-element.component';

@Component({
	selector: 'app-normal-input-element-container',
	template: `
		<label *ngIf="!hideLabel" [for]="for">
			<span [innerHTML]="label | dynamic"></span>
			<p *ngIf="description | dynamic" [innerHTML]="description | dynamic"></p>
		</label>
		<div class="d-flex w-100 flex-row gap-2 justify-content-start align-items-center">
			<ng-content></ng-content>
		</div>
		<div *ngIf="suggestionTitles && suggestionTitles.length > 0" class="d-flex w-100 flex-row">
			<button
				*ngFor="let suggestion of suggestionTitles; let i = index"
				class="btn btn-sm btn-secondary me-2 mt-2"
				(click)="setSuggestion(i)"
				[innerHTML]="suggestion | dynamic"
				[class.active]="(value | dynamic) === suggestionValues![i]"
			></button>
		</div>
	`,
	styleUrls: ['../input-elements.styles.scss'],
})
export class NormalInputElementContainer extends BlockLayoutElementComponent {
	@Input() for!: string;
	@Input() label: BaseInputElement<never>['label'] = '';
	@Input() description!: BaseInputElement<never>['description'];
	@Input() value!: BaseInputElement<unknown>['value'];

	@Input() hideLabel: boolean = false;

	@Input() suggestionTitles: BaseInputElement<never>['suggestionTitles'];
	@Input() suggestionValues: BaseInputElement<unknown>['suggestionValues'];

	override setupAllValues(): void {}

	override setupValueForKey(_key: keyof this): void {}

	override setupValueInArrayForKey(_key: keyof this, _index: number): void {}

	protected setSuggestion(index: number): void {
		if (this.value instanceof ValueLink) {
			this.value.value = this.suggestionValues![index];
		} else {
			this.value = this.suggestionValues![index];
		}
	}
}

/**
 *
 * Input system ------
 *
 * - input elements maken
 * - valuecontroller testen
 * - forms genereren op basis van input definitions
 *
 *
 * Definitions system ------
 *
 * - ANGULAR
 * 		- create definition page & component
 * 		- edit definition page & component
 * 		- navbar
 * 		- template editor
 *       - prompt split in array
 *       - voeg 'definition' toe als type aan array
 *
 * - FIREBASE
 * 		- collection & document definitions
 * 		- rules
 *    - functions??
 *
 *
 * Toekomst ------
 * - table layout elements
 * - chat elements (message, etc)
 * - inline button groups (tiny + small)
 * - inline input groups
 */
