import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import * as MobileDetect from 'mobile-detect';

export enum FileUploadState {
	IDLE = 'idle',
	UPLOADING = 'uploading',
	UPLOADED = 'uploaded',
	ERROR = 'error',
}

@Component({
	selector: 'app-file-handler',
	templateUrl: './file-handler.component.html',
	styleUrls: ['./file-handler.component.scss'],
})
export class FileHandlerComponent implements OnInit {
	public static audioFileTypes =
		'audio/aac, audio/ac3, audio/eac3, audio/flac, audio/mp4, audio/mpeg, audio/mp3, application/ogg, audio/opus, audio/wav, audio/m4a, .aac, .ac3, .eac3, .flac, .mp4, .mpeg, .mp3, .ogg, .opus, .wav, .m4a';

	@Input() initialFiles: File[] = [];

	@Input() userId: string | undefined;
	@Input() organizationId: string | undefined;
	@Input() tokenId: string | undefined;
	@Input() userSessionId: string | undefined;
	@Input() isActive: boolean = false;
	@Input() maxFiles: number = 10;
	@Input() disabled: boolean = false;
	@Input() accept = 'image/*';

	@Input() labelText: string = 'Add, drop or paste images...';

	@ViewChild('galleryInput') galleryInput: ElementRef | undefined;
	@ViewChild('cameraInput') cameraInput: ElementRef | undefined;

	@Output() selectedFiles: EventEmitter<File[]> = new EventEmitter<File[]>();
	@Output() readyToSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() bucketRefs: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() downloadUrls: EventEmitter<string[]> = new EventEmitter<string[]>();

	fileDisplay: string[] = [];
	fileBucketRefs: string[] = [];
	fileDownloadURLs: string[] = [];
	fileStates: FileUploadState[] = [];

	isDragging: boolean = false;
	isMobile: boolean = false;
	files: File[] = [];

	async ngOnInit() {
		const mb = new MobileDetect(navigator.userAgent);
		this.isMobile = mb.mobile() !== null;

		if (this.initialFiles.length > 0) {
			await this.addFiles(this.initialFiles);
		}
	}

	@HostListener('dragover', ['$event'])
	onDragOver(event: Event) {
		if (!this.isActive) return;
		event.preventDefault();
		this.isDragging = true;
	}

	@HostListener('dragleave', ['$event'])
	onDragLeave() {
		if (!this.isActive) return;
		this.isDragging = false;
	}

	@HostListener('drop', ['$event'])
	async onDrop(event: DragEvent) {
		if (!this.isActive) return;
		event.preventDefault();
		event.stopPropagation();
		this.isDragging = false;

		if (!event.dataTransfer?.files) {
			console.warn("Drop event didn't contain files, skipping.");
			return;
		}

		const newFiles = Array.from(event.dataTransfer.files) as File[];
		this.addFiles(newFiles).then();
	}

	@HostListener('document:paste', ['$event'])
	async onPaste(event: ClipboardEvent) {
		if (!this.isActive) return;

		const clipboardData = event.clipboardData;
		if (!clipboardData) {
			console.warn("Paste event didn't contain clipboard data, skipping.");
			return;
		}

		const items = Array.from(clipboardData.items) as DataTransferItem[];
		const newFiles = items.filter(item => item.kind === 'file').map(item => item.getAsFile()) as File[];
		this.addFiles(newFiles).then();
	}

	openGallery() {
		this.galleryInput?.nativeElement?.click();
	}

	openCamera() {
		this.cameraInput?.nativeElement?.click();
	}

	enabled() {
		return this.files.length < this.maxFiles;
	}

	async fileChanged(event: Event) {
		if (!(event.target instanceof HTMLInputElement)) return;

		const target = event.target as HTMLInputElement;
		if (!target.files) return;

		const newFiles = Array.from(target.files) as File[];
		this.addFiles(newFiles).then();
		target.value = '';
	}

	async addFiles(newFiles: File[]) {
		if (newFiles.length === 0) {
			return;
		}

		this.readyToSubmit.emit(false); // reset the ready to submit state because we are adding new files

		for (let i = 0; i < newFiles.length; i++) {
			if (this.files.length >= this.maxFiles) {
				break;
			}

			const newFile = newFiles[i];
			this.files.push(newFile);
			this.fileStates.push(FileUploadState.IDLE);
			this.fileBucketRefs.push('');
			this.fileDownloadURLs.push('');
			this.fileDisplay.push('');
			this.uploadFile(newFile, this.fileStates.length - 1).then(); // start uploading the file
			this.updateFileDisplay(newFile, this.fileDisplay.length - 1).then();
		}
		this.selectedFiles.emit(this.files);
	}

	async uploadFile(file: File, index: number) {
		// create a unique id for the file
		const uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		const bucketRef =
			this.organizationId && this.userId
				? `organizations/${this.organizationId}/users/${this.userId}/uploads/${uid}-${file.name}`
				: `public/${this.tokenId}/${this.userSessionId}/uploads/${uid}-${file.name}`;

		// set the file state and bucket ref
		this.fileBucketRefs[index] = bucketRef;
		this.fileStates[index] = FileUploadState.UPLOADING;
		this.bucketRefs.emit(this.fileBucketRefs);

		// upload
		const storage = getStorage();
		const storageRef = ref(storage, bucketRef);
		uploadBytes(storageRef, file)
			.then(snapshot => {
				getDownloadURL(snapshot.ref)
					.then(downloadURL => {
						// check if file is still in the list
						const newIndex = this.files.indexOf(file);
						if (newIndex === -1) {
							// file was removed from the list, so we should remove it from the storage
							console.log('File uploaded, but it was removed from the list. Deleting it from storage.');
							deleteObject(storageRef)
								.then(() => {
									console.log(
										'File deleted from storage after it was removed from the list while still uploading.',
									);
								})
								.catch(error => {
									console.error(
										'Error, failed to delete file from storage after it was removed from the list:',
										error,
									);
								});
							return;
						}
						console.log('File available at', downloadURL);
						this.fileStates[newIndex] = FileUploadState.UPLOADED;
						this.fileDownloadURLs[newIndex] = downloadURL;
						this.downloadUrls.emit(this.fileDownloadURLs);
						this.onFileUploadComplete();
					})
					.catch(error => {
						console.error('Error:', error);
					});
			})
			.catch(error => {
				console.error('Error:', error);
				// check if file is still in the list
				const newIndex = this.files.indexOf(file);
				if (newIndex === -1) {
					return;
				}
				this.fileStates[newIndex] = FileUploadState.ERROR;
			});
	}

	async updateFileDisplay(file: File, index: number) {
		if (file.type.startsWith('image/') || file.type.startsWith('audio/')) {
			const reader = new FileReader();
			reader.onload = event => {
				// check if file is still in the list
				const newIndex = this.files.indexOf(file);
				if (newIndex === -1) {
					return;
				}
				this.fileDisplay[newIndex] = event.target?.result as string;
			};
			reader.onerror = error => {
				console.error('Error:', error);
			};
			reader.readAsDataURL(file);
		} else {
			this.fileDisplay[index] = file.name;
		}
	}

	onFileUploadComplete() {
		this.readyToSubmit.emit(this.fileStates.every(state => state === FileUploadState.UPLOADED));
	}

	removeFile(index: number) {
		// TODO: Delete the file from storage if it isn't being used

		this.files.splice(index, 1);
		this.fileStates.splice(index, 1);
		this.fileBucketRefs.splice(index, 1);
		this.fileDownloadURLs.splice(index, 1);
		this.fileDisplay.splice(index, 1);
		this.selectedFiles.emit(this.files);
		this.bucketRefs.emit(this.fileBucketRefs);
		this.downloadUrls.emit(this.fileDownloadURLs);
	}

	public removeFiles() {
		this.files = [];
		this.fileStates = [];
		this.fileBucketRefs = [];
		this.fileDownloadURLs = [];
		this.fileDisplay = [];
		this.selectedFiles.emit(this.files);
		this.bucketRefs.emit(this.fileBucketRefs);
		this.downloadUrls.emit(this.fileDownloadURLs);
	}
}
