import { Type } from '@angular/core';
import { LayoutElementComponent } from '../../components/layout/base/layout-element.component';

export enum LayoutElementRule { // good enough for now rule system....
	TopTightIfFirst = 'top-tight-if-first',
	TopTight = 'top-tight',
	VerticalTightBetween = 'vertical-tight-between',
	BottomTight = 'bottom-tight',
	BottomTightIfLast = 'bottom-tight-if-last',
}

// #region possible new rule system

// export class LayoutRule { }

// export class LayoutSpacingRule extends LayoutRule {
// 	static readonly TopTightIfFirst = new LayoutRule();
// }

// export class InputLayoutRule extends LayoutRule {
// 	static readonly ShowHelpAsParagraph = new InputLayoutRule();
// }

// const rules: LayoutRule[] = [
// 	LayoutSpacingRule.TopTightIfFirst,
// 	InputLayoutRule.ShowHelpAsParagraph,
// ];

//#endregion

export interface LayoutElementDecorator {
	component: Type<LayoutElementComponent>;
}

export abstract class LayoutElement {
	abstract type: string;

	overrideRules?: {
		ignore: LayoutElementRule[];
		apply: LayoutElementRule[];
	};

	protected forceIgnoreRule(rule: LayoutElementRule): this {
		if (!this.overrideRules) {
			this.overrideRules = {
				ignore: [],
				apply: [],
			};
		}
		this.overrideRules.ignore.push(rule);
		return this;
	}

	protected forceApplyRule(rule: LayoutElementRule): this {
		if (!this.overrideRules) {
			this.overrideRules = {
				ignore: [],
				apply: [],
			};
		}
		this.overrideRules.apply.push(rule);
		return this;
	}
}
