//eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorCallback = (error: any) => void;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ErrorAndReturnCallback<T> = (error: any) => T;

export const makeErrorCallbackWithReturn: <T>(
	errorCallBack: ErrorCallback | undefined,
	value: T,
) => ErrorAndReturnCallback<T> = (errorCallBack, value) => {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (error: any) => {
		if (errorCallBack) errorCallBack(error);
		return value;
	};
};
