import { Injectable } from '@angular/core';
import { FieldValue } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { DestroyableComponent } from 'src/app/core/shared/components/base/destroyable/destroyable.component';
import { getUnixTimestampInSeconds } from 'src/app/core/shared/utilities/timeUtilities';
import { ActiveOrganizationServiceTunnelToUserService, UserService } from 'src/app/user/services/user.service';
import {
	Organization5,
	OrganizationBranding5,
	OrganizationId,
	OrganizationInfo5,
	OrganizationMembership5,
} from '../../core/shared/models/database/organizationModels';
import { organizationDoc } from '../../core/shared/utilities/database/organizationUtilities';
import { fsdb, ManagedDocumentListenerByRef } from '../../core/shared/utilities/firebase/firestoreUtilities';
import { DotPatches } from '../../core/shared/utilities/typeUtilities';
import { VariableValues5 } from 'src/app/core/shared/models/database/variableModels';
import { MembershipsService } from './memberships.service';
import { ContextService } from 'src/app/core/shared/services/page/context.service';
import { NavigationService } from 'src/app/core/shared/services/navigation/navigation.service';

type Remembership = {
	lastActive?: {
		userId: string;
		organizationId: string;
		timestamp: number;
	};
	[userId: string]:
		| {
				organizationId: string;
				timestamp: number;
		  }
		| undefined; // organizationId
};

const MEMBERSHIP_STORAGE_KEY = 'remembership';

@Injectable({
	providedIn: 'root',
})
export class ActiveOrganizationService extends DestroyableComponent {
	public activeMembership: BehaviorSubject<OrganizationMembership5 | undefined> = new BehaviorSubject<
		OrganizationMembership5 | undefined
	>(undefined);
	public activeOrganizationId: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(
		undefined,
	);
	public activeOrganization: BehaviorSubject<Organization5 | undefined> = new BehaviorSubject<
		Organization5 | undefined
	>(undefined);
	private activeOrganizationListener: ManagedDocumentListenerByRef<Organization5> = new ManagedDocumentListenerByRef(
		(organization: Organization5 | undefined) => {
			if (organization)
				this.contextService.setOrganization(
					organization?.info?.name ?? 'Error',
					organization?.docId ?? 'Error',
				);
			else this.contextService.unsetOrganization();
			this.activeOrganization.next(organization);
		},
		(error: Error) => {
			console.error('Error listening to organization', error);
		},
	)
		.hookStop(this)
		.enableRetry()
		.enableUndefinedOnPause();

	private userServiceTunnel: ActiveOrganizationServiceTunnelToUserService;

	constructor(
		private userService: UserService,
		private contextService: ContextService,
		private membershipsService: MembershipsService,
		private navigationService: NavigationService,
	) {
		super();

		this.userServiceTunnel = userService.getTunnelForActiveOrganizationService();

		this._maintainCurrentActiveMembership();

		window.onfocus = () => {
			if (this.userService.user.value) {
				const currentlyActivated = this.activeMembership.value;
				if (
					currentlyActivated &&
					currentlyActivated.userRef.id === this.userService.user.value?.docRef?.id &&
					currentlyActivated.organizationRef.id! !==
						this._getLastActiveOrganizationIdFromUserDoc()?.organizationId
				) {
					this._rememberOrganizationIdForUserIdInLocalStorage(
						currentlyActivated.userRef.id,
						currentlyActivated.organizationRef.id!,
					);
					this.userServiceTunnel.setActiveOrganization(
						currentlyActivated.organizationRef.id! as OrganizationId,
					);
				}
			}
		};

		this.activeMembership.subscribe(membership => {
			if (!membership) {
				this.activeOrganizationListener.pause();
			} else {
				this.activeOrganizationListener.listenTo(membership.organizationRef);
			}
		});
	}

	get isLoaded(): boolean {
		return this.activeOrganization.value !== undefined;
	}

	get id(): OrganizationId | undefined {
		return this.activeOrganization.value?.docRef?.id as OrganizationId;
	}

	get branding(): OrganizationBranding5 | undefined {
		return this.activeOrganization.value?.data?.branding;
	}

	get variables(): VariableValues5 | undefined {
		return this.activeOrganization.value?.data?.variables;
	}

	get info(): OrganizationInfo5 | undefined {
		return this.activeOrganization.value?.info;
	}

	updateOrg(patch: Partial<Organization5> | { [P in keyof Organization5]?: FieldValue }, organizationId?: string) {
		const orgId = organizationId ?? this.activeOrganization.value?.docId;
		if (!orgId) {
			console.error('No organization to patch');
			return;
		}

		fsdb.update(organizationDoc(orgId).path, patch)
			.then(() => {
				//console.log('updated current user:', patch);
			})
			.catch(error => {
				console.error('error updating org', patch, error);
			});
	}

	patchOrg<T extends Organization5 = Organization5>(patch: DotPatches<T>, organizationId?: string) {
		const orgId = organizationId ?? this.activeOrganization.value?.docId;
		if (!orgId) {
			console.error('No organization to patch');
			return;
		}

		return fsdb
			.patch(organizationDoc(orgId).path, patch)
			.then(() => {
				console.log(`updated organization '${orgId}'`, patch);
			})
			.catch(error => {
				console.error('error updating org', patch, error);
			});
	}

	activateOrganizationByMembership(membership?: OrganizationMembership5 | null): void {
		const userId = this._getCurrentUserId();
		if (!userId) return;
		if (membership) {
			this._activateMembershipByOrganizationId(userId, membership.organizationRef.id!, true);
		} else {
			this._activateMembershipByOrganizationId(userId, null);
		}
	}

	activateOrganization(organizationId: string): void {
		const userId = this._getCurrentUserId();
		if (!userId) return;

		if (!this.membershipsService.isUserMemberOfOrganization(userId, organizationId)) {
			console.error('User is not a member of the organization');
			return;
		}

		this._activateMembershipByOrganizationId(userId, organizationId);
	}

	private _getCurrentUserId(): string | undefined {
		return this.userService.user.value?.docRef?.id;
	}

	private _maintainCurrentActiveMembership() {
		this.navigationService.currentState.subscribe(state => {
			if (state?.['organizationId'] !== this.activeOrganizationId.value) {
				const userId = this._getCurrentUserId();
				if (!userId) return;
				this._activateMembershipByOrganizationId(userId, state?.['organizationId']);
			}
		});

		this.membershipsService.memberships.subscribe(memberships => {
			const userId = this._getCurrentUserId();
			if (!userId) return;
			if (!memberships || memberships.length === 0) return;

			const allLastActivations: {
				organizationId: string;
				timestamp: number;
			}[] = [];

			const lastActive_FromStorage = this._getLastSelectedOrganizationIdFromStorage();
			if (
				lastActive_FromStorage &&
				this.membershipsService.isUserMemberOfOrganization(userId, lastActive_FromStorage.organizationId)
			) {
				allLastActivations.push(lastActive_FromStorage);
			}

			const lastActive_ForUserInStorage = this._getOrganizationIdForUserIdFromStorage(userId);
			if (
				lastActive_ForUserInStorage &&
				this.membershipsService.isUserMemberOfOrganization(userId, lastActive_ForUserInStorage.organizationId)
			) {
				allLastActivations.push(lastActive_ForUserInStorage);
			}

			const lastActive_FromUserDoc = this._getLastActiveOrganizationIdFromUserDoc();
			if (
				lastActive_FromUserDoc &&
				this.membershipsService.isUserMemberOfOrganization(userId, lastActive_FromUserDoc.organizationId)
			) {
				allLastActivations.push(lastActive_FromUserDoc);
			}

			// sort by timestamp in descending order
			allLastActivations.sort((a, b) => b.timestamp - a.timestamp);

			// select the first one
			if (allLastActivations.length > 0) {
				this._activateMembershipByOrganizationId(userId, allLastActivations[0].organizationId, false, true);
			}

			// if no selection is possible, select the first one
			if (!this.activeMembership.value) {
				this._activateMembershipByOrganizationId(userId, memberships[0].organizationRef.id!);
			}
		});
	}

	private _activateMembershipByOrganizationId(
		userId: string,
		organizationId: string | null,
		asNewRoute: boolean = false,
		forceUpdate: boolean = false,
	): void {
		// if same then return
		if (!forceUpdate && this.activeMembership.value == null && organizationId == null) {
			return;
		}
		if (
			!forceUpdate &&
			this.activeMembership.value &&
			organizationId &&
			this.activeMembership.value.organizationRef.id! === organizationId
		) {
			return;
		}

		// is there a membership to activate?
		const memberships = this.membershipsService.memberships.value;
		if (!memberships || memberships.length === 0) {
			console.error('No memberships to select from');
			this.activeMembership.next(undefined);
			this.activeOrganizationId.next(undefined);
			return;
		}

		// if null, remove from local storage and set currentSelectedMembership to null
		if (!organizationId) {
			this.activeMembership.next(undefined);
			this.activeOrganizationId.next(undefined);
			return;
		}

		// find membership with organizationId
		const membership = memberships.find(m => {
			return m.organizationRef.id === organizationId && m.userRef.id === userId;
		});

		// if not a member, log error and return
		if (!membership) {
			console.error('User is not a member of the organization');
			this._forgetOrganizationIdForUserIdFromLocalStorage(userId);
			this.activeMembership.next(undefined);
			this.activeOrganizationId.next(undefined);
			return;
		}

		// remember activated and save to local storage
		this._rememberOrganizationIdForUserIdInLocalStorage(userId, organizationId);
		this.userServiceTunnel.setActiveOrganization(organizationId as OrganizationId);
		this.activeOrganizationId.next(organizationId);
		this.activeMembership.next(membership);

		if (asNewRoute) {
			this.navigationService.navigateToState('organizationId', organizationId);
		} else {
			this.navigationService.replaceState('organizationId', organizationId);
		}
	}

	private _getRemembershipFromStorage(): Remembership {
		const storedRemembership = localStorage.getItem(MEMBERSHIP_STORAGE_KEY);
		if (!storedRemembership) {
			localStorage.setItem(MEMBERSHIP_STORAGE_KEY, JSON.stringify({}));
			return {};
		}

		try {
			return JSON.parse(storedRemembership) as Remembership;
		} catch (e) {
			localStorage.setItem(MEMBERSHIP_STORAGE_KEY, JSON.stringify({}));
			console.error('Error parsing stored membership:', e);
		}
		return {};
	}

	private _rememberOrganizationIdForUserIdInLocalStorage(userId: string, organizationId: string) {
		const remembership = this._getRemembershipFromStorage();
		remembership[userId] = {
			organizationId,
			timestamp: getUnixTimestampInSeconds(),
		};
		remembership.lastActive = {
			userId,
			organizationId,
			timestamp: getUnixTimestampInSeconds(),
		};
		localStorage.setItem(MEMBERSHIP_STORAGE_KEY, JSON.stringify(remembership));
	}

	private _forgetOrganizationIdForUserIdFromLocalStorage(userId: string) {
		const remembership = this._getRemembershipFromStorage();
		if (!remembership[userId]) return;
		delete remembership[userId];
		localStorage.setItem(MEMBERSHIP_STORAGE_KEY, JSON.stringify(remembership));
	}

	private _getOrganizationIdForUserIdFromStorage(
		userId: string,
	): { organizationId: string; timestamp: number } | null {
		const remembership = this._getRemembershipFromStorage();
		return remembership[userId] ?? null;
	}

	private _getLastSelectedOrganizationIdFromStorage(): {
		userId: string;
		organizationId: string;
		timestamp: number;
	} | null {
		const remembership = this._getRemembershipFromStorage();
		return remembership.lastActive ?? null;
	}

	private _getLastActiveOrganizationIdFromUserDoc(): {
		organizationId: string;
		timestamp: number;
	} | null {
		const membershipsData = this.userService.membershipsData;
		if (!membershipsData || !membershipsData.active) return null;
		if (!membershipsData.active.organizationId) return null;
		if (!membershipsData.active.timestamp) return null;
		return {
			organizationId: membershipsData.active.organizationId,
			timestamp: membershipsData.active.timestamp,
		};
	}
}
