import { Permission } from '../util/permission';
import { ChatType, ContentBlockType, WebhookDefinition } from './chat';
import { BrandingColors } from '../core/branding';
import { SharedRtdbObject } from '../util/internal';
import { GptModelSettings } from '../util/gpt';
import { MetaData } from '../util/general';
import { FormComponent } from './form-inputs';

export type Template = SharedRtdbObject<{
	id: string;
	created: SharedRtdbObject<MetaData>;
	organizationId?: string;
	permissions?: {
		modify: Permission;
		use: Permission;
		newChat: Permission;
	};
	name: string;
	model?: SharedRtdbObject<Model>;
	description: string;
	systemMessageId: string;
	updateNumber: number;
	replyOptions?: ReplyOption[];
	sharedWith?: string[];
	hidden: boolean;
	chatType?: ChatType;
	webhook?: SharedRtdbObject<WebhookDefinition>;
	branding?: BrandingColors;
	structuredOutputsEnabled?: boolean;
	structuredOutputs?: (StructuredOutputReasoning | StructuredOutput)[];
}>;

export interface StructuredOutput {
	index: number;
	/**
	 * this is also being used as an identifier, we might want to change that in the future
	 */
	name: string;
	type: ContentBlockType;
	instructions: string;
}

export interface StructuredOutputReasoning extends StructuredOutput {
	type: 'reasoning';
	thinkingTitle: string;
	resultTitle: string;
	hidden?: boolean;
}

export type ReplyOption = {
	text: string;
	prompt: string;
	isMain: boolean;
};

export type Model = {
	name: string;
	settings: ModelSettings;
};

export type ModelSettings = GptModelSettings & {};

export type FormTemplate = Template & {
	formInputs: FormComponent[];
	valid: boolean;
};

export type SystemMessage = {
	name: string;
	description: string;
	value: string;
};

export type PromptTemplate = Template & {
	promptTemplate: string;
};

export type GetTemplateAsFormBody = {
	organizationId: string;
	templateId: string;
};
