import { Injectable, NgZone } from '@angular/core';
import {
	AuthErrorCodes,
	createUserWithEmailAndPassword,
	EmailAuthProvider,
	GoogleAuthProvider,
	linkWithCredential,
	linkWithPopup,
	signInAnonymously,
	signInWithEmailAndPassword,
	signInWithPopup,
	unlink,
	updatePassword,
	updateProfile,
	User,
	UserInfo,
	verifyBeforeUpdateEmail,
} from 'firebase/auth';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BehaviorSubject } from 'rxjs';
import { fbAuth } from '../../firebase-init';
import { FirebaseError } from 'firebase/app';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public currentUser: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);

	constructor(
		ngZone: NgZone,
		private gaService: GoogleAnalyticsService,
	) {
		// check if user exists, set current user
		fbAuth.authStateReady().then(() => {
			this.currentUser.next(fbAuth.currentUser ?? undefined);
			this._isInitialized = true;
		});

		fbAuth.onAuthStateChanged(user => {
			if (user) {
				if (user.uid === this.currentUser.value?.uid) return;
				ngZone.run(() => {
					this.currentUser?.next(user);
				});
			} else {
				if (!this.currentUser && !user) return;
				ngZone.run(() => {
					this.currentUser?.next(undefined);
					console.log('no user, signing in anonymously');
					this.loginAnonymously().then();
				});
			}
		});
	}

	private _isInitialized: boolean = false;

	get isInitialized(): boolean {
		return this._isInitialized;
	}

	async logout() {
		this.gaService.event('auth_logout', 'auth', this.currentUser.value?.uid);
		await fbAuth.signOut();
	}

	async loginWithGoogle(): Promise<UserInfo> {
		const provider = new GoogleAuthProvider();
		const userCredential = await signInWithPopup(fbAuth, provider);
		this.gaService.event('auth_login', 'auth', userCredential.user.uid, undefined, undefined, { method: 'google' });
		return userCredential.user;
	}

	async loginAnonymously(): Promise<UserInfo> {
		const userCredential = await signInAnonymously(fbAuth);
		this.gaService.event('auth_login_anonymous', 'auth', userCredential.user.uid);
		return userCredential.user;
	}

	async loginBasic(email: string, password: string): Promise<UserInfo> {
		if (!email || !password) return Promise.reject('Email and password required');
		const userCredential = await signInWithEmailAndPassword(fbAuth, email, password);
		this.gaService.event('auth_login', 'auth', userCredential.user.uid, undefined, undefined, { method: 'basic' });
		return userCredential.user;
	}

	async signupBasic(name: string, email: string, password: string): Promise<UserInfo> {
		if (!email || !password) return Promise.reject('Email and password required');
		const userRedential = await createUserWithEmailAndPassword(fbAuth, email, password);
		const user = userRedential.user;
		await updateProfile(user, { displayName: name });
		this.gaService.event('auth_signup', 'auth', user.uid);
		return user;
	}

	async updateDisplayName(displayName: string): Promise<void> {
		const user = fbAuth.currentUser;
		if (!user) return Promise.reject('No user logged in');
		await updateProfile(user, { displayName });
		this.gaService.event('auth_update_display_name', 'auth', user.uid);
	}

	async updatePhotoURL(photoURL: string): Promise<void> {
		const user = fbAuth.currentUser;
		if (!user) return Promise.reject('No user logged in');
		await updateProfile(user, { photoURL });
		this.gaService.event('auth_update_photo_url', 'auth', user.uid);
	}

	async linkWithGoogle(): Promise<void> {
		const user = fbAuth.currentUser;
		if (!user) return Promise.reject('No user logged in');
		const provider = new GoogleAuthProvider();
		await linkWithPopup(user, provider);
		this.gaService.event('auth_link_with_google', 'auth', user.uid);
	}

	isValidEmail(email: string): boolean {
		return !!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
	}

	async linkWithCredential(email: string, password: string): Promise<void> {
		const user = fbAuth.currentUser;
		if (!user) return Promise.reject('No user logged in');
		const credential = EmailAuthProvider.credential(email, password);
		await linkWithCredential(user, credential);
		this.gaService.event('auth_link_with_credential', 'auth', user.uid);
	}

	async unlinkProvider(providerId: string): Promise<void> {
		const user = fbAuth.currentUser;
		if (!user) return Promise.reject('No user logged in');
		await unlink(user, providerId);
		this.gaService.event('auth_unlink_provider', 'auth', user.uid, undefined, undefined, { provider: providerId });
	}

	async updateEmail(email: string): Promise<void> {
		const user = fbAuth.currentUser;
		if (!user) return Promise.reject('No user logged in');
		if (!email) return Promise.reject('Email required');
		if (email === user.email) return Promise.reject('Email is the same');
		if (!this.isValidEmail(email)) return Promise.reject('Invalid email');
		try {
			await verifyBeforeUpdateEmail(user, email, {
				url: window.location.href,
			});
		} catch (error) {
			if ((error as FirebaseError).code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
				alert('Please login again to update your email');
				await this.logout();
			} else {
				throw error;
			}
		}
		this.gaService.event('auth_update_email', 'auth', user.uid);
	}

	async updatePassword(password: string): Promise<void> {
		const user = fbAuth.currentUser;
		if (!user) return Promise.reject('No user logged in');
		if (!password) return Promise.reject('Password required');
		try {
			await updatePassword(user, password);
		} catch (error) {
			if ((error as FirebaseError).code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
				alert('Please login again to update your password');
				await this.logout();
			} else {
				throw error;
			}
		}
		this.gaService.event('auth_update_password', 'auth', user.uid);
	}

	async deleteAccount(): Promise<void> {
		const user = fbAuth.currentUser;
		if (!user) return Promise.reject('No user logged in');
		await user.delete();
		this.gaService.event('auth_delete_account', 'auth', user.uid);
	}
}
