<div class="toasts-container">
	<!-- Then put toasts within -->
	<!-- use white text when danger and success backgrounds -->
	<div
		(click)="toast.onClick ? toast.onClick() : ''; removeToast(toast.id)"
		*ngFor="let toast of toasts"
		[id]="toast.id"
		[ngClass]="{
			'bg-success': toast.type === 'success',
			'bg-danger': toast.type === 'error',
			'bg-warning': toast.type === 'warning',
			'bg-info': toast.type === 'info',
			'text-white': toast.type === 'success' || toast.type === 'error' || toast.type === 'warning',
		}"
		aria-atomic="true"
		aria-live="assertive"
		class="toast show mt-2"
		role="alert"
	>
		<div *ngIf="toast.header" class="toast-header">
			<strong *ngIf="toast.header" class="mr-auto">{{ toast.header }}</strong>
			<!-- <strong class="mr-auto" *ngIf="!toast.header">{{toast.type | titlecase}}</strong> -->
			<!-- <small class="text-muted"></small>
			<button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="removeToast(toast.id)">
				<span aria-hidden="true">&times;</span>
			</button> -->
		</div>
		<div class="toast-body">
			{{ toast.message }}
		</div>
	</div>
</div>
