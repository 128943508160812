export const isObject = (item: unknown): boolean => {
	return item !== undefined && item !== null && typeof item === 'object' && !Array.isArray(item);
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const keyValueToArray = (obj: any, keyName: string = 'key') => {
	if (Object.keys(obj).some(key => obj[key][keyName]))
		throw new Error(`At least one object already has a '${keyName}' property`);
	return Object.keys(obj).map(key => ({ [keyName]: key, ...obj[key] }));
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepMerge = (target: any, source: any, mergeToCloneOfTarget: boolean = true) => {
	if (!source) return target;
	if (!target) return deepClone(source);
	if (mergeToCloneOfTarget) target = deepClone(target);
	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) {
					Object.assign(target, { [key]: {} });
				}
				deepMerge(target[key], source[key], false);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}
	return target;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepClone = (obj: any) => {
	if (obj === null || typeof obj !== 'object') return obj;
	return JSON.parse(JSON.stringify(obj));
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepFreeze = (obj: any) => {
	Object.keys(obj).forEach(key => {
		if (obj[key] && typeof obj[key] === 'object') {
			deepFreeze(obj[key]);
		}
	});
	return Object.freeze(obj);
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepDiff = (obj1: any, obj2: any) => {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const diff: { [key: string]: any } = {}; // Add index signature to diff object
	for (const key in obj1) {
		if (isObject(obj1[key]) && isObject(obj2[key])) {
			const subDiff = deepDiff(obj1[key], obj2[key]);
			if (Object.keys(subDiff).length > 0) {
				diff[key] = subDiff;
			}
		} else if (obj1[key] !== obj2[key]) {
			diff[key] = obj2[key];
		}
	}
	return diff;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepFilter = (obj: any, predicate: (key: string, value: any) => boolean) => {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: { [key: string]: any } = {}; // Add index signature to result object
	Object.keys(obj).forEach(key => {
		if (isObject(obj[key])) {
			result[key] = deepFilter(obj[key], predicate);
		} else if (predicate(key, obj[key])) {
			result[key] = obj[key];
		}
	});
	return result;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepMap = (obj: any, mapper: (key: string, value: any) => any) => {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: { [key: string]: any } = {}; // Add index signature to result object
	Object.keys(obj).forEach(key => {
		if (isObject(obj[key])) {
			result[key] = deepMap(obj[key], mapper);
		} else {
			result[key] = mapper(key, obj[key]);
		}
	});
	return result;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepEqual = (obj1: any, obj2: any): boolean => {
	if (obj1 === obj2) return true; // if the same object, then of course they are equal

	if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
		// Handle NaN case
		return obj1 !== obj1 && obj2 !== obj2;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = new Set(Object.keys(obj2));

	if (keys1.length !== keys2.size) return false;

	for (const key of keys1) {
		if (!keys2.has(key)) return false;
		if (!deepEqual(obj1[key], obj2[key])) return false;
	}

	return true;
};
