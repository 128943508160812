<div class="d-flex flex-row bg-primary" id="app">
	<div id="navbar-side">
		<app-navbar-side />
	</div>

	<div class="flex-grow-1 d-flex flex-column overflow-hidden">
		<!-- <div *ngIf="userService.isLoggedInAsGod" class="flex-grow-0 flex-shrink-0 w-100 z-3 text-bg-primary" style="height: 5px;" [tooltipRaw]="'God mode'"></div> -->

		<div id="navbar-top">
			<app-navbar-top></app-navbar-top>
		</div>

		<div class="flex-grow-1" id="content">
			<div class="container p-3 h-100">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>

	<app-toasts></app-toasts>
</div>
