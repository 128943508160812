import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DividerElement } from 'src/app/core/shared/models/layout/layoutElements';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';

@Component({
	selector: `app-divider-element`,
	template: ` <hr #hrEl />`,
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
})
export class DividerElementComponent extends BlockLayoutElementComponent {
	@ViewChild('hrEl', { static: true }) hrEl!: ElementRef<HTMLHRElement>;
	@Input() strength: DividerElement['strength'] = 'light';

	protected override get contextElement(): ElementRef<HTMLHRElement> {
		return this.hrEl;
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	override setupRuleBasedStyles(calculatedRules: any): void {
		super.setupRuleBasedStyles(calculatedRules);
		this.setClass('border-0', true);
		this.setClass('border-top', true);
		this.setClass('border-dark', true);
	}

	override setupAllValues(): void {
		this.setupValueForKey('strength');
	}

	override setupValueForKey(key: keyof this): void {
		if (key === 'strength') {
			const unwrapped = unwrap(this.strength);
			this.setClass('opacity-25', unwrapped === 'light');
			this.setClass('opacity-50', unwrapped === 'medium');
			this.setClass('opacity-75', unwrapped === 'strong');
		}
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays
	}
}
