import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { LocalizationKey } from '../../../models/config/localization/localizationModels';
import { Gen8Params, RouteOption } from '../../../services/navigation/navigation.service';

type NavItemExtra = {
	faIcon: string;
	tooltip?: LocalizationKey;
	onlyShowOnHover?: boolean;
};

type NavItemAction = NavItemExtra & {
	onClick: () => void;
};

type NavItemToggle = NavItemExtra & {
	isActive: boolean;
	onClick: () => void;
};

type NavItemIndicator = NavItemExtra & {
	additionalClasses: string[];
}

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.css']
})
export class NavItemComponent {

	@Input() id?: string;
	@Input() faIcon?: string;
	@Input() faType?: 'light' | 'solid' | 'regular' | 'brands' = 'light';
	@Input({ required: true }) label: string = '';
	@Input() tooltip?: LocalizationKey;
	@Input() order?: number;

	@Input() isActive: boolean = false;
	@Input() isHighlighted: boolean = false;
	@Input() isDisabled: boolean = false;
	@Input() isLoading: boolean = false;

	@Input() actions: NavItemAction[] = [];
	@Input() toggles: NavItemToggle[] = [];
	@Input() startIndicator?: NavItemIndicator;
	@Input() labelIndicator?: NavItemIndicator;
	@Input() endIndicator?: NavItemIndicator;

	@Input() route: RouteOption | null = null;
	@Input() params: Gen8Params | null = null;

	@Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  // Bind the 'order' style property to the host element
  @HostBinding('style.order') get styleOrder() {
    return this.order;
  }

	onItemClick(event: MouseEvent) {
		if (this.isDisabled || this.isLoading) return;
		this.onClick.emit();
		if (!this.route) {
			event.stopPropagation();
		}
	}

	onActionClick(action: NavItemAction, event: MouseEvent) {
		// if (this.isDisabled || this.isLoading) return;
		action.onClick();
		event.stopPropagation();
	}

	onToggleClick(toggle: NavItemToggle, event: MouseEvent) {
		// if (this.isDisabled || this.isLoading) return;
		toggle.onClick();
		event.stopPropagation();
	}
}
