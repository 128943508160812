import { unwrap } from './../../../utilities/data/dynamic/valueWrappers';
import { Component, Input } from '@angular/core';
import { TextInputElement } from '../../../models/layout/inputElements';
import { BaseInputElementComponent } from './base-input-element.component';

@Component({
  selector: 'app-text-are-input-element',
  template: `
	<app-normal-input-element-container [setup]="setup" [for]="uniqueId">
		<textarea
			[id]="uniqueId"
			class="form-control"
			[disabled]="disabled | dynamic"
			[placeholder]="placeholder | dynamic"
			[value]="value | dynamic"
			(change)="onValueChange($event)"
			(input)="onInput($event)"
			appAutoSize
		></textarea>
	</app-normal-input-element-container>
	`,
  styleUrls: ['./input-elements.styles.scss']
})
export class TextAreaInputElementComponent extends BaseInputElementComponent<string> {

	@Input() prefix: TextInputElement['prefix'];
	@Input() suffix: TextInputElement['suffix'];
	@Input() autocomplete: TextInputElement['autocomplete'] = 'off';
	@Input() placeholder: TextInputElement['placeholder'];

	protected override onValueChangeEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
	protected override onInputEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
}
