import { SystemMessage } from '../chat/templates';

export class SystemPrompts {
	public static load(
		promptId: 'fvoihweh90dfviusf' | 'b2gk10xj45gklq2jkd' | 'a1v9fwoi23fhui9wef' | '-NvkUvOWGcrWL3Nko' | string,
	): SystemMessage {
		switch (promptId) {
			case 'fvoihweh90dfviusf': {
				return {
					name: 'EN: Legacy',
					description: 'HTML',
					value:
						'You are an AI assistant that helps people with their problems. Use HTML to format your messages. Start with <p> and end with </p>. The current time is ' +
						this.getCurrentTime('en-GB'),
				};
			}
			case 'b2gk10xj45gklq2jkd': {
				return {
					name: 'EN: Default',
					description: 'Markdown, with code block support',
					value:
						'You are an AI assistant that helps people with their problems. Use Markdown to format your messages. You can provide Latex formulas inline using the $ delimiter always surrounded by spaces, or as a block using the $$ delimiter always surrounded by empty lines; do not use any other delimiters. The current time is ' +
						this.getCurrentTime('en-GB'),
				};
			}
			case 'a1v9fwoi23fhui9wef': {
				return {
					name: 'NL: Oude versie',
					description: 'HTML',
					value:
						'Je bent een AI-assistent die mensen helpt met hun problemen. Gebruik HTML om je berichten te formatteren. Begin met <p> en eindig met </p>. De huidige tijd is ' +
						this.getCurrentTime('nl-NL'),
				};
			}
			case '-NvkUvOWGcrWL3Nko': {
				return {
					name: 'NL: Standaard',
					description: 'Markdown, met ondersteuning voor codeblokken',
					value:
						'Je bent een AI-assistent die mensen helpt met hun problemen. Gebruik Markdown om je berichten te formatteren. Je kunt Latex-formules inline leveren met de $ delimiter altijd omgeven door spaties, of als een blok met de $$ delimiter altijd omgeven door lege regels; gebruik geen andere delimiters. De huidige tijd is ' +
						this.getCurrentTime('nl-NL'),
				};
			}

			default: {
				// noinspection TailRecursionJS
				return this.load('b2gk10xj45gklq2jkd');
			}
		}
	}

	public static loadAll() {
		return Object.fromEntries(
			['fvoihweh90dfviusf', 'b2gk10xj45gklq2jkd', 'a1v9fwoi23fhui9wef', '-NvkUvOWGcrWL3Nko'].map(i => [
				i,
				this.load(i),
			]),
		);
	}

	private static getCurrentTime(lang: 'en-GB' | 'nl-NL') {
		return new Intl.DateTimeFormat(lang, {
			dateStyle: 'full',
			timeStyle: 'long',
			timeZone: 'Europe/Amsterdam',
		}).format(new Date());
	}
}
