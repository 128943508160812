import { Component, Input } from '@angular/core';
import { InputElementOption, RadioInputElement } from '../../../models/layout/inputElements';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';
import { BaseInputElementComponent } from './base-input-element.component';

@Component({
	selector: 'app-radio-input-element',
	template: `
		<app-normal-input-element-container [setup]="setup" [for]="uniqueId">
			<div class="d-flex flex-column w-100">
				@for (option of unwrappedOptions; track option.value) {
					<label class="radio-label">
						<input
							type="radio"
							class="form-check-input"
							[disabled]="disabled || (option.disabled | dynamic)"
							[checked]="(value | dynamic) == (option.value | dynamic)"
							[value]="option.value | dynamic"
							(click)="unsetIfOptionSet(option, $event)"
							(change)="onValueChange($event)"
						/>
						<span>{{ option.label }}</span>
					</label>
				}
			</div>
		</app-normal-input-element-container>
	`,
	styleUrls: ['./input-elements.styles.scss'],
})
export class RadioInputElementComponent extends BaseInputElementComponent<string> {
	@Input() options!: RadioInputElement['options'];

	protected get unwrappedOptions() {
		return unwrap(this.options);
	}

	protected unsetIfOptionSet(option: InputElementOption, event: Event) {
		if (!unwrap(option.disabled) && !unwrap(this.disabled) && unwrap(this.value) === unwrap(option.value)) {
			setTimeout(() => {
				this.setValue('');
			});
			event.preventDefault();
		}
	}

	protected override onValueChangeEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
	protected override onInputEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
}
