import { ConfigUIComponentTypes, HostConfig } from './hostConfigModels';

export const defaultHostConfig: HostConfig = {
	organizations: {
		linked: {},
		nonLinked: {},
	},
	ui: {
		navbar: {
			organization: {
				isEnabled: true,
				dropdown: {
					isEnabled: true,
					isSwitcherEnabled: true,
				},
				button: {
					isEnabled: true,
					routerLink: {
						toRoute: '@organization/details',
					},
				},
			},
			user: {
				button: {
					routerLink: {
						toRoute: '@user/details',
					},
				},
			},
		},
		pageStateGuard: {
			useComponent: ConfigUIComponentTypes.DEFAULT,
		},
		page: {
			generate: {
				isEnabled: true,
				faIcon: 'fa-solid fa-wand-magic-sparkles',
				useComponent: ConfigUIComponentTypes.DEFAULT,
			},
			templates: {
				isEnabled: true,
				faIcon: 'fa-solid fa-book',
				useComponent: 'default',
				isCreateEnabled: true,
			},
			organization: {
				isDetailsEnabled: true,
				members: {
					isEnabled: true,
					isMembersListEnabled: true,
					isInvitesEnabled: true,
					isInviteLinksEnabled: true,
				},
				billing: {
					isEnabled: true,
					isSavingsEnabled: true,
				},
			},
			organizations: {
				isEnabled: true,
				isCreateEnabled: true,
			},
			user: {
				billing: {
					isEnabled: false,
					isSavingsEnabled: true,
				},
			},
		},
		devTools: {
			isEnabled: false,
		},
	},
};
