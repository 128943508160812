import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const DefaultFavicon = 'favicon.ico';

@Injectable({
	providedIn: 'root',
})
export class FaviconService {
	private readonly icon = new BehaviorSubject<string>(DefaultFavicon);

	public readonly icon$ = this.icon.asObservable();

	/**
	 * Updates the favicon to the given URL.
	 * @param iconUrl URL of the favicon.
	 */
	public setIcon(iconUrl: string) {
		this.icon.next(iconUrl);
	}

	/**
	 * Resets the favicon to the default icon.
	 */
	public reset() {
		this.icon.next(DefaultFavicon);
	}
}
