export class Profiler {
	private id: string = Math.random().toString(36).substring(2, 6);

	private logs: Record<string, Date> = {};

	public static start() {
		return new Profiler();
	}

	public log(key: string): void {
		this.logs[key] = new Date();
	}

	public finish() {
		const keys = Object.keys(this.logs);
		for (const key of keys) {
			console.log(`[${this.id} ${this.logs[key].toISOString()}]: ${key}`);
		}
	}
}
