import { Injectable, TemplateRef } from '@angular/core';

export enum LayoutLocation {
	TOP = 'top',
	SIDE = 'side',
}

export type ExtraContent = {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	template: TemplateRef<any>;
	id: string;
};

@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	public readonly content: { [key in LayoutLocation]: ExtraContent[] } = {
		[LayoutLocation.TOP]: [],
		[LayoutLocation.SIDE]: [],
	};
	private contentId = 0;

	constructor() {}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	public addContent(content: TemplateRef<any>, layoutLocation: LayoutLocation): () => void {
		const id = (this.contentId++).toString();
		this.content[layoutLocation].push({ template: content, id });
		return () => this._removeContent(id, layoutLocation);
	}

	private _removeContent(id: string, location: LayoutLocation): void {
		const index = this.content[location].findIndex(c => c.id === id);
		if (index !== -1) {
			this.content[location].splice(index, 1);
			return;
		}

		console.warn(`Failed to remove page content: id ${id} not found.`);
	}
}
