import { Component, Input } from '@angular/core';
import { SliderInputElement } from '../../../models/layout/inputElements';
import { BaseInputElementComponent } from './base-input-element.component';

@Component({
	selector: 'app-number-input-element',
	template: `
		<app-normal-input-element-container [setup]="setup" [for]="uniqueId">
			<div
				style="min-width: 100px"
				class="me-2 py-1 px-2 bg-light rounded d-flex flex-row justify-content-center align-items-center gap-1"
			>
				<div *ngIf="prefix | dynamic" [innerHTML]="prefix | dynamic"></div>
				<div [innerText]="value | dynamic"></div>
				<div *ngIf="suffix | dynamic" [innerHTML]="suffix | dynamic"></div>
			</div>
			<input
				[id]="uniqueId"
				[class]="'form-range'"
				[type]="'range'"
				[disabled]="disabled | dynamic"
				[autocomplete]="autocomplete | dynamic"
				[placeholder]="placeholder | dynamic"
				[value]="value | dynamic"
				[min]="min | dynamic"
				[max]="max | dynamic"
				[step]="step | dynamic"
				(change)="onValueChange($event)"
				(input)="onInput($event)"
			/>
		</app-normal-input-element-container>
	`,
	styleUrls: ['./input-elements.styles.scss'],
})
export class SliderInputElementComponent extends BaseInputElementComponent<number> {
	@Input() prefix: SliderInputElement['prefix'];
	@Input() suffix: SliderInputElement['suffix'];

	@Input() min: SliderInputElement['min'];
	@Input() max: SliderInputElement['max'];
	@Input() step: SliderInputElement['step'];

	@Input() autocomplete: SliderInputElement['autocomplete'] = 'off';
	@Input() placeholder: SliderInputElement['placeholder'];

	protected override onValueChangeEventToInputValue(event: Event): number {
		return Number((event.target as HTMLInputElement).value);
	}
	protected override onInputEventToInputValue(event: Event): number {
		return Number((event.target as HTMLInputElement).value);
	}
}
