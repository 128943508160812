import { FormGroup } from '@angular/forms';
import { Condition, ConditionOperator } from '../../../../build-dependencies/shared';

export type CompareOperator = '==' | '!=' | '>' | '<' | '>=' | '<=';

export type ConditionCombine = Condition & {
	operator: ConditionOperator;
	conditions: Condition[];
};

export type ConditionInvert = {
	condition: Condition;
};

export type ConditionCompare = Condition & {
	name: string;
	operator: CompareOperator;
	value: string;
};

/**
 * Convert a FormGroup to a dictionary (Record<string, string>).
 *
 * @remarks
 * This function iterates over all the controls in the FormGroup and
 * adds them to the dictionary. If the control value is a boolean, it
 * is converted to either '1' or '0' before being added to the dictionary.
 *
 * @param formGroup - The FormGroup to be converted.
 * @returns The resulting dictionary.
 */
export function formGroupToDictionary(formGroup: FormGroup): Record<string, string> {
	const dictionary: Record<string, string> = {};
	for (const key in formGroup.controls) {
		const control = formGroup.controls[key];
		dictionary[key] = String(typeof control.value === 'boolean' ? (control.value ? '1' : '0') : control.value);
	}
	return dictionary;
}

export function evaluate(condition: Condition, dictionary: Record<string, string>): boolean {
	if (isConditionCombine(condition)) {
		return evaluateCombine(condition, dictionary);
	} else if (isConditionInvert(condition)) {
		return evaluateInvert(condition, dictionary);
	} else if (isConditionCompare(condition)) {
		return evaluateCompare(condition, dictionary);
	} else {
		return true;
	}
}

function evaluateCombine(condition: ConditionCombine, dictionary: Record<string, string>): boolean {
	const { operator, conditions } = condition;
	if (operator === 'and') {
		return conditions.every(c => evaluate(c, dictionary));
	} else if (operator === 'or') {
		return conditions.some(c => evaluate(c, dictionary));
	} else {
		return false;
	}
}

function evaluateInvert(condition: ConditionInvert, dictionary: Record<string, string>): boolean {
	const { condition: innerCondition } = condition;
	return !evaluate(innerCondition, dictionary);
}

function evaluateCompare(condition: ConditionCompare, dictionary: Record<string, string>): boolean {
	const { name, value, operator } = condition;

	const processedName = name.trim().toLowerCase();
	const actualValue = Object.entries(dictionary).find(([key]) => key.trim().toLowerCase() === processedName)?.[1];

	switch (operator) {
		case '==':
			return String(actualValue) == String(value);
		case '!=':
			return String(actualValue) != String(value);
		case '>':
			return Number(actualValue) > Number(value);
		case '<':
			return Number(actualValue) < Number(value);
		case '>=':
			return Number(actualValue) >= Number(value);
		case '<=':
			return Number(actualValue) <= Number(value);
		default:
			return false;
	}
}

function isConditionCombine(condition: Condition): condition is ConditionCombine {
	if (!condition) return false;
	return 'operator' in condition && 'conditions' in condition;
}

function isConditionInvert(condition: Condition): condition is ConditionInvert {
	if (!condition) return false;
	return 'condition' in condition;
}

function isConditionCompare(condition: Condition): condition is ConditionCompare {
	if (!condition) return false;
	return 'name' in condition && 'value' in condition && 'operator' in condition;
}
