import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { connectFunctionsEmulator } from '@firebase/functions';
import { connectAuthEmulator } from 'firebase/auth';
import { connectDatabaseEmulator } from 'firebase/database';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectStorageEmulator } from 'firebase/storage';
import { TimeagoModule } from 'ngx-timeago';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { fbAuth, fbDatabase, fbFirestore, fbFunctions, fbStorage, fbUI } from './firebase-init';
import { LocalizePipe } from './core/shared/directives/localization/localize.pipe';
import { CreditsToCurrencyStringPipe } from './core/shared/pipes/credits-to-currency.pipe';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

// Connect to Firebase emulators in development environment
if (environment.useEmulators) {
	console.log('Using Firebase Emulators!');

	// Firestore emulator
	connectFirestoreEmulator(fbFirestore, '127.0.0.1', 8080);

	// Functions emulator
	connectFunctionsEmulator(fbFunctions, '127.0.0.1', 5001);

	// Auth emulator
	connectAuthEmulator(fbAuth, 'http://127.0.0.1:9099');

	// Realtime Database emulator
	connectDatabaseEmulator(fbDatabase, '127.0.0.1', 9000);

	// Storage emulator
	connectStorageEmulator(fbStorage, '127.0.0.1', 9199);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		CoreModule,
		TimeagoModule.forRoot(),
		NgxGoogleAnalyticsModule.forRoot(environment.firebaseConfig.measurementId),
	],
	providers: [
		{ provide: 'Firestore', useValue: fbFirestore },
		{ provide: 'Auth', useValue: fbAuth },
		{ provide: 'Functions', useValue: fbFunctions },
		{ provide: 'Database', useValue: fbDatabase },
		{ provide: 'Storage', useValue: fbStorage },
		{ provide: 'fbUI', useValue: fbUI },
		LocalizePipe,
		CreditsToCurrencyStringPipe,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
