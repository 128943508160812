import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { InputElementOption, PickInputElement } from '../../../models/layout/inputElements';
import { BaseInputElementComponent } from './base-input-element.component';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';

@Component({
	selector: 'app-pick-input-element',
	template: `
		<app-normal-input-element-container [setup]="setup" [for]="uniqueId">
			<input #hiddenInput type="hidden" (change)="onValueChange($event)" />
			@if (!(value | dynamic)) {
				<div class="d-flex flex-row flex-wrap gap-2">
					<button
						*ngFor="let option of options | dynamic; let i = index"
						[disabled]="disabled || (option.disabled | dynamic)"
						class="btn btn-sm btn-secondary"
						(click)="pickOption(option)"
					>
						{{ option.label }}
					</button>
				</div>
			} @else {
				<div class="btn-group" style="width: fit-content">
					<button (submit)="(false)" disabled="true" class="btn btn-sm btn-secondary w-100">
						{{ labelForValue }}
					</button>
					<button
						(submit)="(false)"
						[disabled]="disabled"
						class="btn btn-sm btn-secondary"
						(click)="pickOption(null)"
					>
						X
					</button>
				</div>
			}
		</app-normal-input-element-container>
	`,
	styleUrls: ['./input-elements.styles.scss'],
})
export class PickInputElementComponent extends BaseInputElementComponent<string> {
	@ViewChild('hiddenInput') hiddenInput!: ElementRef<HTMLInputElement>;

	@Input() options!: PickInputElement['options'];

	protected get labelForValue() {
		const option = unwrap(this.options).find(option => unwrap(option.value) === unwrap(this.value));
		return option?.label || '';
	}

	protected pickOption(option: InputElementOption | null) {
		// trigger change event on the hidden input
		this.hiddenInput.nativeElement.value = option ? unwrap(option.value) : '';
		this.hiddenInput.nativeElement.dispatchEvent(new Event('change'));
	}

	protected override onValueChangeEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
	protected override onInputEventToInputValue(event: Event): string {
		return (event.target as HTMLInputElement).value;
	}
}
