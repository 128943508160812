import { Component } from '@angular/core';
import { BaseInputElementComponent } from './base-input-element.component';

@Component({
	selector: 'app-checkbox-input-element',
	template: `
		<app-normal-input-element-container [setup]="setup" [for]="uniqueId" [hideLabel]="true">
			<label class="checkbox-label form-check d-flex flex-row w-100 align-items-start" [for]="uniqueId">
				<input
					[id]="uniqueId"
					class="form-check-input"
					type="checkbox"
					[disabled]="disabled | dynamic"
					[checked]="value | dynamic"
					[value]="true"
					(change)="onValueChange($event)"
					(input)="onInput($event)"
				/>
				<span class="form-check-label" [innerHTML]="label | dynamic"></span>
			</label>
		</app-normal-input-element-container>
	`,
	styleUrls: ['./input-elements.styles.scss'],
})
export class CheckboxInputElementComponent extends BaseInputElementComponent<boolean> {
	protected override onValueChangeEventToInputValue(event: Event): boolean {
		return (event.target as HTMLInputElement).checked;
	}

	protected override onInputEventToInputValue(event: Event): boolean {
		return (event.target as HTMLInputElement).checked;
	}
}
