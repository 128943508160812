import { HostConfigService } from './host-config.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { HostConfigOption } from '../../models/config/host-config/hostConfigModels';
import { Subscription } from 'rxjs';
import { ActiveOrganizationService } from 'src/app/organization/services/active-organization.service';
import { OrganizationMembership } from '../../../../../build-dependencies/shared';
import { FsDoc } from '../../models/Utility';

@Directive({
	selector: '[ifHostConfig]',
})
export class IfHostConfigDirective {
	forOption: HostConfigOption | HostConfigOption[] | undefined;
	forOrganization: string | undefined;
	or?: boolean;
	andIf?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	elseTemplate: TemplateRef<any> | undefined;
	isOptionTrue: boolean = false;
	selectedMembership?: FsDoc<OrganizationMembership>;
	selectedMembershipSubscription?: Subscription;

	private hasIfView = false;
	private hasElseView = false;

	constructor(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private HostConfigService: HostConfigService,
		private orgService: ActiveOrganizationService,
	) {}

	@Input('ifHostConfig') set ifHostConfig(
		value:
			| HostConfigOption
			| HostConfigOption[]
			| {
					option: HostConfigOption | HostConfigOption[] | undefined;
					or?: boolean;
					andIf?: boolean;
					organizationId?: string;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					else?: TemplateRef<any>;
			  },
	) {
		if (typeof value === 'string') {
			this.forOption = value;
		} else if (Array.isArray(value)) {
			this.forOption = value;
		} else {
			this.forOption = value.option;
			this.forOrganization = value.organizationId;
			this.elseTemplate = value.else;
			this.or = value.or ?? false;
			this.andIf = value.andIf ?? true;
		}
		this.updateView();
	}

	ngOnInit() {
		this.listenToSelectedMembership();
		this.updateView();
	}

	ngOnChanges() {
		this.updateView();
	}

	ngOnDestroy() {
		if (this.selectedMembershipSubscription) {
			this.selectedMembershipSubscription.unsubscribe();
			this.selectedMembershipSubscription = undefined;
		}
	}

	listenToSelectedMembership() {
		if (this.selectedMembershipSubscription) {
			this.selectedMembershipSubscription.unsubscribe();
			this.selectedMembershipSubscription = undefined;
		}
		this.selectedMembershipSubscription = this.orgService.activeMembership.subscribe(membership => {
			this.selectedMembership = membership;
			this.updateView();
		});
	}

	updateOptionValue() {
		if (this.forOption) {
			let isOptionTrue: boolean;
			if (Array.isArray(this.forOption)) {
				isOptionTrue = this.forOption.some(option =>
					this.getBooleanOrError(this.HostConfigService.get(option, this.forOrganization), option),
				);
			} else {
				isOptionTrue = this.getBooleanOrError(
					this.HostConfigService.get(this.forOption, this.forOrganization),
					this.forOption,
				);
			}

			this.isOptionTrue = (isOptionTrue || (this.or ?? false)) && (this.andIf ?? true);
		} else {
			this.isOptionTrue = false;
		}
	}

	updateView() {
		this.updateOptionValue();

		if (this.isOptionTrue) {
			if (this.hasElseView) {
				this.viewContainer.clear();
				this.hasElseView = false;
			}

			if (!this.hasIfView) {
				this.viewContainer.createEmbeddedView(this.templateRef);
				this.hasIfView = true;
			}
		} else {
			if (this.hasIfView) {
				this.viewContainer.clear();
				this.hasIfView = false;
			}

			if (this.elseTemplate && !this.hasElseView) {
				this.viewContainer.createEmbeddedView(this.elseTemplate);
				this.hasElseView = true;
			}
		}
	}

	getBooleanOrError(value: boolean | undefined, option: HostConfigOption) {
		// noinspection SuspiciousTypeOfGuard
		if (value === undefined || value === null || typeof value !== 'boolean') {
			console.error('Error getting boolean value from host config for option', option, 'returning false');
			return false;
		}
		return value;
	}
}
