import { Toast, ToastsService } from '../../services/toasts.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-toasts',
	templateUrl: './toasts.component.html',
	styleUrls: ['./toasts.component.scss'],
})
export class ToastsComponent implements OnInit, OnDestroy {
	protected toasts: Toast[] = [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private subscription: any;

	constructor(private toastsService: ToastsService) {}

	ngOnInit(): void {
		this.subscription = this.toastsService.toasts.subscribe(toasts => {
			this.toasts = toasts;
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	removeToast(id: string) {
		this.toastsService.removeToast(id);
	}
}
