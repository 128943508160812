<div
	(dragover)="onDragOver($event)"
	(drop)="onDrop($event)"
	[class.dragging]="isDragging"
	class="d-flex flex-column align-items-center gap-1 h-100"
	id="fileHandler"
>
	<div class="d-flex flex-row my-auto gap-1">
		<!-- Picture from gallery -->
		<input
			#galleryInput
			(change)="fileChanged($event)"
			[accept]="accept"
			[disabled]="disabled"
			class="d-none"
			multiple
			type="file"
		/>

		<i class="fa-regular fa-image" role="button"></i>

		<!-- Picture from camera -->
		<ng-container *ngIf="isMobile">
			<input
				#cameraInput
				(change)="fileChanged($event)"
				[accept]="accept"
				[disabled]="disabled"
				capture
				class="d-none"
				multiple
				type="file"
			/>
			<i class="fa-regular fa-camera" role="button"></i>
		</ng-container>
	</div>

	<!-- Files -->
	<div *ngIf="fileDisplay && fileDisplay.length > 0" class="d-flex flex-wrap align-items-left gap-3 w-100">
		<div *ngFor="let display of fileDisplay; let i = index" class="d-block position-relative">
			<!-- Display -->
			<img
				*ngIf="display.startsWith('data:image')"
				[ngClass]="{ 'opacity-50': fileStates[i] !== 'uploaded' }"
				[src]="display"
			/>
			<audio
				*ngIf="display.startsWith('data:audio')"
				[ngClass]="{ 'opacity-50': fileStates[i] !== 'uploaded' }"
				[src]="display"
				controls
			></audio>
			<div
				*ngIf="!display.startsWith('data:image') && !display.startsWith('data:audio')"
				[ngClass]="{ 'opacity-50': fileStates[i] !== 'uploaded' }"
			>
				<i class="fa-solid fa-file" style="margin-right: 1rem"></i>
				<span style="margin-right: 1.75rem">{{ display }}</span>
			</div>

			<!-- Loader -->
			<div
				*ngIf="fileStates[i] === 'uploading'"
				class="spinner-border text-primary position-absolute"
				role="status"
				style="left: 0.5rem; top: 0.5rem"
			>
				<span class="visually-hidden" localize="general.loading"></span>
			</div>

			<!-- Error -->
			<div
				*ngIf="fileStates[i] === 'error'"
				class="text-danger position-absolute"
				style="left: 0.5rem; top: 0.5rem"
			>
				<i class="fa-solid fa-exclamation-triangle"></i>
			</div>

			<!-- Remove button -->
			<button
				(click)="removeFile(i); $event.stopPropagation()"
				*ngIf="fileStates[i] !== 'uploading'"
				[disabled]="disabled"
				class="btn btn-sm btn-danger remove-file"
			>
				X
			</button>
		</div>
	</div>
</div>
