import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TimeagoModule } from 'ngx-timeago';
import { MemberBadgeComponent } from './shared/components/badges/member-badge/member-badge.component';
import { UserBadgeComponent } from './shared/components/badges/user-badge/user-badge.component';
import { DynamicFormComponent } from './shared/components/forms/dynamic-form/dynamic-form.component';
import { LoaderGuardComponent } from './shared/components/guards/loader-guard/loader-guard.component';
import { PageStateGuardComponent } from './shared/components/guards/page-state-guard/page-state-guard.component';
import { FormInputComponent } from './shared/components/inputs/dynamic-input/form-input.component';
import { FileHandlerComponent } from './shared/components/inputs/file-handler/file-handler.component';
import { InputSuggestionComponent } from './shared/components/inputs/input-suggestion/input-suggestion.component';
import { ButtonElementComponent } from './shared/components/layout/elements/button-element.component';
import { CaptionElementComponent } from './shared/components/layout/elements/caption-element.component';
import { DividerElementComponent } from './shared/components/layout/elements/divider-element.component';
import { HeaderElementComponent } from './shared/components/layout/elements/header-element.component';
import { IconElementComponent } from './shared/components/layout/elements/icon-element.component';
import { ImageElementComponent } from './shared/components/layout/elements/image-element.component';
import { SubtitleElementComponent } from './shared/components/layout/elements/subtitle-element.component';
import { TextElementComponent } from './shared/components/layout/elements/text-element.component';
import { AccordionLayoutGroupItemContentComponent } from './shared/components/layout/groups/accordion-layout-group-item-content.component';
import { AccordionLayoutGroupComponent } from './shared/components/layout/groups/accordion-layout-group.component';
import { BlockquoteLayoutGroupComponent } from './shared/components/layout/groups/blockquote-layout-group.component';
import { CardLayoutGroupComponent } from './shared/components/layout/groups/card-layout-group.component';
import { HorizontalFlexLayoutGroupComponent } from './shared/components/layout/groups/horizontal-flex-layout-group.component';
import { HorizontalLayoutGroupComponent } from './shared/components/layout/groups/horizontal-layout-group.component';
import { HorizontalSplitLayoutGroupComponent } from './shared/components/layout/groups/horizontal-split-layout-group.component';
import { IndentLayoutGroupComponent } from './shared/components/layout/groups/indent-layout-group.component';
import { TabLayoutGroupItemBodyComponent } from './shared/components/layout/groups/tab-layout-group-item-body.component';
import { TabLayoutGroupItemTabComponent } from './shared/components/layout/groups/tab-layout-group-item-tab.component';
import { TabLayoutGroupComponent } from './shared/components/layout/groups/tab-layout-group.component';
import { VerticalLayoutGroupComponent } from './shared/components/layout/groups/vertical-layout-group.component';
import { H1Component } from './shared/components/layout/h1/h1.component';
import { H2Component } from './shared/components/layout/h2/h2.component';
import { NormalInputElementContainer } from './shared/components/layout/inputs/containers/normal-input-element-container.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { SpacerLayoutElementComponent } from './shared/components/layout/utilities/spacer-layout-element.component';
import { NavItemComponent } from './shared/components/navbar/nav-item/nav-item.component';
import { NavListDividerComponent } from './shared/components/navbar/nav-list-divider/nav-list-divider.component';
import { NavbarSideComponent } from './shared/components/navbar/navbar-side.component';
import { NavbarTopComponent } from './shared/components/navbar/navbar-top.component';
import { ToastsComponent } from './shared/components/toasts/toasts.component';
import { AutofocusDirective } from './shared/directives/autofocus/autofocus.directive';
import { AutoSizeDirective } from './shared/directives/autosize/autosize.directive';
import { HostConfigDirective } from './shared/directives/host-config/host-config.directive';
import { IfConfigEnabledPipe } from './shared/directives/host-config/if-config-enabled.pipe';
import { IfHostConfigDirective } from './shared/directives/host-config/if-host-config.directive';
import { RouterConfigPipe } from './shared/directives/host-config/router-config.pipe';
import { AsLocalizationKeyPipe } from './shared/directives/localization/as-localization-key.pipe';
import { LocalizeDirective } from './shared/directives/localization/localize.directive';
import { LocalizePipe } from './shared/directives/localization/localize.pipe';
import { IfHasPermissionDirective } from './shared/directives/permissions/if-has-permission.directive';
import { IfHasPermissionPipe } from './shared/directives/permissions/if-has-permission.pipe';
import { TooltipDirective } from './shared/directives/tooltip/tooltip.directive';
import { CreditsToCurrencyStringPipe } from './shared/pipes/credits-to-currency.pipe';
import { SanitizeHtmlPipe } from './shared/pipes/sanitize-html.pipe';
import { DynamicPipe } from './shared/utilities/data/dynamic/dynamicPipe';
import { TextInputElementComponent } from './shared/components/layout/inputs/text-input-element.component';
import { TextAreaInputElementComponent } from './shared/components/layout/inputs/text-area-input-element.component';
import { NumberInputElementComponent } from './shared/components/layout/inputs/number-input-element.component';
import { SliderInputElementComponent } from './shared/components/layout/inputs/slider-input-element.component';
import { CheckboxInputElementComponent } from './shared/components/layout/inputs/checkbox-input-element.component';
import { NavigationLinkDirective } from './shared/services/navigation/navigation-link.directive';
import { PickInputElementComponent } from './shared/components/layout/inputs/pick-input-element.component';
import { RadioInputElementComponent } from './shared/components/layout/inputs/radio-input-element.component';
import { SelectInputElementComponent } from './shared/components/layout/inputs/select-input-element.component';
import { InitDirective } from './shared/directives/init/init.directive';
import { BrandingComponent } from './shared/components/forms/branding/branding.component';
import { BetterTimeagoPipe } from './shared/pipes/better-timeago.pipe';
import { ToDatePipe } from './shared/pipes/to-date.pipe';
import { ToidPipe } from './shared/pipes/toid.pipe';
import { EditorjsRendererComponent } from './shared/components/editorjs-renderer/editorjs-renderer.component';

@NgModule({
	declarations: [
		NavbarTopComponent,
		NavbarSideComponent,
		NavItemComponent,
		NavListDividerComponent,
		NavigationLinkDirective,

		PageStateGuardComponent,
		LoaderGuardComponent,
		MemberBadgeComponent,
		UserBadgeComponent,
		FileHandlerComponent,
		InputSuggestionComponent,
		FormInputComponent,
		DynamicFormComponent,
		ToastsComponent,
		IfHasPermissionDirective,
		AutoSizeDirective,
		HostConfigDirective,
		IfHostConfigDirective,
		// DestroyableComponent,
		// DynamicValuesComponent,
		IfConfigEnabledPipe,
		IfHasPermissionPipe,
		RouterConfigPipe,
		LocalizePipe,
		BetterTimeagoPipe,
		ToDatePipe,
		LocalizeDirective,
		CreditsToCurrencyStringPipe,
		AsLocalizationKeyPipe,
		AutofocusDirective,
		TooltipDirective,
		InitDirective,
		SanitizeHtmlPipe,
		H1Component,
		H2Component,

		LayoutComponent,
		HeaderElementComponent,
		SubtitleElementComponent,
		CaptionElementComponent,
		TextElementComponent,
		ImageElementComponent,
		IconElementComponent,
		DividerElementComponent,
		ButtonElementComponent,

		VerticalLayoutGroupComponent,
		HorizontalLayoutGroupComponent,
		HorizontalSplitLayoutGroupComponent,
		HorizontalFlexLayoutGroupComponent,
		IndentLayoutGroupComponent,
		BlockquoteLayoutGroupComponent,
		TabLayoutGroupComponent,
		TabLayoutGroupItemBodyComponent,
		TabLayoutGroupItemTabComponent,
		AccordionLayoutGroupComponent,
		AccordionLayoutGroupItemContentComponent,
		CardLayoutGroupComponent,

		NormalInputElementContainer,
		TextInputElementComponent,
		TextAreaInputElementComponent,
		NumberInputElementComponent,
		SliderInputElementComponent,
		CheckboxInputElementComponent,
		PickInputElementComponent,
		RadioInputElementComponent,
		SelectInputElementComponent,

		SpacerLayoutElementComponent,

		DynamicPipe,
		BrandingComponent,
		EditorjsRendererComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [
		NavbarTopComponent,
		NavbarSideComponent,
		NavItemComponent,
		NavListDividerComponent,
		NavigationLinkDirective,

		PageStateGuardComponent,
		LoaderGuardComponent,
		MemberBadgeComponent,
		UserBadgeComponent,
		FileHandlerComponent,
		InputSuggestionComponent,
		DynamicFormComponent,
		ToastsComponent,
		TimeagoModule,
		IfHasPermissionDirective,
		AutoSizeDirective,
		HostConfigDirective,
		IfHostConfigDirective,
		// DestroyableComponent,
		// DynamicValuesComponent,
		IfConfigEnabledPipe,
		IfHasPermissionPipe,
		RouterConfigPipe,
		LocalizePipe,
		BetterTimeagoPipe,
		ToDatePipe,
		LocalizeDirective,
		CreditsToCurrencyStringPipe,
		AsLocalizationKeyPipe,
		AutofocusDirective,
		TooltipDirective,
		H1Component,
		H2Component,

		LayoutComponent,
		// HeaderElementComponent,
		// SubtitleElementComponent,
		// TextElementComponent,
		// ImageElementComponent,
		// IconElementComponent,
		// DividerElementComponent,
		// ButtonElementComponent,

		// VerticalLayoutGroupComponent,
		// HorizontalLayoutGroupComponent,
		// HorizontalSplitLayoutGroupComponent,
		// HorizontalFlexLayoutGroupComponent,
		// IndentLayoutGroupComponent,
		// BlockquoteLayoutGroupComponent,
		// TabLayoutGroupComponent,
		// AccordionLayoutGroupComponent,
		// CardLayoutGroupComponent,

		// SpacerLayoutElementComponent,

		DynamicPipe,
		SelectInputElementComponent,
		BrandingComponent,
		EditorjsRendererComponent,
	],
	imports: [CommonModule, RouterLink, RouterLinkActive, FormsModule, ReactiveFormsModule, ToidPipe],
})
export class CoreModule {}
