import { Component } from '@angular/core';
import { UserService } from './user/services/user.service';
import { FaviconService } from './core/shared/services/favicon.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	private faviconElement = document.getElementById('appIcon') as HTMLLinkElement;

	constructor(
		protected userService: UserService,
		faviconService: FaviconService,
	) {
		faviconService.icon$.subscribe(iconUrl => (this.faviconElement.href = iconUrl));
	}
}
