import { GeneralInfo, MetaData } from '../util/general';
import { iTimestamp, SharedDocumentReference } from '../util/internal';
import { OrganizationInfo } from '../organization';
import { Nullable } from '../util/common';

export type BasePublicTokenAccess = {
	created: MetaData;
	organizationRef: SharedDocumentReference;
	publicTokenRef: SharedDocumentReference;
};

export type PublicTokenMvp1Access = BasePublicTokenAccess & {
	mvp1path: string;
};

export enum PublicTokenStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	REMOVED = 'removed',
	EXPIRED = 'expired',
}

export type PublicToken = {
	created: MetaData;
	owner: {
		organizationRef: SharedDocumentReference;
		organizationInfo: OrganizationInfo;
	};
	info: GeneralInfo;
	publicTokenId: string;
	expires: Nullable<iTimestamp>;
	status: PublicTokenStatus;
};

export type LinkUserToPublicTokenFunctionBody = {
	publicTokenId: string;
};

export type CreatePublicTokenFunctionBody = {
	organizationId: string;
	expires?: string;
};

export type CreatePublicTokenFunctionResult = {
	success: boolean;
	publicTokenId: string;
};
