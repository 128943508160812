import { iTimestamp, SharedDocumentReference } from '../util/internal';
import { MetaData } from '../util/general';
import { OrganizationPermissions, OrganizationRole } from './roles';
import { IdTag } from '../util/common';

export type OrganizationInviteId = IdTag<'organizationInviteId', `orgInvite_`>;

export enum OrganizationInviteState {
	PENDING = 'pending',
	ACCEPTED = 'accepted',
	DECLINED = 'declined',
	REVOKED = 'revoked',
	EXPIRED = 'expired',
}

export type OrganizationInvite = {
	inviteId: OrganizationInviteId;
	organizationRef: SharedDocumentReference;
	organizationName: string;
	email: string;
	state: OrganizationInviteState;
	remindersSent: number;
	created: MetaData;
	expires: iTimestamp;

	role: OrganizationRole;
	permissions: OrganizationPermissions;
};

export type CreateOrganizationInviteBody = {
	organizationId: string;
	email: string;
	role: OrganizationRole;
	permissions: OrganizationPermissions;
};

export type AcceptOrganizationInviteBody5 = {
	inviteId: OrganizationInviteId;
};

export type DeclineOrganizationInviteBody5 = {
	inviteId: OrganizationInviteId;
};

export type OrganizationInviteFunctionResult5 = {
	success: boolean;
	organizationInviteId: OrganizationInviteId;
};
