<form (ngSubmit)="onSubmit()" [formGroup]="this.form">
	<div [ngClass]="{ 'flex-column': !inline, 'w-100': inline }" class="d-flex gap-3">
		<ng-content select="[header]"></ng-content>

		<div class="d-flex flex-column w-100" data-test-id="dynamic-form">
			@for (inputSetup of this.inputs; track inputSetup?.name; let i = $index) {
				<app-form-input
					#formInput
					*ngIf="inputSetup"
					[disabled]="!active"
					[setup]="inputSetup"
					[value]="values![i]"
					[group]="form"
					[name]="inputSetup.name ? inputSetup.name : 'control_' + inputSetup.index"
					(keydown.enter)="handleEnter($event, i)"
					class="form-input"
					[ngClass]="[
						'form-input-' + inputSetup?.type,
						'form-input-' + (inputSetup?.description ? 'with-description' : 'without-description'),
					]"
				>
					<button
						*ngIf="
							inputSetup.type === FormComponentType.Textarea ||
							inputSetup.type === FormComponentType.Textfield
						"
						id="editTemplateButton"
						class="btn btn-sm border-med bg-white"
						tooltip="page.generate.chat.newChatForTemplate.clean"
					>
						<i class="fa-solid fa-quote-left"></i>
					</button>
				</app-form-input>
			}
		</div>

		<ng-content></ng-content>

		<ng-content select="[footer]"></ng-content>
	</div>
</form>
