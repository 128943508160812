import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from 'src/app/core/shared/services/navigation/navigation.service';
import { UserService } from 'src/app/user/services/user.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		private navigationService: NavigationService,
		private userService: UserService,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return new Observable<boolean>(subscriber => {
			this.userService.user.subscribe(async user => {
				if (!user) {
					if (route.data?.['signup']) {
						await this.navigationService.replaceTemporarily({
							toRoute: '@signup',
						});
						subscriber.next(false);
						subscriber.complete();
						return;
					}

					await this.navigationService.replaceTemporarily({
						toRoute: '@login',
					});
					subscriber.next(false);
					subscriber.complete();
					return;
				}

				if (user) {
					subscriber.next(true);
					subscriber.complete();
					return;
				}

				subscriber.complete();
			});
		});
	}
}
