
<div
	class="container-header mb-3"
	[style.order]="order"
	[ngClass]="isTop ? 'mt-2' : 'mt-4'"
>
	<hr class="container-header-line">
	<span class="container-header-content fw-bold text-secondary bg-primary" style="left: 1rem;">
		{{ label }}
	</span>
</div>
