import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { TimeagoPipe } from 'ngx-timeago';
import { unknownToDate } from '../utilities/time';

@Pipe({
	name: 'btimeago',
	pure: false,
})
export class BetterTimeagoPipe extends TimeagoPipe implements PipeTransform {
	/**
	 * Workaround pipe to make the timeago pipe work with firebase timestamps. Because we switched to numbers for dates
	 * instead of firebase's timestamps, we now have a database where there are both numbers and timestamps.
	 * This pipe takes them both and converts them if necessary.
	 */
	override transform(value: number | Timestamp | Date | null | undefined | unknown, ...args: unknown[]) {
		try {
			return super.transform(unknownToDate(value), ...args);
		} catch {
			return super.transform(value, ...args);
		}
	}
}
