import {
	canFormComponentTypeHaveValue,
	FormOptionsComponent,
	isFormInputComponent,
} from 'src/app/core/shared/models/FormInput';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormInputComponent } from '../../inputs/dynamic-input/form-input.component';
import { instant } from '../../../utilities/instant';
import { FormComponent as FormComp, FormComponentType, FormInputComponent as FormInputComp } from '../../../../../../build-dependencies/shared';

type FormInputSetup = FormInputComp & { controlName: string };

@Component({
	selector: 'app-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges {
	@Input() components: FormComp[] | undefined;
	@Input() values: Array<string> | undefined;
	@Input() active: boolean = true;
	@Input() inline: boolean = false;

	@Output('onSubmit') onSubmitEvent: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

	@ViewChildren('formInput') formInputs!: QueryList<FormInputComponent>;

	protected form!: FormGroup;
	protected inputs: (FormInputSetup | undefined)[] = [];
	protected readonly FormComponentType = FormComponentType;

	constructor(private fb: FormBuilder) {
		this.form = this.fb.group({});
	}

	ngOnInit() {
		this.convertComponentsToInputsAndSetupForm();
		this.setValuesToInputs().then();
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes['components']) {
			this.convertComponentsToInputsAndSetupForm();
		}
		if (changes['values']) {
			await this.setValuesToInputs();
		}
	}

	convertComponentsToInputsAndSetupForm() {
		if (!this.components) {
			this.inputs = [];
			return;
		}
		this.inputs = this.components.map((part, index) => {
			if (isFormInputComponent(part)) {
				return {
					...part,
					controlName: part.name ? part.name : `control_${index}`,
				};
			}
			return undefined;
		}) as (FormInputSetup | undefined)[];
		this.setupForm();
	}

	clearInputValues() {
		this.formInputs.forEach(input => input.clearInputValues());
		// this.values = [];
		// this.inputValues = {};
		// this.setValuesToInputs();
	}

	setupForm() {
		if (!this.components) return;
		// this.components.forEach((part, index) => {
		//   switch (part.type) {
		//     case FormComponentType.Checkbox:
		//       this.form.addControl(`control_${index}`, this.fb.control(false));
		//       break;
		//     case FormComponentType.Radio:
		//     case FormComponentType.Dropdown:
		//       this.form.addControl(`control_${index}`, this.fb.control(''));
		//       break;
		//     default:
		//       this.form.addControl(`control_${index}`, this.fb.control(''));
		//       break;
		//   }
		// });
		return this.setValuesToInputs();
	}

	setValuesToInputs() {
		return instant(() => {
			if (!this.components) return;
			if (!this.values) return;

			this.values.forEach((value, index) => {
				const controlName = this.inputs[index]?.controlName ?? `control_${index}`;
				this.form.get(controlName)?.setValue(value);
			});
		}, 50);
	}

	handleEnter(event: Event, index: number): void {
		const keyboardEvent = event as KeyboardEvent;
		const inputsArray = this.formInputs.toArray();

		// Hack to check if the rich text element has focus
		const editorHasFocus = !!document.querySelector('#editor-js-holder:focus-within');
		if (editorHasFocus) {
			return;
		}

		if (event.target instanceof HTMLTextAreaElement) {
			const textAreaElement = event.target as HTMLTextAreaElement;
			const caretPosition = textAreaElement.selectionStart;
			const textLength = textAreaElement.value.length;

			if (caretPosition !== textLength) {
				// Do not submit/next if the caret is not at the end of the text
				return;
			}
		}

		if (keyboardEvent.shiftKey) {
			return;
		}

		if (index === inputsArray.length - 1) {
			this.onSubmit();
		} else {
			const nextInput = inputsArray[index + 1];
			if (nextInput) {
				nextInput.focus();
			}
		}

		keyboardEvent.preventDefault();
	}

	onSubmit() {
		const values: string[] = [];

		if (!this.components) {
			console.log('Warning: empty form submitted');
			this.onSubmitEvent.emit(values);
			return;
		}

		for (let index = 0; index < this.components.length; index++) {
			const part = this.components[index];

			if (!canFormComponentTypeHaveValue(part.type)) {
				values.push('');
				continue;
			}

			const controlName = this.inputs[index]?.controlName ?? `control_${index}`;
			const controlValue = this.form.get(controlName)?.value;

			if (part.type === FormComponentType.Checkbox) {
				values.push(controlValue);
			} else if (
				part.type === FormComponentType.Radio ||
				part.type === FormComponentType.Dropdown ||
				part.type === FormComponentType.Pick
			) {
				const typedPart = part as FormOptionsComponent;

				if (typedPart.options !== undefined) {
					if (Array.isArray(typedPart.options)) {
						values.push(controlValue);
					} else {
						const selectedOption = typedPart.options[controlValue];
						values.push(selectedOption);
					}
				}
			} else {
				values.push(controlValue);
			}
		}

		this.onSubmitEvent.emit(values);
	}
}
