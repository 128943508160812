import { BrandingColors } from '../core/branding';
import { Contact, GeneralInfo, MetaData, Reference } from '../util/general';
import { IdTag, Nullable } from '../util/common';
import { VariablesData } from '../chat/variables';

export * from './invites';
export * from './membership';
export * from './permissions';
export * from './roles';

export type OrganizationId = IdTag<'organizationId', `org_`>;

export type OrganizationBranding = {
	colors: BrandingColors;
	logo: string | null;
};

export type OrganizationInfo = GeneralInfo & {
	website: Nullable<string>;
	contact: Nullable<Contact>;
};

export type OrganizationData = {
	variables?: VariablesData;
	branding?: OrganizationBranding;
};

export type Organization = {
	created: MetaData;
	info: OrganizationInfo;
	data?: OrganizationData;
};

export type OrganizationPaymentData = {
	paysForUsage: boolean;
	walletPath: Reference;
};

export type CreateOrganizationBody = {
	organizationName: string;
};

export type OrganizationFunctionResult = {
	success: boolean;
	organizationId: OrganizationId;
};
