import { Pipe, PipeTransform } from '@angular/core';
import { HostConfigOption } from '../../models/config/host-config/hostConfigModels';
import { HostConfigService } from './host-config.service';

@Pipe({
	name: 'ifConfigEnabled',
})
export class IfConfigEnabledPipe implements PipeTransform {
	constructor(private hostConfigService: HostConfigService) {}

	transform(value: HostConfigOption, organizationId?: string): boolean {
		return this.hostConfigService.get(value, organizationId);
	}
}
