import { LayoutElementComponent } from './layout-element.component';
import { LayoutElementRule } from '../../../models/layout/layoutBase';

export abstract class InlineBlockLayoutElementComponent extends LayoutElementComponent {
	protected override get rules(): LayoutElementRule[] {
		return [LayoutElementRule.BottomTightIfLast];
	}

	protected override setupRuleBasedStyles(calculatedRules: Set<LayoutElementRule>): void {
		this.setClass('d-inline-block');
		this.setClass('mt-0', calculatedRules.has(LayoutElementRule.TopTight));
		this.setClass('mb-3', !calculatedRules.has(LayoutElementRule.BottomTight), 'mb-0');
	}
}
