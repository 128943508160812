import { Component, Input } from '@angular/core';
import { NumberInputElement } from '../../../models/layout/inputElements';
import { BaseInputElementComponent } from './base-input-element.component';

@Component({
  selector: 'app-number-input-element',
  template: `
	<app-normal-input-element-container [setup]="setup" [for]="uniqueId">
		<div *ngIf="prefix | dynamic" [innerHTML]="prefix | dynamic"></div>
		<input
			[id]="uniqueId"
			[class]="'form-control'"
			[type]="'number'"
			[disabled]="disabled | dynamic"
			[autocomplete]="autocomplete | dynamic"
			[placeholder]="placeholder | dynamic"
			[value]="value | dynamic"
			[min]="min | dynamic"
			[max]="max | dynamic"
			[step]="step | dynamic"
			(change)="onValueChange($event)"
			(input)="onInput($event)"
		/>
		<div *ngIf="suffix | dynamic" [innerHTML]="suffix | dynamic"></div>
	</app-normal-input-element-container>
	`,
  styleUrls: ['./input-elements.styles.scss']
})
export class NumberInputElementComponent extends BaseInputElementComponent<number> {

	@Input() prefix: NumberInputElement['prefix'];
	@Input() suffix: NumberInputElement['suffix'];

	@Input() min: NumberInputElement['min'];
	@Input() max: NumberInputElement['max'];
	@Input() step: NumberInputElement['step'];
	
	@Input() autocomplete: NumberInputElement['autocomplete'] = 'off';
	@Input() placeholder: NumberInputElement['placeholder'];

	protected override onValueChangeEventToInputValue(event: Event): number {
		return Number((event.target as HTMLInputElement).value);
	}
	protected override onInputEventToInputValue(event: Event): number {
		return Number((event.target as HTMLInputElement).value);
	}
}
