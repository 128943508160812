import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ButtonElement } from 'src/app/core/shared/models/layout/layoutElements';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';
import { BlockLayoutElementComponent } from '../base/block-layout-element.component';
import { NavigationService, ResolvedRoute } from '../../../services/navigation/navigation.service';
import { InlineBlockLayoutElementComponent } from '../base/inline-block-layout-element.component';

@Component({
  selector: `app-button-element`,
	template: `
	<button
		class="btn"
		[class.btn-sm]="size == 'sm'"
		[class.btn-lg]="size == 'lg'"
		#btn (click)="onClick ? onClick() : null"
		[innerHTML]="content | dynamic | sanitizeHtml"
		[tooltipRaw]="tooltip ? (tooltip | dynamic) : null"
		[routerLink]="_resolvedRoute?.commands"
		[queryParams]="_resolvedRoute?.extras?.queryParams"
		[fragment]="_resolvedRoute?.extras?.fragment"
		[routerLinkActive]="'active'"
	></button>`,
	styles: [`
		:host {
			display: contents;
		}
	`]
})
export class ButtonElementComponent extends InlineBlockLayoutElementComponent {

	@ViewChild('btn', { static: true }) btn!: ElementRef<HTMLButtonElement>;

	protected override get contextElement(): ElementRef<HTMLButtonElement> {
		return this.btn;
	}

	constructor(private navService: NavigationService) {
		super();
	}

	@Input() sentiment: ButtonElement['sentiment'] = 'primary';
	@Input() state: ButtonElement['state'] = 'normal';
	@Input() content: ButtonElement['content'] = '';
	@Input() size: ButtonElement['size'] = 'md';
	@Input() tooltip?: ButtonElement['tooltip'];
	@Input() onClick?: () => void;
	@Input() navigation?: ButtonElement['navigation'];

	protected _resolvedRoute?: ResolvedRoute;
	private _listeningToNavServiceUpdates = false;

	override setupAllValues(): void {
		this.setupValueForKey('state');
		this.setupValueForKey('sentiment');
		this.setupValueForKey('navigation');
	}

	override setupValueForKey(key: keyof this): void {
		if (key === 'state') {
			const unwrapped = unwrap(this.state);
			this.contextElement.nativeElement.disabled = unwrapped === 'disabled';
			this.switchClass(unwrapped, {
				normal: null,
				loading: 'btn-loading',
				disabled: null,
				error: 'btn-error'
			});

		} else if (key === 'sentiment') {
			this.switchClass(unwrap(this.sentiment), {
				primary: 'btn-primary',
				secondary: 'btn-secondary',
				success: 'btn-success',
				danger: 'btn-danger',
				warning: 'btn-warning',
				info: 'btn-info',
				light: 'btn-light',
				dark: 'btn-dark',
				white: 'btn-white'
			});

		} else if (key === 'navigation') {
			this._updateNavigation();
		}
	}

	private _updateNavigation(): void {
		const unwrapped = unwrap(this.navigation);
		if (unwrapped) {
			this._resolvedRoute = this.navService.toRoute(unwrapped);
			if (!this._listeningToNavServiceUpdates) {
				this._listeningToNavServiceUpdates = true;
				this.addOnStopListener(this.navService.listen(this._updateNavigation.bind(this)), this.navService);
			}
		} else {
			this._resolvedRoute = undefined;
			if (this._listeningToNavServiceUpdates) {
				this._listeningToNavServiceUpdates = false;
				this.removeOnStopListenerByToken(this.navService);
			}
		}
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays
	}
}
