import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TextElement } from 'src/app/core/shared/models/layout/layoutElements';
import { unwrap } from '../../../utilities/data/dynamic/valueWrappers';
import { InlineBlockLayoutElementComponent } from '../base/inline-block-layout-element.component';

@Component({
	selector: 'app-text-element',
	template: `<p #pEl [innerHtml]="content | dynamic | sanitizeHtml" class="d-block"></p>`,
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
})
export class TextElementComponent extends InlineBlockLayoutElementComponent {
	@ViewChild('pEl', { static: true }) pEl!: ElementRef<HTMLParagraphElement>;

	protected override get contextElement(): ElementRef<HTMLParagraphElement> {
		return this.pEl;
	}

	@Input() content: TextElement['content'] = '';
	@Input() styles: TextElement['styles'] = [];

	constructor(protected sanitizer: DomSanitizer) {
		super();
	}

	override setupAllValues(): void {
		this.setupValueForKey('styles');
	}

	override setupValueForKey(key: keyof this): void {
		if (key === 'styles') {
			this.switchClass(unwrap(this.styles), {
				monospace: 'font-monospace',
				muted: 'text-muted',
				smaller: 'fs-7',
				larger: 'fs-5',
				light: 'fw-light',
				lighter: 'fw-lighter',
				bold: 'fw-bold',
				bolder: 'fw-bolder',
				italic: 'fst-italic',
			});
		}
	}
	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// Do nothing
	}
}
