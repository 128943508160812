export type BrandingColors = {
	primary: string | null;
	secondary: string | null;
	bg_primary: string | null;
	bg_secondary: string | null;
};

export const DefaultBrandingColors: BrandingColors = {
	// as defined in hosting/branding.scss
	primary: '#783aeb',
	secondary: '#6c757d',
	bg_primary: '#ffffff',
	bg_secondary: '#f8f9fa',
} as const;
