import { collection, doc, DocumentReference } from 'firebase/firestore';
import { fbFirestore as fs } from 'src/app/firebase-init';
import { fsdb } from '../firebase/firestoreUtilities';
import { WalletPaymentData } from '../../../../../build-dependencies/shared';
import { FsDoc } from '../../models/Utility';

export const WALLETS_COLLECTION_NAME = 'wallets';
export const WALLETS_LEGAL_OWNERS = ['users', 'organizations'];

export const walletsCollection = () => collection(fs, WALLETS_COLLECTION_NAME);
export const walletDoc = (walletId: string) => doc(fs, WALLETS_COLLECTION_NAME, walletId);

export const walletCreditsDataDoc = (walletId: string) =>
	doc(fs, `${WALLETS_COLLECTION_NAME}/${walletId}/walletData`, 'walletCredits');
export const walletCreditsHistoryCollection = (walletId: string) =>
	collection(fs, `${WALLETS_COLLECTION_NAME}/${walletId}/walletData/walletCredits/walletCreditHistory`);
export const walletPaymentDataDoc = (walletId: string) =>
	doc(fs, `${WALLETS_COLLECTION_NAME}/${walletId}/walletData`, 'walletPayment');

export async function paymentPlanRefFromWalletId(walletId: string): Promise<DocumentReference> {
	const walletPaymentDoc = walletPaymentDataDoc(walletId);
	const walletPaymentData = (await fsdb.get(walletPaymentDoc.path)) as FsDoc<WalletPaymentData>;
	if (!walletPaymentData) {
		throw new Error(`No wallet payment data found for wallet id ${walletId}`);
	}
	return walletPaymentData.planRef as DocumentReference;
}
