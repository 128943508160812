import { httpsCallable } from '@firebase/functions';
import { fbFirestore as fs, fbFunctions as cf } from 'src/app/firebase-init';
import { collection } from 'firebase/firestore';
import {
	AcceptCreditGiftInput,
	CreateCheckoutSessionResult,
	CreateCreditsCheckoutSessionBody,
	CreatePortalSessionResult,
} from '../../../../../build-dependencies/shared';

export const PRODUCT_COLLECTION_NAME = 'products';

export const productCollection = () => collection(fs, PRODUCT_COLLECTION_NAME);

export const createStripeCheckoutSession = (data: CreateCreditsCheckoutSessionBody) => {
	const callable = httpsCallable<CreateCreditsCheckoutSessionBody, CreateCheckoutSessionResult>(
		cf,
		'createStripeCreditsCheckoutSession',
	);
	return callable(data);
};

export const createStripePortalSession = () => {
	const callable = httpsCallable<null, CreatePortalSessionResult>(cf, 'createStripePortalSession');
	return callable();
};

export const AcceptGiftCard = (data: AcceptCreditGiftInput) => {
	const callable = httpsCallable<AcceptCreditGiftInput, null>(cf, 'AcceptGiftCard');
	return callable(data);
};
