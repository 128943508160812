import {
	getPermissionsForRole,
	getRoleValue,
	OrganizationActionPermissionPath,
	OrganizationMembership,
	OrganizationRole,
} from '../../../../../build-dependencies/shared';
import { FsDoc } from '../../models/Utility';

export function isValidRole(role: OrganizationRole): boolean {
	return getRoleValue(role) >= 0 && role !== undefined && role !== null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasPermission(permissions: any, permissionPath: string): boolean {
	const [category, feature, action] = permissionPath.split('.');
	return permissions[category]?.[feature]?.[action] ?? false;
}

export function memberHasPermissionFor(
	member: FsDoc<OrganizationMembership> | undefined,
	permissionPath: OrganizationActionPermissionPath | OrganizationActionPermissionPath[],
	pathListOperator?: 'and' | 'or',
): boolean {
	const permissions = member
		? (member.permissions ?? member.role ?? getPermissionsForRole(OrganizationRole.GUEST))
		: getPermissionsForRole(OrganizationRole.PUBLIC);

	if (Array.isArray(permissionPath)) {
		if (pathListOperator === 'and') {
			return permissionPath.every(path => hasPermission(permissions, path));
		} else {
			return permissionPath.some(path => hasPermission(permissions, path));
		}
	} else {
		return hasPermission(permissions, permissionPath);
	}
}
