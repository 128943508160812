import { Component, Input } from '@angular/core';
import { AccordionLayoutGroupItem } from 'src/app/core/shared/models/layout/layoutGroups';
import { DynamicValuesComponent } from '../../base/dynamic-values/dynamic-values.component';

@Component({
	selector: 'app-accordion-layout-group-item-content',
	template: ` <div class="accordion-header">
			<button
				class="accordion-button fw-bold"
				type="button"
				data-bs-toggle="collapse"
				[class.collapsed]="currentAccordion != index"
				[attr.data-bs-target]="'#' + uniqueId + index"
				[attr.aria-expanded]="currentAccordion == index"
				[attr.aria-controls]="uniqueId + index"
				[innerHTML]="label | dynamic | sanitizeHtml"
			></button>
		</div>

		<div
			[id]="uniqueId + index"
			class="accordion-collapse collapse"
			[class.show]="currentAccordion == index"
			[attr.data-bs-parent]="'#' + uniqueId"
		>
			<div class="accordion-body">
				<app-layout [elements]="elements"></app-layout>
			</div>
		</div>`,
	styles: [
		`
			:host {
				display: contents;
			}
		`,
	],
})
export class AccordionLayoutGroupItemContentComponent extends DynamicValuesComponent {
	@Input() elements: AccordionLayoutGroupItem['elements'] = [];
	@Input() label: AccordionLayoutGroupItem['label'] = '';
	@Input() currentAccordion: number = -1;
	@Input() uniqueId: string = '';
	@Input() index: number = -1;

	override setupValueForKey(key: keyof this): void {
		// no values to set up
	}

	override setupValueInArrayForKey(key: keyof this, index: number): void {
		// no arrays to set up
	}

	protected override setupAllValues(): void {
		// no values to set up
	}
}
