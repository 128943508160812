import { ConfigUIComponentTypes, HostConfigs } from '../app/core/shared/models/config/host-config/hostConfigModels';

export const hostConfigs: HostConfigs = {
	localhost: {
		organizations: {
			linked: {
				org_3timm60RQ7LuATqm70A9: {
					ui: {
						page: {
							generate: {
								faIcon: 'fa-solid fa-comment-dots',
								isEnabled: true,
								useComponent: ConfigUIComponentTypes.DEFAULT,
							},
							templates: {
								isEnabled: false,
							},
							organization: {
								isDetailsEnabled: false,
								billing: {
									isEnabled: false,
									isSavingsEnabled: false,
								},
								members: {
									isEnabled: false,
									isMembersListEnabled: false,
									isInvitesEnabled: false,
									isInviteLinksEnabled: false,
								},
							},
						},
					},
				},
				org_qpMQfua5PYo3AxUrabtr: {
					isEnabled: true,
					ui: {
						navbar: {
							organization: {
								isEnabled: false,
							},
						},
						page: {
							generate: {
								faIcon: 'fa-solid fa-comment-dots',
								isEnabled: true,
								useComponent: ConfigUIComponentTypes.DEFAULT,
							},
							templates: {
								isEnabled: false,
							},
							organization: {
								isDetailsEnabled: false,
								billing: {
									isEnabled: false,
									isSavingsEnabled: false,
								},
								members: {
									isEnabled: false,
									isInvitesEnabled: false,
									isInviteLinksEnabled: false,
								},
							},
						},
					},
				},
			},
		},
		ui: {
			localization: {
				nl: {
					'general.app': 'Gen8 Dev (Local)',
				},
				en: {
					'general.app': 'Gen8 Dev (Local)',
				},
			},
			page: {
				templates: {
					isCreateEnabled: true,
				},
			},
			devTools: {
				isEnabled: true,
			},
			navbar: {
				organization: {
					button: {
						routerLink: '@mvp1',
					},
				},
				user: {
					button: {
						routerLink: '@user/billing',
					},
				},
			},
		},
	},
	'dev.gen8.app': {
		ui: {
			localization: {
				nl: {
					'general.app': 'Gen8 Dev (Online)',
				},
				en: {
					'general.app': 'Gen8 Dev (Online)',
				},
			},
			page: {
				templates: {
					isCreateEnabled: true,
				},
			},
			devTools: {
				isEnabled: true,
			},
		},
	},
	'mijn.slagenmetaisha.nl': {
		organizations: {
			linked: {
				org_ZdXaklhwgZMTnXMtJiyB: {
					isEnabled: true,
					autoJoin: {
						isEnabled: false,
						// inviteLinkCode: "inviteLink_WGx0HO02izgDSHQ1vgfs"
					},
				},
				org_Gi5GXVZlVgU12oyoUxDc: {
					isEnabled: true,
					autoJoin: {
						isEnabled: false,
						// inviteLinkCode: "inviteLink_ava9iQzrC6hMqJsF3iNo"
					},
				},
				org_WXDglH5YCAZO58YckPzi: {
					isEnabled: true,
					autoJoin: {
						isEnabled: false,
						// inviteLinkCode: "inviteLink_Y1HXbkraP5uUhHpfyDXd"
					},
				},
			},
			nonLinked: {
				isEnabled: false,
			},
		},
		ui: {
			localization: {
				nl: {
					'general.app': 'Slagen met Aïsha',
					'general.chat': 'gesprek',
					'general.chats': 'gesprekken',
					'general.template': 'vak',
					'general.templates': 'vakken',
					'guard.error.noMembership.description': 'Kies een niveau om verder te gaan',
					'component.chat.assistantName': 'Aïsha',
				},
				en: {
					'general.app': 'Passing with Aïsha',
					'general.chat': 'conversation',
					'general.chats': 'conversations',
					'general.template': 'course',
					'general.templates': 'courses',
					'guard.error.noMembership.description': 'Choose your level of education to continue',
					'component.chat.assistantName': 'Aïsha',
				},
			},
			navbar: {
				organization: {
					isEnabled: true,
					dropdown: {
						isEnabled: true,
						isSwitcherEnabled: true,
					},
				},
			},
			pageStateGuard: {
				useComponent: ConfigUIComponentTypes.AISHA,
			},
			page: {
				generate: {
					faIcon: 'fa-solid fa-comment-dots',
					isEnabled: true,
				},
				templates: {
					isEnabled: false,
				},
				organization: {
					isDetailsEnabled: false,
					billing: {
						isEnabled: false,
						isSavingsEnabled: false,
					},
					members: {
						isEnabled: false,
						isInvitesEnabled: false,
						isInviteLinksEnabled: false,
					},
				},
				organizations: {
					isEnabled: false,
					isCreateEnabled: false,
				},
				user: {
					billing: {
						isEnabled: true,
						isSavingsEnabled: false,
					},
				},
			},
		},
	},
};
