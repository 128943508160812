import { RouteOption, NavigateSetup } from '../../../services/navigation/navigation.service';
import { AppLocalizationModifications } from '../localization/localizationModels';

export interface HostConfigs {
	[hostname: string]: HostConfig;
}

export interface HostConfig {
	organizations?: {
		linked?: {
			[organizationId: string]: LinkedOrgConfig;
		};
		nonLinked?: OrgConfig;
	};
	ui?: ConfigUI;
}

export enum ConfigUIComponentTypes {
	DEFAULT = 'default',
	AISHA = 'aisha',
}

interface ConfigUI {
	general?: {
		logo?: string;
	};
	localization?: AppLocalizationModifications;
	navbar?: {
		organization?: {
			isEnabled?: boolean;
			dropdown?: {
				isEnabled?: boolean;
				isSwitcherEnabled?: boolean;
			};
			button?: {
				isEnabled?: boolean;
				routerLink?: RouteConfig;
			};
		};
		user?: {
			button?: {
				routerLink?: RouteConfig;
			};
		};
	};
	pageStateGuard?: {
		useComponent?: ConfigUIComponentTypes;
	};
	page?: {
		generate?: {
			faIcon?: string | string[];
			isEnabled?: boolean;
			useComponent?: ConfigUIComponentTypes;
		};
		templates?: {
			faIcon?: string | string[];
			isEnabled?: boolean;
			useComponent?: 'default';
			isCreateEnabled?: boolean;
		};
		organization?: {
			isDetailsEnabled?: boolean;
			billing: BillingConfig;
			members?: {
				isEnabled?: boolean;
				isMembersListEnabled?: boolean;
				isInvitesEnabled?: boolean;
				isInviteLinksEnabled?: boolean;
			};
		};
		organizations?: {
			isEnabled?: boolean;
			isCreateEnabled?: boolean;
		};
		user?: {
			billing: BillingConfig;
		};
	};
	devTools?: {
		isEnabled?: boolean;
	};
}

export interface OrgConfig extends ConfigUI {
	isEnabled?: boolean;
	ui?: ConfigUI;
}

export interface LinkedOrgConfig extends OrgConfig {
	autoJoin?: {
		isEnabled: boolean;
		inviteLinkCode?: string;
	};
}

interface BillingConfig {
	isEnabled?: boolean;
	isSavingsEnabled?: boolean;
}

type RouteConfig = RouteOption | NavigateSetup;

export type HostConfigOption =
	| 'organization.isEnabled'
	| 'ui.general.logo'
	| 'ui.navbar.organization.isEnabled'
	| 'ui.navbar.organization.dropdown.isEnabled'
	| 'ui.navbar.organization.dropdown.isSwitcherEnabled'
	| 'ui.navbar.organization.button.isEnabled'
	| 'ui.navbar.organization.button.routerLink'
	| 'ui.navbar.user.button.routerLink'
	| 'ui.pageStateGuard.useComponent'
	| 'ui.page.organization.isDetailsEnabled'
	| 'ui.page.organization.members.isEnabled'
	| 'ui.page.organization.members.isMembersListEnabled'
	| 'ui.page.organization.members.isInvitesEnabled'
	| 'ui.page.organization.members.isInviteLinksEnabled'
	| 'ui.page.organization.billing.isEnabled'
	| 'ui.page.organization.billing.isSavingsEnabled'
	| 'ui.page.organizations.isEnabled'
	| 'ui.page.organizations.isCreateEnabled'
	| 'ui.page.user.billing.isEnabled'
	| 'ui.page.user.billing.isSavingsEnabled'
	| 'ui.page.generate.isEnabled'
	| 'ui.page.generate.faIcon'
	| 'ui.page.generate.useComponent'
	| 'ui.page.templates.isEnabled'
	| 'ui.page.templates.faIcon'
	| 'ui.page.templates.useComponent'
	| 'ui.page.templates.isCreateEnabled'
	| 'ui.devTools.isEnabled';

export type HostConfigTarget = 'innerText' | 'class' | 'visibility' | 'render' | 'src' | 'router';

export const hostConfigTargets: Record<HostConfigOption, HostConfigTarget> = {
	'organization.isEnabled': 'render',
	'ui.general.logo': 'class',
	'ui.navbar.organization.isEnabled': 'render',
	'ui.navbar.organization.dropdown.isEnabled': 'render',
	'ui.navbar.organization.dropdown.isSwitcherEnabled': 'render',
	'ui.navbar.organization.button.isEnabled': 'render',
	'ui.navbar.organization.button.routerLink': 'router',
	'ui.navbar.user.button.routerLink': 'router',
	'ui.pageStateGuard.useComponent': 'render',
	'ui.page.organization.isDetailsEnabled': 'render',
	'ui.page.organization.members.isEnabled': 'render',
	'ui.page.organization.members.isMembersListEnabled': 'render',
	'ui.page.organization.members.isInvitesEnabled': 'render',
	'ui.page.organization.members.isInviteLinksEnabled': 'render',
	'ui.page.organization.billing.isEnabled': 'render',
	'ui.page.organization.billing.isSavingsEnabled': 'render',
	'ui.page.organizations.isEnabled': 'render',
	'ui.page.organizations.isCreateEnabled': 'render',
	'ui.page.user.billing.isEnabled': 'render',
	'ui.page.user.billing.isSavingsEnabled': 'render',
	'ui.page.generate.isEnabled': 'render',
	'ui.page.generate.faIcon': 'class',
	'ui.page.generate.useComponent': 'render',
	'ui.page.templates.isEnabled': 'render',
	'ui.page.templates.faIcon': 'class',
	'ui.page.templates.useComponent': 'render',
	'ui.page.templates.isCreateEnabled': 'render',
	'ui.devTools.isEnabled': 'render',
};
